export const formatNumber = (input: number | string | undefined): string => {
  if (!input) return '0';
  const value = Math.abs(Number(input));
  if (value < 1000) return value.toFixed(1);
  if (value < 1000000) return `${(value / 1000).toFixed(1)}K`;
  if (value < 1000000000) return `${(value / 1000000).toFixed(1)}M`;
  return '0.0';
};

export const toTwoDigits = (input: number | string): string =>
  Number(input).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
