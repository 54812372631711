import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { BigNumber, providers } from 'ethers';
import { useToken } from './useContract';
import { getTokenPriceFromCGC } from 'utils/dexscreener';
import { getTokensInfo } from 'utils/backend';
import { getDeployedTokensInfo, getTokensDexInfo } from 'config/constants/backend';
import multicall from 'utils/multicall';
import { getBalanceInEther } from 'utils';
import LpPair from 'config/abi/LpPair.json';

export const useFtmPrice = (chainId: number) => {
  const [balance, setBalance] = useState<number>(0);
  const [lastTime, setLastTime] = useState<number>(0);
  
  const fetchBalance = useCallback(async () => {
//   const price = await getTokenPriceFromCGC("fantom");
    const offchainData = await getTokensInfo({});
    // console.log(`Chain id fetch native price ${chainId}`)
    try{
       if( chainId === 250 ){
        if(!offchainData)
          return
          for( let i = 0; i < offchainData.length; i+=1 ){
              if( offchainData[i].tokenAddress === "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83" ){
                  setBalance(offchainData[i].priceInUSD)
                  break;
              }
          }
       }
       else if( chainId === 8453 ){

        const offchainData = await getTokensDexInfo({});
        if(!offchainData)
          return
        for( let i = 0; i < offchainData.length; i+=1 ){
            if( offchainData[i].pairAddress === "0xb2cc224c1c9fee385f8ad6a55b4d94e92359dc59" ){
                  setBalance(offchainData[i].priceUSD)
                  return
            }
          }
        // 0xb2cc224c1c9feE385f8ad6a55b4d94E92359DC59 pair
        //
        // console.log(balance)
        //   const currentTime = Number((new Date().getTime() / 1000).toFixed(0))
        //   console.log(currentTime)

        //   if( lastTime + 60 < currentTime) {
        //     console.log("Time to update price")
        //     setLastTime(currentTime)
        //   } else {
        //     return;
        //   }
          
        
        // console.log("here")
        // const native = "0x4200000000000000000000000000000000000006"
        // let price = 0;
        // const res = await getTokenPriceFromCGC("")
        // console.log(res)
        // setBalance(Number(res.pair.priceUsd))
        setBalance(0)
       }

    }
    catch(err){
      console.log(err)
    }
  }, [chainId]);

  useEffect(() => {
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBalance();

    setTimeout(() => {
      fetchBalance();
    }, 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return balance;
};
