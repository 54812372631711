import { useState, useEffect } from 'react';
import axios from 'axios';

const JWT_KEY = process.env.REACT_APP_PINATA_API_JWT;
const BASE_URL = 'https://api.pinata.cloud';

interface PinataFile {
    description: string;
    tg: string;
    twitter: string;
    web: string;
    imageUrl: string;
    jsonUrl: string;
}

interface CombinedData {
    [key: string]: PinataFile;
}

export const usePinata = (reloadCounter: number) => {
    const [combinedData, setCombinedData] = useState<CombinedData>({});

    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/data/pinList`, {
                headers: {
                    Authorization: `Bearer ${JWT_KEY}`,
                },
                params: {
                    status: 'pinned',
                    pageLimit: 200,
                },
            });

            const files = response.data.rows || [];
            const jsonFiles = files.filter((file: any) => file.mime_type === 'application/json' && file.metadata.name);
            const imageFiles = files.filter((file: any) => file.mime_type.startsWith('image/') && file.metadata.name);

            const combinedData: { [key: string]: PinataFile } = {};

            await Promise.all(
                imageFiles.map(async (imageFile: any) => {
                    const tokenAddress = imageFile.metadata.name.toLowerCase();


                    const jsonFile = jsonFiles.find((json: any) => json.metadata.name.toLowerCase().replace('.json', '') === tokenAddress);

                    let description = '';
                    let tg = '';
                    let twitter = '';
                    let web = '';
                    let jsonUrl = '';

                    if (jsonFile) {
                        try {
                            const descriptionResponse = await axios.get(`https://gateway.pinata.cloud/ipfs/${jsonFile.ipfs_pin_hash}`);
                            const descriptionData = descriptionResponse.data;

                            description = descriptionData?.desc || description;
                            tg = descriptionData?.tg || '';
                            twitter = descriptionData?.twitter || '';
                            web = descriptionData?.web || '';
                            jsonUrl = `https://gateway.pinata.cloud/ipfs/${jsonFile.ipfs_pin_hash}`;
                        } catch (err) {
                            console.error('Error fetching JSON file description:', err);
                        }
                    }
                    else {
                        if( imageFile.metadata.keyvalues ){
                            description = imageFile.metadata.keyvalues['desc']
                            tg = imageFile.metadata.keyvalues['tg']
                            twitter = imageFile.metadata.keyvalues['twitter']
                            web = imageFile.metadata.keyvalues['web']
                        }
                    }

                    const imageUrl = `https://gateway.pinata.cloud/ipfs/${imageFile.ipfs_pin_hash}`;

                    combinedData[tokenAddress] = {
                        description,
                        tg,
                        twitter,
                        web,
                        imageUrl,
                        jsonUrl,
                    };
                })
            );

            setCombinedData(combinedData);
        } catch (err) {
            console.error('Error fetching files:', err);
        }
    };

    const uploadImageToPinata = async (tokenAddress: string, imageFile: File, cidVersion: number) => {
        try {
            const imageFormData = new FormData();
            imageFormData.append('file', imageFile);

            const metadata = JSON.stringify({
                name: tokenAddress
            });

            imageFormData.append('pinataMetadata', metadata);

            const pinataOptions = JSON.stringify({
                cidVersion: cidVersion,
            });

            imageFormData.append("pinataOptions", pinataOptions);

            const imageResponse = await axios.post(`${BASE_URL}/pinning/pinFileToIPFS`, imageFormData, {
                headers: {
                    Authorization: `Bearer ${JWT_KEY}`,
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (!imageResponse.data || !imageResponse.data.IpfsHash) {
                throw new Error('Image upload failed');
            }

            const imageUrl = `https://gateway.pinata.cloud/ipfs/${imageResponse.data.IpfsHash}`;
            return imageUrl;
        } catch (err) {
            console.error('Error uploading image:', err);
            throw err;
        }
    };

    const uploadJsonToPinata = async (tokenAddress: string, desc: string, tg: string, twitter: string, web: string) => {
        try {
            const jsonContent = {
                desc,
                tg,
                twitter,
                web,
            };

            const jsonFile = new Blob([JSON.stringify(jsonContent)], { type: 'application/json' });
            const jsonFormData = new FormData();
            jsonFormData.append('file', jsonFile, `${tokenAddress}.json`);

            const jsonResponse = await axios.post(`${BASE_URL}/pinning/pinFileToIPFS`, jsonFormData, {
                headers: {
                    Authorization: `Bearer ${JWT_KEY}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (!jsonResponse.data || !jsonResponse.data.IpfsHash) {
                throw new Error('JSON upload failed');
            }

            const jsonUrl = `https://gateway.pinata.cloud/ipfs/${jsonResponse.data.IpfsHash}`;
            return jsonUrl;
        } catch (err) {
            console.error('Error uploading JSON:', err);
            throw err;
        }
    };



    const deleteImageFromPinata = async (tokenAddress: string) => {
        try {

            const fileData = combinedData[tokenAddress];

            if (!fileData || !fileData.imageUrl) {
                throw new Error('Image not found');
            }


            const imageHash = fileData.imageUrl.split('/').pop();
            await axios.delete(`${BASE_URL}/pinning/unpin/${imageHash}`, {
                headers: {
                    Authorization: `Bearer ${JWT_KEY}`,
                },
            });


            console.log(`Successfully deleted image for token: ${tokenAddress}`);
        } catch (err) {
            console.error('Error deleting image:', err);
            throw err;
        }
    };

    const deleteJsonFromPinata = async (tokenAddress: string) => {
        try {
            const fileData = combinedData[tokenAddress.toLowerCase()];

            if (!fileData || !fileData.jsonUrl) {
                throw new Error('JSON file not found');
            }


            const jsonHash = fileData.jsonUrl.split('/').pop();
            await axios.delete(`${BASE_URL}/pinning/unpin/${jsonHash}`, {
                headers: {
                    Authorization: `Bearer ${JWT_KEY}`,
                },
            });

            console.log(`Successfully deleted JSON file for token: ${tokenAddress}`);
        } catch (err) {
            console.error('Error deleting JSON file:', err);
            throw err;
        }
    };

    useEffect(() => {
        fetchFiles().then();
    }, [reloadCounter]);

    return {
        combinedData,
        fetchFiles,
        uploadImageToPinata,
        uploadJsonToPinata,
        deleteImageFromPinata,
        deleteJsonFromPinata
    };
};
