import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, styled, Typography } from '@mui/material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { useFtmPrice } from 'hooks/useFTMPrice';
import TokenSection from './Components/TokenSection';
import TokenomicsSection from './Components/TokenomicsSection';
import InfoSection from './Components/InfoSection';
import PresaleSection from './Components/PresaleSection';
import PostPresaleSection from './Components/PostPresaleSection';
import LiquiditySection from './Components/LiquiditySection';
import { PresaleUIInfo } from 'types/presale';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import { usePresale } from 'hooks/usePresaleInfo';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import axios from 'axios';
import { ImageType } from 'react-images-uploading';
import { fetchDeployedPresaleSizeAsync, resetDataPresale, setPresaleReloadCount, setPresaleReloadCountAsync } from 'state/presale/presaleSlice';
import { useWeb3React } from '@web3-react/core';
import PresaleModal from "../Modal/PresaleModal/PresaleModal";
import ConnectModal from "../Modal/WalletConnectModal";
import DeployPresaleModal from "../Modal/DeployPresaleModal/DeployPresaleModal";
import {removeFragmentSpreadFromDocument} from "@apollo/client/utilities";

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  // margin: '0 auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  // minHeight: '300px',
//   borderRadius: '20px',
  background: "rgba(42,49,57, 0.4)",
  boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    [theme.breakpoints.up('xs')]: {
      padding: '20px 32px',
      maxWidth: '320px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 100px',
      minWidth: '200px',
      maxWidth: '700px',

    },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  width: '100%',
  cursor: 'pointer',
  textWrap: 'wrap',
  textAlign: 'left',
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    minWidth: '200px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '30px',
    lineHeight: '30px',
    img: {
      display: 'block',
    },
  },
}));

const GlobalInfoPresaleCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  width: '100%',
  cursor: 'pointer',
  textWrap: 'wrap',
  textAlign: 'center',
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '25px',
    lineHeight: '25px',
    img: {
      display: 'block',
    },
  },
}));

const ActionButton = styled(ContainedButton)(() => ({
    maxWidth: '102px',
    height: '30px',
  }));

interface PoolCardProps {
}

const DeployPresale: FC<PoolCardProps> = ({  }) => {
    const [isExpanded, setExpanded] = useState<boolean>(false);
    const [launchPrice, setLaunchPrice] = useState<string>('');
    const [totalSupply, setTotalSupply] = useState<string>('');
    const [isDeployed, setIsDeployed] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(true);
    const [isCheckErrors, setIsCheckErrors] = useState<boolean>(false);
    const { selectedChainId } = useAppSelector((state) => state.chain);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [userDeployedPresale, setUserDeployedPresale] = useState<any>(undefined);
    const { deployedPresales, size, presaleReloadCount } = useAppSelector((presale) => presale.presale);
    const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [hardCap, setHardCap] = useState("")
    const [images, setImages] = useState<ImageType[]>([]);
    const dispatch = useAppDispatch();
    const { account } = useWeb3React();
    const [isTotalSupplyError, setTotalSupplyError] = useState<boolean>(false);
    const [totalSupplySouldBe, setTotalSupplyShouldBe] = useState<string>("");
    const [presaleDeployedAddress, setPresaleDeployedAddress] = useState<string>("");

    const [presaleInfo, setPresaleInfo] = useState<PresaleUIInfo>({tokenName: "", tokenSymbol: "", totalSupply: "", 
        tokensPresale: "", presaleTokenPrice: "", softCap: "", minBuy: "", presaleStartsIn: "", hardCap: "", 
        maxBuy: "", presaleLen: "", amm: undefined, lockDuration: "", isBurnChecked: false, isLiquidityChecked: false, isLiquidityCreateChecked: false,
        launchPrice: "", isElock: false, tokenDescription: "", tokenTg: "", tokenX: "", tokenDiscord: "", tokenWeb: ""
    })


    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpen = () => setIsModalOpen(true);
    const handleClose = () => setIsModalOpen(false);

    const {onPresale} = usePresale(Number(selectedChainId), presaleInfo);

    const onConfirm = async () => {
        if(presaleInfo.isElock ){
          if( Number(presaleInfo.lockDuration) <= 0 )
            return
        }
        else if( presaleInfo.isLiquidityChecked ){
          if( Number(presaleInfo.lockDuration) < 15 )
            return
        }

        setLoading(true);
    
        try {
          handleClose()
          const presaleAddress = await onPresale(presaleInfo);
          if( presaleAddress )
            setPresaleDeployedAddress(presaleAddress)
          setIsDeployed(true)
          
          } catch (err) {
            console.log('Presale err: ', err);
          }
          setLoading(false);
      };

      const key = process.env.REACT_APP_PINATA_API_KEY
      const apiKey = process.env.REACT_APP_PINATA_API_API
    
      const pinataConfig = {
        root: 'https://api.pinata.cloud',
        headers: {
          'pinata_api_key': apiKey,
          'pinata_secret_api_key': key
        }
      }; 
    
      const sendFileToIPFS = async (addr: string) => {
        try {
            if (images.length > 0 && images[0] && !imageUploaded) {
              const formData = new FormData();
              formData.append('file', images[0].file ? images[0].file : "");
              const pinataBody = {
                options: {
                  cidVersion: 1,
                },
                metadata: {
                  name: `${addr}`,
                  keyvalues: {desc: `${presaleInfo.tokenDescription}`,
                              tg: `${presaleInfo.tokenTg}`,
                              twitter: `${presaleInfo.tokenX}`,
                              web: `${presaleInfo.tokenWeb}`}
                }
              }
              formData.append('pinataOptions', JSON.stringify(pinataBody.options));
              formData.append('pinataMetadata', JSON.stringify(pinataBody.metadata));
              const url = `${pinataConfig.root}/pinning/pinFileToIPFS`;
              const response = await axios({
                method: 'post',
                url: url,
                data: formData,
                headers: pinataConfig.headers
              })
              dispatch(resetDataPresale())
              dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
              setImageUploaded(true)
              //   queryPinataFiles();
            } else {
              // alert('select file first')
            }
          } catch (error) {
            console.log(error)
          }
      }
      useEffect(() => {
        if( deployedPresales && isDeployed ){
          let userToken = undefined
          for (const token  of Object.values(deployedPresales)) {
            if( token.isUserToken ){
             // TODO Check name 
             // dispatch(fetchDeployedPresaleSizeAsync())
              if( token.info.tokenInfo.tokenName === presaleInfo.tokenName ) {
                userToken = token;
              }   
            }
        } 
        if( !userToken ){
            dispatch(fetchDeployedPresaleSizeAsync())
            return;
        }
          setUserDeployedPresale(userToken)
          setIsLoadingInfo(false)
        }
      }, [deployedPresales, size]);
    

    const checkError = (presale: PresaleUIInfo) => {
        setIsCheckErrors(true)
        let error = false
        if( presale.tokenName.length === 0 )
            error = true;
        if( presale.tokenSymbol.length === 0 )
            error = true
        if( presale.totalSupply.length === 0 || Number(presale.totalSupply) === 0 )
            error = true;
        if( presale.tokensPresale.length === 0 || Number(presale.tokensPresale) === 0 )
            error = true;
        if( presale.presaleTokenPrice.length === 0 || Number(presale.presaleTokenPrice) === 0 )
            error = true;
        if( presale.softCap.length === 0 || Number(presale.softCap) === 0 )
            error = true;
        if( presale.minBuy.length === 0 || Number(presale.minBuy) === 0 )
            error = true;
        if( presale.presaleStartsIn.length === 0 || Number(presale.presaleStartsIn) === 0 )
            error = true;
        if( presale.hardCap.length === 0 || Number(presale.hardCap) === 0 )
            error = true;
        if( presale.maxBuy.length === 0 || Number(presale.maxBuy) === 0 )
            error = true;
        if( presale.presaleLen.length === 0 || Number(presale.presaleLen) === 0 )
            error = true;
        if( !presale.isElock && !presale.amm )
            error = true;
        if( Number(presale.softCap) > Number(presale.hardCap) )
            error = true;
        const val = Number(presale.totalSupply) - ( Number(presale.totalSupply) *  100/10000 )
        const val2 = Number(presale.tokensPresale) + (((Number(presale.presaleTokenPrice) * Number(presale.tokensPresale)) / Number(presale.launchPrice)))
        console.log(`Val ${val} <  Val2 ${val2}`)

        if ( Number(presale.totalSupply) - ( Number(presale.totalSupply) *  100/10000 ) >= Number(presale.tokensPresale) + (((Number(presale.presaleTokenPrice)
             * Number(presale.tokensPresale)) / Number(presale.launchPrice)) )) {
            setTotalSupplyError(false)
        } else 
        {
          error = true
          // const diff = Math.ceil(val2 - val) + 1
          // console.log(`Diff = ${diff}`)
          // const totalSupply = val + ( Number(presale.totalSupply) *  100/10000 )
          // console.log(`Total Supply  ${totalSupply} should be ${totalSupply + diff}`)
          // const futureTotalSupply = totalSupply + diff
          // const val3 = Number(futureTotalSupply) - ( Number(futureTotalSupply) *  100/10000 )
          // const val4 = Number(presale.tokensPresale) + (((Number(presale.presaleTokenPrice) * Number(presale.tokensPresale)) / Number(presale.launchPrice)))
          // const diff2 = Math.ceil(val4 - val3) + 1
          // console.log(`Total Supply  ${futureTotalSupply} should be 2 ${totalSupply + diff2}`)

          setTotalSupplyError(true)
          // setTotalSupplyShouldBe((totalSupply + diff).toString())
          // require(_tokenInfo.totalSupply - (_tokenInfo.totalSupply * 100/10000) >= _deployInfo.tokensForPresale + ((((_deployInfo.presalePrice
          //   * _deployInfo.tokensForPresale) / 1 ether) * 1 ether) / _tokenInfo.tokenLaunchPrice), "invalid total supply" );
        }
        //  tokens for presale + ( hard cap /  launch price) + ( total supply /100 )  <= total supply


        if( presale.isLiquidityChecked ){
            if( presale.lockDuration.length === 0 || Number(presale.lockDuration) === 0 )
                error = true;
        }
        if( presale.launchPrice.length === 0 || Number(presale.launchPrice) === 0 )
            error = true;
        setIsError(error)
    }

    const tokenSectionChanges = (tokenName: string, tokenSymbol: string, totalSupply: string) => {
        let presale = presaleInfo;
        presale.tokenName = tokenName;
        presale.tokenSymbol = tokenSymbol;
        presale.totalSupply = totalSupply;


        if( presale.totalSupply.length > 0 ){
          setTotalSupply((Number(presale.totalSupply)).toString())
        }

        checkError(presale)
        setPresaleInfo(presale)
    }
    
    const tokenomicsSectionChanges = (totalSupply: string, tokensPresale: string, presaleTokenPrice: string) => {
        let presale = presaleInfo;
        presale.totalSupply = totalSupply;
        presale.tokensPresale = tokensPresale;
        presale.presaleTokenPrice = presaleTokenPrice;

        if( presale.presaleTokenPrice.length > 0 && presale.totalSupply.length > 0 ){
            setTotalSupply((Number(presale.totalSupply)).toString())
            setLaunchPrice(presale.presaleTokenPrice)
            setHardCap((Number(presale.presaleTokenPrice) * Number(presale.tokensPresale)).toString())
        }

        console.log(`Total supply ${totalSupply} Tokens Presale ${tokensPresale} Presale token price ${presaleTokenPrice}`)
        checkError(presale)
        setPresaleInfo(presaleInfo)
    }

    const presaleSectionChanges = (tokensForPresale: string, presaleTokenPrice: string, softCap: string, hardCap: string, minBuy: string, maxBuy: string, presaleStartsIn: string,
      presaleLen: string) => {
        console.log(`Tokens for presale ${tokensForPresale} Presale token price ${presaleTokenPrice} Soft Cap ${softCap}Hard Cap ${hardCap}  Min buy ${minBuy} Max buy ${maxBuy} Presale starts in ${presaleStartsIn} 
          Presale Len ${presaleLen}`)
        let presale = presaleInfo;

        presale.tokensPresale = tokensForPresale;
        presale.presaleTokenPrice = presaleTokenPrice
        presale.softCap = softCap;
        presale.minBuy = minBuy;
        presale.presaleStartsIn = presaleStartsIn;
        presale.hardCap = hardCap;
        presale.maxBuy = maxBuy;
        presale.presaleLen = presaleLen;

        if( presale.presaleTokenPrice.length > 0 && presale.totalSupply.length > 0 ){
          // setTotalSupply((Number(presale.totalSupply)).toString())
          setLaunchPrice(presale.presaleTokenPrice)
          setHardCap((Number(presale.presaleTokenPrice) * Number(presale.tokensPresale)).toFixed(4))
       }
        checkError(presale)
        setPresaleInfo(presale)
    }

    const liquiditySectionChanges = (ammm: any, lockDuration: string, isBurnChecked: boolean,
        isLiquidityChecked: boolean, isLiquidityCreateChecked: boolean, images: ImageType[], isElock: boolean, tokenDescription: string,
        tokenTg: string, tokenX: string, tokenDiscord: string, tokenWeb: string
    ) => {
        console.log(`Amm ${ammm} Duration ${lockDuration} isBurnChecked ${isBurnChecked} isLiquidityChecked ${isLiquidityChecked} isLiquidityCreate ${isLiquidityCreateChecked} isElock ${isElock} description ${tokenDescription}`)
        let presale = presaleInfo;
        presale.amm = ammm;
        presale.lockDuration = lockDuration;
        presale.isBurnChecked = isBurnChecked;
        presale.isLiquidityChecked = isLiquidityChecked;
        presale.isLiquidityCreateChecked = isLiquidityCreateChecked;
        presale.isElock = isElock
        presale.tokenDescription = tokenDescription
        presale.tokenTg = tokenTg
        presale.tokenX = tokenX
        presale.tokenDiscord = tokenDiscord
        presale.tokenWeb = tokenWeb
        setImages(images)
        checkError(presale)
        setPresaleInfo(presale)
    }
    const postPresaleSectionChanges = (launchPrice: string
    ) => {
        // console.log(`Launch Price ${launchPrice}`)
        let presale = presaleInfo;
        presale.launchPrice = launchPrice;
        checkError(presale)
        setPresaleInfo(presale)
    }

    useEffect(() => {
        // console.log(presaleInfo)

    }, [presaleInfo]);

    useEffect(() => {
      // console.log(userDeployedPresale)
      // console.log(isDeployed)
      if( presaleDeployedAddress.length > 0 ){
        console.log("Send IMAGE PRESAEL ")
        console.log(presaleDeployedAddress)
        sendFileToIPFS(presaleDeployedAddress);
      }
    }, [presaleDeployedAddress]);

    // @ts-ignore
    // @ts-ignore
    return (
        <>
        {/* {modalOpen && modalType && <StakingModal info={pool} modalType={modalType} onClose={onCloseModal} />} */}
        <Container sx={{borderRadius: '10px', position: 'relative'}} justifyContent='center' alignItems='center' textAlign='center'>
            <Box >
              <GlobalInfoCardLabel>
                  Liquidity Bootstrap Module
              </GlobalInfoCardLabel>

            </Box>
            <Box sx={{position: 'absolute', top: 10, right: 10}}>
              <Button onClick={() => {setExpanded(!isExpanded)}}>
                <ArrowDropDownCircleIcon sx={{transform: isExpanded  ? "rotate(180deg)" : "", width: '35px', height: '35px'}}/>
              </Button>
            </Box>
            {isExpanded && 
            <>
            <Box>
              <GlobalInfoPresaleCardLabel mt={2}>
                Create your Token And Presale
              </GlobalInfoPresaleCardLabel>
            </Box>

                <Box display='flex' flexDirection='column' mt={2}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Box display='flex' flexDirection='column'>
                                <TokenSection isCheckErrors={isCheckErrors} tokenSectionChanges={tokenSectionChanges} isTotalSupplyError={false} shouldBeTotalSupply={totalSupplySouldBe}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box display='flex' flexDirection='column'>
                                <PresaleSection hardCapS={hardCap} totalSupply={totalSupply} presaleSectionChanges={presaleSectionChanges} isCheckErrors={isCheckErrors} isPresaleTokensError={isTotalSupplyError}/>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Box display='flex' flexDirection='column'>
                                <PostPresaleSection tokenLaunchPrice={launchPrice} totalSupply={totalSupply} postPresaleSectionChanges={postPresaleSectionChanges} isCheckErrors={isCheckErrors}/>
                                <LiquiditySection isCheckErrors={isCheckErrors} liquiditySectionChanges={liquiditySectionChanges}/>
                                <InfoSection/>
                          </Box>
                        </Grid>
                    </Grid>

                </Box>

                {account ? (
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {/* <ActionButton
                            variant='outlined'
                            disabled={isDeployed || isError}
                            onClick={onConfirm}
                            sx={{ maxWidth: '202px', marginLeft: '0px', marginTop: '20px' }}
                        >
                            {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Create LBM"}
                        </ActionButton> */}

                        <Button
                            variant='outlined'
                            disabled={isDeployed || isError}

                            onClick={handleOpen}
                            sx={{ maxWidth: '202px', marginLeft: '20px', marginTop: '20px' }}
                        >
                            {isLoading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Preview"}
                        </Button>

                        <DeployPresaleModal
                            isDeployed={isDeployed}
                            open={isModalOpen}
                            handleClose={handleClose}
                            presaleInfo={presaleInfo}
                            images = {images}
                            handleDeploy={onConfirm}
                        />
                    </Box>

                    ) : (
                    <ConnectWallet  width={150} />
                    )
                }
            </>
            }
        </Container>
        </>
    );
};

export default DeployPresale;
