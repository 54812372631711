import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { AbiItem } from 'web3-utils';
import { BigNumber, Contract, constants, ethers, providers, utils } from "ethers";
import { useDispatch } from 'react-redux';
import { fetchDeployedTokensDataAsync, fetchPresaleUserInfoAsync } from "state/actions";
import pairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';
import multicall, { multicallNative} from 'utils/multicall';
import { getWeb3, getProviderBASE, getWeb3NoAccountBASE, getWeb3NoAccount } from 'utils/web3';
import LpPair from 'config/abi/LpPair.json';

import { FarmInfo } from "types/stake";
import { getMasterChefAbi } from "utils/stake";
import {useContract, useToken} from "./useContract";
import { setPendingTxHash } from 'state/modal/modalSlice';

import { useNotification } from "./useNotification";
import { getBalanceInEther, getContract } from "utils";
import { getTokenPriceFromCGC } from "utils/coingecko";
import { getTokenPriceFromDexTools } from "utils/dextools";
import { Interface } from "ethers/lib/utils";
import { getTokenInfo } from "utils/tokens";
import { getTokenDeployerInfo } from "utils/deployTokens";
import { getTokensDexInfo } from "config/constants/backend";

export const useStakeDeployedAccountInfo = (chainId: number, pool: any, counter: number) => {
  const { account } = useWeb3React();
  const [stat, setStat] = useState<any>();
  const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
  const token = useContract(ERC20 as unknown as AbiItem, pool ? pool?.stakingToken : '');

  useEffect(() => {
      async function getStakeInfo() {
        if( !contract || !token || !pool || !account ) return;
        let accountBalance = BigNumber.from(0);
        let userStaked = BigNumber.from(0);
        let stakeTime = BigNumber.from(0);
        let allowance = BigNumber.from(0);
        let pendingRewards = BigNumber.from(0);
        try {
          if( contract && token && pool){
              if( account ){
                  accountBalance = await token.balanceOf(account);
                  const userInfo = await contract.userInfo(0, account);
                  userStaked = userInfo.amount;
                  stakeTime = userInfo.stakeTime;
                  allowance = await token.allowance(account, contract.address)
                  pendingRewards= await contract.pendingReward(0, account);
              }
              setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: pool.masterChefAddress, stakingToken: pool.stakingToken, poolId: 0, isLp: false});
          }
        } catch (err) {
          console.log(err)
          // setStat({accountBalance, userStaked, stakeTime, allowance, pendingRewards, masterChefAddress: "", stakingToken: "", poolId: 0, isLp: false});
        }
      }
   
      getStakeInfo().then();
    }, [ chainId, pool, contract, token, account, counter]);
    return stat;
}

export const useStakeEstimateInfo = (chainId: number, pool: FarmInfo, counter: number, tokenInfo: any, ftmPrice: number) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
    const token = useContract(ERC20 as unknown as AbiItem, pool ? pool?.stakingToken : '');
    const tokenLp = useContract(pairAbi as unknown as AbiItem, pool ? pool?.stakingToken0 : '');
    const rewardTokInfo = getTokenInfo(pool?.stakingToken)
    const deployerTokInfo = getTokenDeployerInfo(chainId)
    useEffect(() => {
        async function getStakeInfo() {
          try {

            if( pool && rewardTokInfo){
              let poolRewards = 0;
              let accountBalance = BigNumber.from(0);
              let userStaked = BigNumber.from(0);
              let stakeTime = BigNumber.from(0);
              let allowance = BigNumber.from(0);
              let pendingRewards = BigNumber.from(0);
              let tvlUSDC = 0;
  
              const [poolInfo, totalAllocPoint, rewardToken, poolEndTime] = await multicall(
                getMasterChefAbi(),
                [
                  {
                    address: pool?.masterChefAddress,
                    name: 'poolInfo',
                    params: [pool.poolId],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'totalAllocPoint',
                    params: [],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'rewardToken',
                    params: [],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'poolEndTime',
                    params: [],
                  },
                ]
            );
            console.log(`Pool end time ${poolEndTime}`)

            let decimals = 18

            if( rewardTokInfo )
              decimals = rewardTokInfo.decimals

            const [totalRewardsRaw] = await multicall(
              ERC20,
              [
                {
                  address: rewardToken[0],
                  name: 'balanceOf',
                  params: [pool?.masterChefAddress],
                },
              ]
            );
            const totalRewards = getBalanceInEther(totalRewardsRaw[0]);
  
            const allocPoint = Number(poolInfo.allocPoint);
            const totalAllocPointRaw = Number(totalAllocPoint);
            const poolWeight = allocPoint / totalAllocPointRaw;
  
            let totalStaked = BigNumber.from(0)

            const [balance, stakingTokenSupply, totalStakedRaw] = await multicall(
              ERC20,
              [
                {
                  address: rewardToken[0],
                  name: 'balanceOf',
                  params: [pool?.masterChefAddress],
                },
                {
                  address: pool?.stakingToken,
                  name: 'totalSupply',
                  params: [],
                },
                {
                  address: pool?.stakingToken,
                  name: 'balanceOf',
                  params: [pool?.masterChefAddress],
                },
              ]
            );
            totalStaked = totalStakedRaw[0]
            
  
            let depositFee = Number(poolInfo.depositFee);
            if( depositFee > 0 )
              depositFee = depositFee / 100

            poolRewards = (getBalanceInEther(balance) - Number(utils.formatUnits(totalStaked, decimals)) )/ 2;
            const aprYear = poolRewards * allocPoint / 100 / getBalanceInEther(totalStaked) * 100;
            let apr = 0;
            const  lockTime =  poolInfo.lockTime;
            let tvl = 0;
            const curretnToken = getTokenInfo(pool?.stakingToken);

            let currentTokenPrice = 1;
            
            if( curretnToken ){
              if( curretnToken.cgc.length > 0 ){
                const offchainData = await getTokensDexInfo({});
                if(!offchainData)
                  return
                for( let i = 0; i < offchainData.length; i+=1 ){
                    if( offchainData[i].tokenName === curretnToken.cgc &&  offchainData[i].chain === curretnToken.chain )
                    {
                      if( offchainData[i].tokenName === 'ETH' ) {
                        // currentTokenPrice = offchainData[i].priceNative

                        tvl = Number(utils.formatUnits(totalStaked,decimals));
                        apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * ftmPrice) * 600;

                        break;
                      } else{
                        currentTokenPrice = offchainData[i].priceNative
                        tvl = Number(utils.formatUnits(totalStaked,decimals));
  
                        apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 600;
                        break;
                      }

                    }
                  }
              }
              else if( curretnToken.lp.length  >  0){                     
                const [reserves] = await multicall(
                  LpPair,
                  [
                    {
                      address: curretnToken.lp,
                      name: 'getReserves',
                      params: [],
                    },
                  ]
                );
  
                const [token0] = await multicall(
                  LpPair,
                  [
                    {
                      address: curretnToken.lp,
                      name: 'token0',
                      params: [],
                    }
                  ]
                );
                if( token0[0].toLowerCase() === deployerTokInfo?.nativeToken.toLowerCase() ) {
                  currentTokenPrice = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                }
                else {
                  currentTokenPrice = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                }
                tvl = Number(utils.formatUnits(totalStaked,decimals));
                apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 600;
                // console.log(`!!! Total rewards ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)

              } else if( curretnToken.address.toLowerCase() === deployerTokInfo?.nativeToken.toLowerCase() ){
                tvl = Number(utils.formatUnits(totalStaked,decimals));
                apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * ftmPrice) * 600;
                // console.log(`Total rewards 2 ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
              } else if( curretnToken.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' || curretnToken.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913' ){
                tvl = Number(utils.formatUnits(totalStaked,decimals));
                let usdcPriceInFtm = 1;
                if( ftmPrice <= 1 )
                   usdcPriceInFtm = 1 + (1 - ftmPrice )
                else
                  usdcPriceInFtm = 1 / ftmPrice
                apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * usdcPriceInFtm) * 600;
                // console.log(`Total rewards 3 ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
                currentTokenPrice = usdcPriceInFtm
              }
            } else {
              tvl = Number(utils.formatUnits(totalStaked,decimals));
              apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 600;
              // console.log(`Total rewards ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
            }

            if( account ){
              const [userInfo, pendingRewardsRaw] = await multicall(
                getMasterChefAbi(),
                [
                  {
                    address: pool?.masterChefAddress,
                    name: 'userInfo',
                    params: [pool.poolId, account],
                  },
                  {
                    address: pool?.masterChefAddress,
                    name: 'pendingReward',
                    params: [pool.poolId, account],
                  },
                ]
              );
              const [accountBalanceRaw, allowanceRaw] = await multicall(
                ERC20,
                [
                  {
                    address: pool?.stakingToken,
                    name: 'balanceOf',
                    params: [account],
                  },
                  {
                    address: pool?.stakingToken,
                    name: 'allowance',
                    params: [account, pool?.masterChefAddress ],
                  }
                ]
              );

              console.log(`Pool id ${pool.poolId} pendingReward ${getBalanceInEther(pendingRewardsRaw)} `)
              accountBalance = accountBalanceRaw[0]
              allowance = allowanceRaw[0]
              userStaked = userInfo.amount;
              pendingRewards = pendingRewardsRaw[0]
              stakeTime = userInfo.stakeTime;
            }

            const currentDate = Math.floor((new Date()).getTime() / 1000)

            if( currentDate > poolEndTime[0] ){
              apr = -1;
            }
            // console.log(`${curretnToken?.tokenName} currentTokenPrice ${currentTokenPrice}`)
                setStat({poolInfo, totalAllocPoint, poolWeight, totalStaked, stakingTokenSupply, rewardPerSecond : 0, aprYear, accountBalance, userStaked, stakeTime, allowance, pendingRewards, lockTime ,tvl, stakeTokenPriceFTM: currentTokenPrice, depositFee, apr, decimals});
            }
            else if(pool && !contract && !token) {
                throw("err")
            }

            // const res = await contract?.estimatePreSale(ethers.utils.parseUnits(amount.toString(), 18));
            // setStat({value: res});
          } catch (err) {
            // console.log(err)
          //   let poolRewards = 0;
          //   let accountBalance = BigNumber.from(0);
          //   let userStaked = BigNumber.from(0);
          //   let stakeTime = BigNumber.from(0);
          //   let allowance = BigNumber.from(0);
          //   let pendingRewards = BigNumber.from(0);

          //   const rewardTokInfo2 = getTokenInfo(pool?.stakingToken)
          //   let decimals = 18

          //   if( rewardTokInfo2 )
          //     decimals = rewardTokInfo2.decimals


          //   const [poolInfo, totalAllocPoint, rewardToken] = await multicall(
          //     getMasterChefAbi(),
          //     [
          //       {
          //         address: pool?.masterChefAddress,
          //         name: 'poolInfo',
          //         params: [pool.poolId],
          //       },
          //       {
          //         address: pool?.masterChefAddress,
          //         name: 'totalAllocPoint',
          //         params: [],
          //       },
          //       {
          //         address: pool?.masterChefAddress,
          //         name: 'rewardToken',
          //         params: [],
          //       },
          //     ]
          // );

          // console.log(rewardToken[0])
          // const rewardTokInfo = getTokenInfo(rewardToken[0])
          // console.log(rewardTokInfo)

          // const [totalRewardsRaw] = await multicall(
          //   ERC20,
          //   [
          //     {
          //       address: rewardToken[0],
          //       name: 'balanceOf',
          //       params: [pool?.masterChefAddress],
          //     },
          //   ]
          // );
          // const totalRewards = getBalanceInEther(totalRewardsRaw[0]);

          // const allocPoint = Number(poolInfo.allocPoint);
          // const totalAllocPointRaw = Number(totalAllocPoint);
          // const poolWeight = allocPoint / totalAllocPointRaw;
          // let apr = 0;
          // let totalStaked: BigNumber = BigNumber.from(0)
          // const [balance, stakingTokenSupply, totalStakedRaw] = await multicall(
          //   ERC20,
          //   [
          //     {
          //       address: rewardToken[0],
          //       name: 'balanceOf',
          //       params: [pool?.masterChefAddress],
          //     },
          //     {
          //       address: pool?.stakingToken,
          //       name: 'totalSupply',
          //       params: [],
          //     },
          //     {
          //       address: pool?.stakingToken,
          //       name: 'balanceOf',
          //       params: [pool?.masterChefAddress],
          //     },
          //   ]
          // );
          // totalStaked = totalStakedRaw[0]
          

          // let depositFee = Number(poolInfo.depositFee);
          // if( depositFee > 0 )
          //   depositFee = depositFee / 100
    
          // console.log(decimals)
          // console.log(totalStaked)
    
          // console.log(`Pool id ${pool.poolId} Total Staked ${Number(utils.formatUnits(totalStaked, decimals))}`)

          // poolRewards = (getBalanceInEther(balance) - Number(utils.formatUnits(totalStaked, decimals)) )/ 2;
          // const aprYear = poolRewards * allocPoint / 100 / Number(utils.formatUnits(totalStaked, decimals)) * 100;

          // const  lockTime =  poolInfo.lockTime;
          // let tvl = 0;
          // const curretnToken = getTokenInfo(pool?.stakingToken);
          // let currentTokenPrice = 0;
          // if( curretnToken ){
          //   if( curretnToken.lp.length  >  0){                     
          //     const wftm = ("0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83").toLowerCase()

          //     const [reserves] = await multicall(
          //       LpPair,
          //       [
          //         {
          //           address: curretnToken.lp,
          //           name: 'getReserves',
          //           params: [],
          //         },
          //       ]
          //     );

          //     const [token0] = await multicall(
          //       LpPair,
          //       [
          //         {
          //           address: curretnToken.lp,
          //           name: 'token0',
          //           params: [],
          //         },
          //       ]
          //     );
          //     if( token0[0] === "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83") {
          //       currentTokenPrice = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
          //     }
          //     else {
          //       currentTokenPrice = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
          //     }
          //     tvl = Number(utils.formatUnits(totalStaked, decimals));
          //     apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 400;
          //     console.log(`!!! Total rewards ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
          //   } else if( curretnToken.address === '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83' ){
          //     tvl = Number(utils.formatUnits(totalStaked, decimals));
          //     apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * ftmPrice) * 400;
          //     console.log(`Total rewards 2 ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
          //   } else if( curretnToken.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' ){
          //     tvl = Number(utils.formatUnits(totalStaked, decimals));
          //     let usdcPriceInFtm = 1;
          //     if( ftmPrice <= 1 )
          //        usdcPriceInFtm = 1 + (1 - ftmPrice )
          //     else
          //       usdcPriceInFtm = 1 / ftmPrice
          //     apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * usdcPriceInFtm) * 400;
          //     console.log(`Total rewards 3 ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
          //   }
          // } else {
          //   tvl = Number(utils.formatUnits(totalStaked, decimals));
          //   apr = ((totalRewards * tokenInfo.tokenPriceFTM ) * poolWeight) / (tvl * currentTokenPrice) * 400;
          //   console.log(`Total rewards ${totalRewards} Deposit fee ${depositFee} Tvl ${tvl} Apr ${apr}`)
          // }

          // if( account ){
          //   const [userInfo, pendingRewards] = await multicall(
          //     getMasterChefAbi(),
          //     [
          //       {
          //         address: pool?.masterChefAddress,
          //         name: 'userInfo',
          //         params: [pool.poolId, account],
          //       },
          //       {
          //         address: pool?.masterChefAddress,
          //         name: 'pendingReward',
          //         params: [pool.poolId, account],
          //       },
          //     ]
          //   );
          //   const [accountBalanceRaw, allowanceRaw] = await multicall(
          //     ERC20,
          //     [
          //       {
          //         address: pool?.stakingToken,
          //         name: 'balanceOf',
          //         params: [account],
          //       },
          //       {
          //         address: pool?.stakingToken,
          //         name: 'allowance',
          //         params: [account, pool?.masterChefAddress ],
          //       }
          //     ]
          //   );
          //   accountBalance = accountBalanceRaw
          //   allowance = allowanceRaw
          //   userStaked = userInfo.amount;
          //   stakeTime = userInfo.stakeTime;
          // }
          // setStat({poolInfo, totalAllocPoint, poolWeight, totalStaked, stakingTokenSupply, rewardPerSecond: 0, aprYear, accountBalance, userStaked, stakeTime, allowance, pendingRewards, lockTime ,tvl, stakeTokenPriceFTM: currentTokenPrice, depositFee, apr, decimals});
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, pool, contract, token, account, counter, ftmPrice]);
      return stat;
}

export const useTokenApprove = (chainId: number, amount: string, pool: FarmInfo ) => {
    const { account } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, pool ? pool?.masterChefAddress : '');
    
    useEffect(() => {
        if( !contract || !pool || amount.length <= 0){
          setStat({value: 0});
          return;
        }
        // HOW MUCH APPROVED
        async function getStakeInfo() {
          try {
            const res = await contract?.estimatePreSale(ethers.utils.parseUnits(amount.toString(), 18));
            setStat({value: res});
          } catch (err) {
            console.error(err);
          }
        }
     
        getStakeInfo().then();
      }, [ chainId, amount, pool, contract]);
      return stat;
}

export const useTokenPrice = (chainId: number, lastPairUpdates: number) => {
    const { account, connector } = useWeb3React();
    const [stat, setStat] = useState<any>();
    const nativePair = "0x9c0Dd6BA0E2c611585c75F06f024BC8826FdB446";
    const usdcPair = "0x29715d8D279cAB143A12fF515b40a2b35d7BAD37";

    useEffect(() => {
        // if( !contract ){
        //   setStat({value: 0});
        //   return;
        // }
        // HOW MUCH APPROVED
        async function getStakeInfo() {
          try {


            if((Date.now().valueOf() - lastPairUpdates) <= 30000)
            {
                return;
            }
                
            // const token0Native = await contract.token0();
            // const token1Native = await contract.token1();
            
            // const provider = new providers.Web3Provider(connector.provider!);
            
            // const token0Contract = getContract(token0Native, ERC20, provider, account ? account : undefined);
            // const token1Contract = getContract(token1Native, ERC20, provider, account ? account : undefined);

            // const token0NativeDecimals = await token0Contract.decimals();
            // const token1NativeDecimals = await token1Contract.decimals();


            // const token0NativeBalance = await token0Contract.balanceOf(nativePair);
            // const token1NativeBalance = await token1Contract.balanceOf(nativePair);
            
            // let priceLp = 1;

            // const format0 = ethers.utils.formatUnits(BigNumber.from(token0NativeBalance), token0NativeDecimals);
            // const format1 = ethers.utils.formatUnits(token1NativeBalance, token1NativeDecimals);
            // priceLp = Number(format1) / Number(format0)
            const price = await getTokenPriceFromCGC("fantom");
            if( price ){
                if(price.pairs.length > 0){
                    setStat({value: price.pairs[0].priceUsd});
                }
            }
            // setStat({value: res});
          } catch (err) {
            console.log(err);
          }
        }
     
        getStakeInfo().then();
      }, [ chainId]);
      return stat;
}

export const useStake = (chainId: number, amount: string, info: any, poolInfo: any) => {
    const { account } = useWeb3React();
    const contract = useContract(getMasterChefAbi() as unknown as AbiItem, info ? info?.masterChefAddress : '');
    const dispatch = useDispatch();
    const { onShowNotification } = useNotification();
    const tokenContract = useToken( info?.isLp ? info?.stakingToken0 : info?.stakingToken);
    const handleStake= useCallback(
        async (estimate: string): Promise<string | undefined> => {
    
          if (!account || !contract || !info ) return '';

          let decimals = 18;
          if( tokenContract?.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' || tokenContract?.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913')
            decimals = 6;

          const tx = await contract.stake(info.poolId, estimate); 
    
          const receipt = await tx.wait();
    
          if (receipt.status !== 1) {
            throw new Error();
          }
          return tx.txHash;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, contract, amount]
    );

    const handleUnStake= useCallback(
      async (estimate: string): Promise<string | undefined> => {
  
        if (!account || !contract || !info ) return '';
        
        if( estimate === "0" ) {
            const tx  = await contract.unstake(info.poolId, 0); 
            const receipt = await tx.wait();
  
            if (receipt.status !== 1) {
              throw new Error();
            }
            return tx.txHash;
        }
        else {
          let decimals = 18;
          if( tokenContract?.address === '0x2F733095B80A04b38b0D10cC884524a3d09b836a' || tokenContract?.address === '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913')
            decimals = 6;

           const tx = await contract.unstake(info.poolId, ethers.utils.parseUnits(amount,decimals)); 
           const receipt = await tx.wait();
  
           if (receipt.status !== 1) {
             throw new Error();
           }
         //   dispatch(fetchBridgeUserDataAsync(account, chainId.toFixed(), info, tokenFrom));
           dispatch(fetchPresaleUserInfoAsync(chainId.toString(), account))
           return tx.txHash;

        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [account, contract, amount]
  );

    const handleApprovePurchaseToken = useCallback(async (): Promise<string | undefined> => {
        if (!account || !contract || !tokenContract ) return '';
        const tx = await tokenContract.approve(contract.address, ethers.constants.MaxUint256);
    
        dispatch(setPendingTxHash(tx.hash));
        const receipt = await tx.wait();
    
        if (receipt.status !== 1) {
          throw new Error();
        }
    
        // dispatch(fetchTheatreUserDataAsync(account, selectedChainId));
    
        return tx.txHash;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [account, dispatch, contract, tokenContract]);

    return {
        onStake: handleStake,
        onApprove: handleApprovePurchaseToken,
        onUnstake: handleUnStake
    }
}
