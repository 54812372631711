import { FC, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { BigNumber } from 'ethers';
import moment from 'moment';

import { useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
// import { StakingModal } from 'components/Modal';
import { getBalanceInEther, getBalanceInWei, toTwoDigits } from 'utils';
// import { useStakingPool } from 'hooks/useStakingPool';
// import {useClaimRewardTimerBoardroom, useWithdrawTimerStaking} from 'hooks/useStakingPoolLockInfo';
import { useNotification } from 'hooks/useNotification';
import { ContainedInput } from 'components/common/Input';
import { useWeb3React } from '@web3-react/core';
import { useStake, useTokenPrice } from 'hooks/useStakeInfo';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
//   borderRadius: '20px',
  background: "transparent",

  [theme.breakpoints.up('xs')]: {
    padding: '0px 16px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '0px 32px',
  },
}));

// origin section
const Origin = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '0px 15px',
  padding: '16px 0px',

  [theme.breakpoints.up('xs')]: {
    gap: '0px 15px',
  },
  [theme.breakpoints.up('md')]: {
    gap: '15px 15px',
  },
}));

// token img section
const TokenImgSection = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const TokenImg = styled('img')(({ theme }) => ({
  width: '60px',

  [theme.breakpoints.up('xs')]: {
    width: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60px',
  },
}));

const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '100px',
  whiteSpace: 'break-spaces',
  textAlign: 'center',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    textAlign: 'start',
    whiteSpace: 'break-spaces',
  },
}));

// info section
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    gap: '15px',
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    gap: '30px',
  },
}));

const InfoCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'rgba(255, 255, 255, 0.7)',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '30px',
  color: '#ffffff',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
  margin: 'auto',

  span: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginLeft: '4px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '19px',
    lineHeight: '23px',
    span: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px',
    span: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const RewardImg = styled('img')(() => ({
  width: '22px',
}));

// desktop expanded section
const DesktopExpanded = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: '18px',
  alignItems: 'center',
  padding: '0px 0px 16px',

  [theme.breakpoints.up('xs')]: {
    border: 'none',
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {},
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

// global info section
const GlobalInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0px 15px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  borderBottom: '1.5px solid rgba(255, 255, 255, 0.3)',
  flexWrap: 'wrap',

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  },
  [theme.breakpoints.up('md')]: {},
}));

const GlobalInfoCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0px',
  },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  width: '100%',
  cursor: 'pointer',
  textWrap: 'wrap',
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '18px',
    img: {
      display: 'block',
    },
  },
}));

const GlobalInfoCardValue = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  color: '#ffffff',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
}));

// action section
const ActionSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
}));

const ActionInfoSection = styled(Box)(() => ({
  maxWidth: '130px',
  margin: 'auto',
  marginBottom: '6px',
  minWidth: '130px'
}));

const ActionInfo1 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  whiteSpace: 'break-spaces',
  color: 'rgba(255, 255, 255, 0.7)',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));

const ActionInfo2 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  whiteSpace: 'break-spaces',
  color: 'rgba(255, 255, 255, 255)',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
}));

const ButtonSection = styled(Box)(() => ({}));

const ActionButton = styled(ContainedButton)(() => ({
  maxWidth: '102px',
  height: '30px',
}));

// mobile expanded section
const MobileExpanded = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  gap: '18px',
  alignItems: 'center',
  padding: '0px 0px 12px',

  [theme.breakpoints.up('xs')]: {
    border: 'none',
    display: 'flex',
    borderTop: '2px solid #CCC292',
  },
  [theme.breakpoints.up('sm')]: {
    border: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

// global info section
const MobileGlobalInfoCard = styled(Box)(() => ({
  textAlign: 'center',
}));

const MobileGlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  color: 'rgba(255, 255, 255, 0.7)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '12px',
  lineHeight: 'normal',
  maxWidth: '150px',
  whiteSpace: 'break-spaces',

  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const MobileGlobalInfoCardValue = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  color: '#ffffff',
  fontSize: '12px',
  lineHeight: 'normal',
}));

const AmountInput = styled(ContainedInput)(() => ({
  height: '30px',
  maxWidth: '150px',
  marginRight: '5px',
  marginLeft: '5px'
}));

// action section
const MobileButtonSection = styled(Box)(() => ({
  textAlign: 'center',
}));

const MobileActionButton = styled(ContainedButton)(() => ({
  maxWidth: '102px',
  height: '40px',
}));

interface PoolCardProps {
    lockTime: number,
}

const CountDown: FC<PoolCardProps> = ({ lockTime}) => {
    const [nextEpochCountDown, setNextEpochCountDown] = useState<string>('');

    useEffect(() => {
        const interval = setInterval(() => {
          const timeDiff = moment.duration(moment(1000 * lockTime).diff(moment()));
            
          const hourDiff = toTwoDigits(timeDiff.hours() + (Number(timeDiff.days()) * 24 ));
          const minDiff = toTwoDigits(timeDiff.minutes());
          const secDiff = toTwoDigits(timeDiff.seconds());
          if( Number(timeDiff.seconds()) < 0 || Number(timeDiff.minutes()) < 0 ) {
            setNextEpochCountDown(`00:00:00`);
            return;
          }
          setNextEpochCountDown(`${hourDiff}:${minDiff}:${secDiff}`);
        }, 1000);
    
        return () => clearInterval(interval);
      }, [lockTime]);
    

  return (
    <>
          <GlobalInfoCardValue>{`${nextEpochCountDown === "NaN:NaN:NaN" ? '00:00:00' : nextEpochCountDown}`}</GlobalInfoCardValue>
    </>
  );
};

export default CountDown;
