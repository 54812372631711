import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { BigNumber } from 'ethers';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { StakingModal } from 'components/Modal';
import { getBalanceInEther, getBalanceInWei, toTwoDigits } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { useStake, useTokenPrice } from 'hooks/useStakeInfo';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { getLockerAddress, getMemDaoAddress, getRouters, getTokenDeployerInfo } from 'utils/deployTokens';
import useCountDown from 'hooks/useCountDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TokenModal from 'components/Modal/TokenModal';
import { fetchDeployedTokensDataAsync, fetchDeployedTokensDataByCAAsync, fetchStakerInfoAsync, fetchStakerShortInfoAsync } from 'state/actions';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import { useGetTokensLogoPiniata2 } from 'hooks/useGetDeployedTokenImage';
import StakerPool from './StakerPool';
import useCountDownUp from 'hooks/useCountDownUp';
import { setSelectedChainId } from 'state/chain/chainSlice';

const Card = styled(Box)(({ theme }) => ({
    background: "rgba(42,49,57, 0.4)",
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '20px'
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: '20px'
      },
  
}));

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '30px',
    background: "rgba(42,49,57, 0.4)",

    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    // alignItems: 'center',
    // justifyContent: 'center',
  //   alignItems: 'center',
    flexDirection: 'column',
    minHeight: '700px',
    minWidth: '300px',
  //   borderRadius: '20px',
  //   background: "#2A3239",
  //   boxShadow:
  //     '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
  
    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
      minWidth: '280px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 32px',
    },
  }));

// origin section
const Origin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  boxShadow:
  '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
 // 5A687D
  transform: 'translate3d(0px, 0px, 0px)',
  border: '1px solid rgba(46,145,250,0.0)', 
  "&:hover": {
    boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    transform: 'translate3d(0px, -4px, 0px)',
    border: '1px solid rgba(46,145,250,0.8)', 
   // 5A687D
  },
  transition: 'all 0.25s ease-in-out',
}));

// token img section
const TokenImgSection = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const TokenImg = styled('img')(({ theme }) => ({
  width: '60px',

  [theme.breakpoints.up('xs')]: {
    width: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60px',
  },
}));

const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));

const PoolSupply = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  }));

  const PoolInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
  }));
  const PoolInfoLabelEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameInfoEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '160px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const SupplyValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
  }));


// info section
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    gap: '15px',
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    gap: '30px',
  },
}));

const InfoCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '30px',
  color: '#ffffff',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
  margin: 'auto',

  span: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginLeft: '4px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '19px',
    lineHeight: '23px',
    span: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px',
    span: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const RewardImg = styled('img')(() => ({
  width: '22px',
}));

// desktop expanded section
const ExpandedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent:'center',
  gap: '15px',
  alignItems: 'space-around',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    alignItems: 'space-around',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  },
}));

// global info section
const GlobalInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0px 15px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  borderBottom: '1.5px solid rgba(255, 255, 255, 0.3)',
  flexWrap: 'wrap',

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  },
  [theme.breakpoints.up('md')]: {},
}));

const GlobalInfoCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0px',
  },
}));

const ActionButton = styled(ContainedButton)(() => ({
    maxWidth: '102px',
    height: '30px',
  }));

// modal body
const ModalBody = styled(Box)(() => ({
  marginTop: '23px',
  marginBottom: '21px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  gap: '20px'
}));

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        style={{width: '100%'}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} justifyContent='center' sx={{width: '100%', minHeight: '700px'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

interface Props {
}

const TokenPage: FC<Props> = ({ }) => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [lockedLps, setLockedLps] = useState<Number>(0);
  const [info, setInfo] = useState<any>({});
  const [stakers, setStakers] = useState<any>([]);
  const [stakerPools, setStakerPools] = useState<any>([]);
  const [value, setValue] = useState(0);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [days, hours, minutes, seconds] = useCountDown(info?.info ? Number(info.info.timeStamp) * 1000 : new Date().getUTCSeconds() * 1000);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const { deployedTokens, size, stakersInfo, stakersPools, tokenInfoPage } = useAppSelector((deployedTokens) => deployedTokens.deployedTokens);
  const [daysUntilUnlock, hoursUntilUnlock, minutesUntilUnclock, secsUntilUnlock] = useCountDownUp(info?.info ? Number(info.unlockDate) * 1000 : new Date().getUTCSeconds() * 1000);
  const tokenDeployerInfo = getTokenDeployerInfo(Number(selectedChainId));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const images = useGetTokensLogoPiniata2(0)

  useEffect(() => {
    const a = document.createElement('a');
    const url = new URL(window.location.href);
    const res = url.pathname.split('/');

    if( res.length > 3 ){
        dispatch(setSelectedChainId(Number(res[3])));
        if( ftmPrice )
            dispatch(fetchDeployedTokensDataByCAAsync(Number(selectedChainId), account ? account : "", res[2], ftmPrice));

    } else  if( res.length > 2 ){
      if( ftmPrice )
          dispatch(fetchDeployedTokensDataByCAAsync(Number(selectedChainId), account ? account : "", res[2], ftmPrice));
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, ftmPrice , account]);

  useEffect(() => {
    if(  tokenInfoPage ){
            setInfo(tokenInfoPage)
            setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenInfoPage]);

  const onCloseModal = () => {
    setIsExpanded(false)
  };

  useEffect(() => {
    if( images && info ){
        setTokenImage(images[info?.info?.tokenAddress.toLowerCase()]?.img)
    }
}, [images, info]);

useEffect(() => {
    const pools = []
    if( stakersPools && info ){
        try{
            if( stakersPools[info.info.tokenAddress]) {
                for (const pool  of Object.values(stakersPools[info.info.tokenAddress])) {
                    pools.push(pool)
                    // break
                }
                setStakerPools(pools)
            }
        }
        catch(err){
            console.log(err)
        }
    }
}, [stakersPools]);

useEffect(() => {
if( ftmPrice && info ){
    setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
}
}, [ftmPrice, info]);

// useEffect(() => {
//     const stakers = []
//     for (const staker  of Object.values(stakersInfo)) {
//         stakers.push(staker)
//         break
//     } 
//     console.log(stakers)
//     setStakers(stakers)
// }, [stakersInfo]);

useEffect(() => {
    if( info ) {
        dispatch(fetchStakerInfoAsync(Number(selectedChainId), account ? account : "", ftmPrice, info));
    }
}, [info, selectedChainId]);

useEffect(() => {
if( info ){ 
  const routers = getRouters(Number(selectedChainId))
  setLockedLps(Number(info?.lockLps))
  for( let i = 0; i < routers.length; i+=1 )
  {
    if( routers[i].address === info?.info?.router ){
        setAmm(routers[i].name)
        return
    }
  }
}
}, [info]);

const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };
    const buy = async () => {
        if( info ){
          if( info.routerBuyURL ){
            window.open(info.routerBuyURL)
          }
        }
      };
  return (
    <> <Container>
        {/* body */}
        <ModalBody sx={{position: 'relative'}}>
            {info?.tokenAddInfo && 
            <>
              <Box display='flex' justifyContent='center' sx={{display: {xs: 'none', md: 'flex', position: 'absolute', bottom: 0, right: '0%', minWidth: '128px'}}} >
                <Button  onClick={() => {window.open(info?.tokenAddInfo.twitter)}} sx={{zIndex: 1}}>
                  <XIcon sx={{width: '18px'}} />
                </Button>
                { info?.tokenAddInfo.tg.length > 0 &&
                    <Button onClick={() => {window.open(info?.tokenAddInfo.tg)}} sx={{zIndex: 1}}>
                      <TelegramIcon sx={{width: '25px'}} />
                    </Button>
                }
                { info?.tokenAddInfo.discord.length > 0 &&
                  <Button onClick={() => {window.open(info?.tokenAddInfo.discord)}} sx={{zIndex: 1}}>
                    <img alt='' src={icons.discord} style={{width:'25px', height: '25px'}}></img>
                  </Button>
                }
                { info?.tokenAddInfo.web.length  > 0 && 
                  <Button onClick={() => {window.open(info?.tokenAddInfo.web)}} sx={{zIndex: 1}}>
                  <LanguageIcon sx={{width: '25px', height: '25px'}} />
                </Button>
                }


              </Box>
              <Box gap='20px' display='flex' flexDirection='row' justifyContent='center' sx={{display: {xs: 'flex', md: 'none', position: 'absolute', bottom: 0, right: '0%'}}} >
                <Button onClick={() => {window.open(info?.tokenAddInfo.twitter)}} sx={{zIndex: 1}}>
                  <XIcon sx={{width: '18px'}} />
                </Button>
                { info?.tokenAddInfo.tg.length > 0 &&
                    <Button onClick={() => {window.open(info?.tokenAddInfo.tg)}} sx={{zIndex: 1}}>
                      <TelegramIcon sx={{width: '25px'}} />
                    </Button>
                }
                { info?.tokenAddInfo.discord.length > 0 &&
                  <Button onClick={() => {window.open(info?.tokenAddInfo.discord)}} sx={{zIndex: 1}}>
                    <img alt='' src={icons.discord} style={{width:'25px', height: '25px'}}></img>
                  </Button>
                }
                { info?.tokenAddInfo.web.length  > 0 && 
                  <Button onClick={() => {window.open(info?.tokenAddInfo.web)}} sx={{zIndex: 1}}>
                  <LanguageIcon sx={{width: '25px', height: '25px'}} />
                </Button>
                }
              </Box>
            </>
          }
  
            { info && info?.currentRouterLogo?.length > 0 &&
                    <img src={info ? info?.currentRouterLogo : ""}  style={{width: '40px', height: '40px', objectFit: 'cover', position: 'absolute', right: 10, top: 20}}/>  
            }
            <Box gap='30px' display='flex' sx={{flexDirection: {xs: 'column', md: 'row'}}}>
                        <Box display='flex' flexDirection='row'  alignItems='center' gap='10px' sx={{height: '100%', maxWidth: '200px', border: '0px solid red', position: 'relative'}}> 
                            {tokenImage?.length > 0 && 
                                <img src={tokenImage.length > 0 ? tokenImage : ""}  style={{width: '180px', height: '180px', minWidth: '150px', objectFit: 'cover', borderRadius: '10px'}}/>  
                            }

                        </Box>
                        <Box display='flex' gap={'10px'} flexDirection='column' justifyContent='start' sx={{border: '0px solid blue', width: '100%', height: '100%'}}>
                            <PoolName >
                                {info?.info?.name}
                            </PoolName>
                            <PoolSymbol>
                                {info?.symbol}
                            </PoolSymbol>
                            <Box display='flex' flexDirection={'row'} gap='8px'   sx={{border: '0px solid red'}}>
                                <PoolName2>
                                    Total Supply: {info?.info?.supply ? Number(getBalanceInEther(info?.info.supply)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                </PoolName2>
                            </Box>
                            <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={{background: "rgba(6,1,1,0.3)", display: {xs: 'flex', md: 'flex'}, padding: '2px', maxWidth: '100px', paddingRight: '8px', paddingLeft: '8px', borderRadius: '10px'}}>
                                <img src={icons.timeIcon}  style={{width: '22px', height: '22px', minWidth: '20px', objectFit: 'fill'}}/>  
                                <PoolName2 sx={{border: '0px solid red'}}>
                                    {days > 0 ? days : hours > 0 ? hours : minutes > 0 ? minutes : seconds } {days > 0 ? "d" : hours > 0 ?  "h" :  minutes > 0 ? "m" : "s"}
                                </PoolName2>
                            </Box>
                            <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={{background: "rgba(6,1,1,0.0)", display: info?.routerBuyURL ? 'flex' : 'none', padding: '2px', maxWidth: '100px', paddingRight: '8px', paddingLeft: '8px', borderRadius: '10px'}}>
                                <ActionButton variant='outlined' onClick={buy} sx={{ maxWidth: '202px', marginLeft: '0px' }}>
                                        BUY
                                </ActionButton>
                            </Box>
                    </Box>
            </Box>
        </ModalBody>
        <Grid container display='flex' spacing={1} justifyContent='center'>
                <Grid item xs={12} md={4}>
                    <Box display='flex' flexDirection='column' gap={1}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                            <PoolInfoLabel>
                                    Initial MCap:  
                            </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                            {info?.info?.lockedLiquidity.gt(0) && info?.initialPrice > 0 ? `${(info?.initialPrice * Number(getBalanceInEther(info?.info?.supply))).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}`:`--.--` } 
                            </PoolInfoLabelEnd>
                        </Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                            <PoolInfoLabel sx={{minWidth: '120px'}}>
                                    Initial Liquidity:  
                            </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                                {info?.info?.lockedLiquidity.gt(0) ? `${Number(lockedLps.toFixed(4))} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : `--.--`}
                            </PoolInfoLabelEnd>
                        </Box>   
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                        <PoolInfoLabel>
                                Liquidity Lock:  
                        </PoolInfoLabel>
                        { info?.elockId > 0 && 
                          <PoolInfoLabelEnd>
                          {`eLOCK`}
                          </PoolInfoLabelEnd>
                        }
                        {info?.info?.dao &&
                          <PoolInfoLabelEnd>
                            SCC
                        </PoolInfoLabelEnd>
                        }
                        {!info?.info?.dao && info?.elockId <= 0 &&
                          <>
                        { info?.info?.lockedLiquidity.gt(0) && info?.unlockDate > 0 &&
                          <PoolInfoLabelEnd>
                          {info?.unlockDate > 0  ? `Expires in `: ''}{info?.unlockDate > 0  ? daysUntilUnlock > 0 ? daysUntilUnlock : hoursUntilUnlock > 0 ? hoursUntilUnlock : minutesUntilUnclock > 0 ? minutesUntilUnclock : secsUntilUnlock : "" }{ info?.unlockDate > 0 ? daysUntilUnlock > 0 ? "d " : hoursUntilUnlock > 0 ?  "h " :  minutesUntilUnclock > 0 ? "m " : "s " : ''}
                          {info?.info?.lockedLiquidity.gt(0) ? info?.unlockDate > 0  ? "" : "" : 'None'}
                          </PoolInfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.gt(0) && info?.unlockDate === 0 && info?.info.deployerAddress === account && info?.lockerInfo?.amount > 0 &&
                          <PoolInfoLabelEnd>
                          {'Locked'}
                          </PoolInfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.gt(0) && info?.unlockDate === 0 && info?.info.deployerAddress === account && info?.lockerInfo?.amount === 0 &&
                          <PoolInfoLabelEnd>
                          {'Unlocked'}
                          </PoolInfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.gt(0) && info?.unlockDate === 0 && info?.info.deployerAddress !== account &&
                          <PoolInfoLabelEnd>
                          {'Unlocked'}
                          </PoolInfoLabelEnd>
                        }
                        { info?.info?.lockedLiquidity.eq(0) &&
                          <PoolInfoLabelEnd>
                          {'None'}
                          </PoolInfoLabelEnd>
                        }
                          </>
                        }
                    </Box>                  
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display='flex' flexDirection='column' gap={1}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", minHeight: '34px',  padding: '5px', borderRadius: '10px', minWidth: '200px'}}>
                            <PoolInfoLabel>
                                    MCap:  
                            </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                                {info?.info?.lockedLiquidity.gt(0) ? `${(Number(getBalanceInEther(info?.info.supply)) * Number(info?.tokenPriceFTM)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : "--.--"}
                            </PoolInfoLabelEnd>
                        </Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", minHeight: '34px', padding: '5px', borderRadius: '10px', minWidth: '200px'}}>
                            <PoolInfoLabel>
                                    Liquidity:  
                            </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                                {info?.info?.lockedLiquidity.gt(0) ? `${Number(info?.lpPrice).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${tokenDeployerInfo ? tokenDeployerInfo.nativeTokenName : ""}` : `--.--`} 
                            </PoolInfoLabelEnd>
                        </Box>   
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", minHeight: '34px', padding: '5px', borderRadius: '10px', minWidth: '200px'}}>
                            <PoolInfoLabel>
                                    Price:  
                            </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                                {info?.info?.lockedLiquidity.gt(0) ? Number(tokenPrice) : '--.--' } 
                            </PoolInfoLabelEnd>
                        </Box>                   
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box display='flex' flexDirection='column' gap={1}>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px', minWidth: '170px'}}>
                            <PoolInfoLabel sx={{maxWidth: '80px'}}>
                                    Token C/A:  
                            </PoolInfoLabel>
                            <Button 
                                color="primary" 
                                variant="outlined"                          
                                onClick={()=>{    navigator.clipboard.writeText(info?.info?.tokenAddress);}}
                                style={{maxHeight: '24px',borderRadius: '0px',   display: info?.info?.tokenAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                                >
                                <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(info?.info?.tokenAddress, 4)}
                                </Typography>
                                <FileCopyIcon style={{marginLeft: '2px', maxHeight:"18px", width: 'auto'}}/>
                        </Button>
                        </Box>
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', borderRadius: '10px', minWidth: '170px'}}>
                            <PoolInfoLabel sx={{maxWidth: '60px'}}>
                                    Deployer:  
                            </PoolInfoLabel>
                            <Button 
                                color="primary" 
                                variant="outlined"                          
                                onClick={()=>{    navigator.clipboard.writeText(info?.info?.deployerAddress);}}
                                style={{maxHeight: '24px', borderRadius: '0px', display: info?.info?.deployerAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                                // disabled={ tokenBalance0 < swapInfo?.swapAmount ? true : false}
                                >
                                {/* {smeltBalance < nodeInfo?.price ? 'Insufficient Balance' : 'Purchase'} */}
                                <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(info?.info?.deployerAddress, 4)}
                                </Typography>
                                <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                        </Button>
                        </Box>   
                        <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '170px'}}>
                            {!info?.info?.dao && info?.elockId <= 0 &&
                          <>
                            <PoolInfoLabel sx={{maxWidth: '80px'}}>
                                Lock C/A:  
                            </PoolInfoLabel>
                            <Button 
                                  color="primary" 
                                  variant="outlined"                          
                                  onClick={()=>{    navigator.clipboard.writeText(getLockerAddress(Number(selectedChainId)))}}
                                  style={{maxHeight: '24px', borderRadius: '0px', display: info?.lpAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                                  // disabled={ tokenBalance0 < swapInfo?.swapAmount ? true : false}
                                  >
                                  {/* {smeltBalance < nodeInfo?.price ? 'Insufficient Balance' : 'Purchase'} */}
                                  <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > { info?.info?.lockedLiquidity.gt(0) ? truncate(getLockerAddress(Number(selectedChainId)), 4) : '--.--'}
                                  </Typography>
                                  <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                          </Button>
                          {info?.info?.lockedLiquidity.eq(0) && 
                            <PoolInfoLabel sx={{maxWidth: '60px'}}>
                                No LP  
                            </PoolInfoLabel>
                          }
                          </>
                        }
                        { info?.elockId > 0 && //https://eliteness.network/elocks/?=31#spotlight
                            <>
                              <Box display='flex' alignItems='center' sx={{position: 'relative', width: '100%'}}>
                                 <PoolInfoLabel>
                                  {`eLOCK:` }
                                </PoolInfoLabel>
                                <Button  color="primary"  variant="outlined"  sx={{position: 'absolute', top: -4, right: 0, width: '140px', maxWidth: '140px'  , maxHeight: '24px', borderRadius: '0px',}} onClick={() => {window.open(`https://eliteness.network/elocks/?=${info?.elockId}#spotlight`)}}>
                                  <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > 
                                    {`NFT ID ${info?.elockId}` }
                                  </Typography>
                                  <OpenInNewIcon sx={{width: '25px', height: '25px', position: 'relative', marginLeft: '5px'}}/>
                                </Button>
                              </Box>
                            </>
                        }
                        {info?.info?.dao &&
                            <>
                            <PoolInfoLabel sx={{maxWidth: '80px'}}>
                            SCC:  
                            </PoolInfoLabel>
                            <Button 
                                  color="primary" 
                                  variant="outlined"                          
                                  onClick={()=>{    navigator.clipboard.writeText(getMemDaoAddress(Number(selectedChainId)))}}
                                  style={{maxHeight: '24px', borderRadius: '0px', display: info?.lpAddress ? "" : "none", width: '140px', maxWidth: '140px'}}
                                  // disabled={ tokenBalance0 < swapInfo?.swapAmount ? true : false}
                                  >
                                  {/* {smeltBalance < nodeInfo?.price ? 'Insufficient Balance' : 'Purchase'} */}
                                  <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} > { info?.info?.lockedLiquidity.gt(0) ? truncate(getMemDaoAddress(Number(selectedChainId)), 4) : '--.--'}
                                  </Typography>
                                  <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                          </Button>
                          </>
                        }
                        </Box>                   
                    </Box>
                </Grid>
            </Grid>
            <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} sx={{width: '100%'}} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Stats" {...a11yProps(0)} />
                            <Tab label="Airdrop" {...a11yProps(1)} />
                            </Tabs>
            </Box>
            <>
            <Box mt={2} display='flex' sx={{width: '100%'}}>
                <CustomTabPanel value={value} index={0}>
                    <Box display='flex' sx={{width: '100%', minHeight: '700px'}}>
                        <iframe frameBorder="0" width={'100%'} src={`https://dexscreener.com/${tokenDeployerInfo ? tokenDeployerInfo?.dexscreenerChain : ""}/${info?.info?.tokenAddress}?embed=1&theme=dark&info=0`}></iframe>
                    </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <Box mt={2} gap='10px' display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
                        {stakerPools && stakerPools
                        .map((row: any) => <StakerPool tokenInfo={info} poolInfo={row} />)}
                    </Box>
                </CustomTabPanel>
            </Box>
            </>
                
        </Container>
    </>
  );
};

export default TokenPage;
