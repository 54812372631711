import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, styled, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
// import { StakingModal } from 'components/Modal';
import { getBalanceInEther, getBalanceInWei, toTwoDigits } from 'utils';
// import { useStakingPool } from 'hooks/useStakingPool';
// import {useClaimRewardTimerBoardroom, useWithdrawTimerStaking} from 'hooks/useStakingPoolLockInfo';
import { useNotification } from 'hooks/useNotification';
import { ContainedInput } from 'components/common/Input';
import { useWeb3React } from '@web3-react/core';
import { useStake, useTokenPrice } from 'hooks/useStakeInfo';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { getRouters } from 'utils/deployTokens';
import useCountDown from 'hooks/useCountDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TokenModal from 'components/Modal/TokenModal';
import { fetchDeployedPresalesDataAsync, fetchDeployedTokensDataAsync } from 'state/actions';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import useCountDownUp from 'hooks/useCountDownUp';
import ProgressBar from 'components/common/ ProgressBar/ ProgressBar';
import PresaleModal from 'components/Modal/PresaleModal/PresaleModal';

const Card = styled(Box)(({ theme }) => ({
    background: "rgba(42,49,57, 0.4)",
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '0px'
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'column',
        gap: '0px'
      },
  
}));

// origin section
const Origin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  boxShadow:
  '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
 // 5A687D
  transform: 'translate3d(0px, 0px, 0px)',
  border: '1px solid rgba(46,145,250,0.0)',  
  "&:hover": {
    boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    transform: 'translate3d(0px, -4px, 0px)',
    border: '1px solid rgba(46,145,250,0.8)',  
   // 5A687D
  },
  transition: 'all 0.25s ease-in-out',
}));

const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '300px',
  overflow: 'clip',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));

const PoolSupply = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  }));

  const PoolInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
  }));
  const PoolInfoLabelEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameInfoEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '160px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const SupplyValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
  }));


// info section
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    gap: '15px',
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    gap: '30px',
  },
}));

const InfoCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '30px',
  color: '#ffffff',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
  margin: 'auto',

  span: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginLeft: '4px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '19px',
    lineHeight: '23px',
    span: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px',
    span: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const RewardImg = styled('img')(() => ({
  width: '22px',
}));

// desktop expanded section
const InfoBox = styled(Box)(({ theme }) => ({
   display: 'flex',
   background: 'rgba(6,1,1,0.33)',
   width: '100%',
   minHeight: '35px',
   borderRadius: '10px'
}));

// global info section
const GlobalInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0px 15px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  borderBottom: '1.5px solid rgba(255, 255, 255, 0.3)',
  flexWrap: 'wrap',

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  },
  [theme.breakpoints.up('md')]: {},
}));

const GlobalInfoCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0px',
  },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  width: '100%',
  cursor: 'pointer',
  textWrap: 'wrap',
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '18px',
    img: {
      display: 'block',
    },
  },
}));

// action section
const ActionSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
}));

const ActionInfoSection = styled(Box)(() => ({
  maxWidth: '130px',
  margin: 'auto',
  marginBottom: '6px',
  minWidth: '130px'
}));

const ActionInfo1 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  whiteSpace: 'break-spaces',
  color: 'rgba(255, 255, 255, 0.7)',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
  },
}));

const ActionInfo2 = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  whiteSpace: 'break-spaces',
  color: 'rgba(255, 255, 255, 255)',
  justifyContent: 'center',

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '15px',
  },
}));

interface PoolCardProps {
    preinfo: any,
    images: any
}

const PresaleMini: FC<PoolCardProps> = ({ preinfo, images }) => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [tokenDescription, setTokenDescription] = useState<string>("");
  const [tokenTg, setTokenTg] = useState<string>("");
  const [tokenX, setTokenX] = useState<string>("");
  const [tokenWeb, setTokenWeb] = useState<string>("");

  const [lockedLps, setLockedLps] = useState<Number>(0);
  const [info, setInfo] = useState<any>({});

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [time, setTime] = useState(0);
  const [isLaunchStarted, setLaunchStarted] = useState(false);
  const [isLaunchFinished, setLaunchFinished] = useState(false);

  const [days, hours, minutes, seconds] = useCountDownUp(Number(time.toFixed(0)) * 1000);
  const [daysUntilUnlock, hoursUntilUnlock, minutesUntilUnclock, secsUntilUnlock] = useCountDownUp(info?.info ? Number(info.unlockDate) * 1000 : new Date().getUTCSeconds() * 1000);
  const [isMouseOnTarget, setIsMouseOnTarget] = useState(false);

  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const { deployedPresales, size } = useAppSelector((presale) => presale.presale);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastUpdateTime, setLastUpdateTime] = useState<number>(new Date().getTime() / 1000);
  const [counter, setCounter] = useState(0);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // console.log(Number(time.toFixed(0)) * 1000)
    //  console.log(`days ${days} ${minutes} ${hours} ${seconds} ${isLaunchStarted} ${isLaunchFinished}`)
    if( days < 0 && minutes < 0 && hours < 0 && seconds < 0 && !isLaunchStarted )
    {
      dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId),account ? account : "", [preinfo.index], ftmPrice))
    }
    if( days < 0 && minutes < 0 && hours < 0 && seconds < 0 && isLaunchStarted && !isLaunchFinished)
    {
      dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId),account ? account : "", [preinfo.index], ftmPrice))
    }
    //if( !isLaunchFinished && !isLaunchStarted ){

  }, [days, minutes, hours, seconds, selectedChainId]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
        dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId), account ? account : "", [preinfo.index], ftmPrice))
        setLastUpdateTime(new Date().getTime() / 1000)
    }, 30000);
    return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, selectedChainId])

  useEffect(() => {
    if( ftmPrice === 0 )
        return
    if( deployedPresales[preinfo.index] ){
        setInfo(deployedPresales[preinfo.index])
        return;
    }
    setInfo({})
    dispatch(fetchDeployedPresalesDataAsync(Number(selectedChainId), account ? account : "", [preinfo.index], ftmPrice))
  }, [preinfo, ftmPrice, selectedChainId]);

  useEffect(() => {
    if( deployedPresales[preinfo.index] ){
        setInfo(deployedPresales[preinfo.index])
        setIsLoading(false)
        setCounter(counter + 1)
    }
  }, [deployedPresales]);
  
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };

    useEffect(() => {
        if( images && info ){
            if( info?.presaleAddress )
            {
              setTokenImage(images[info?.presaleAddress?.toLowerCase()]?.imageUrl)
              setTokenDescription(images[info?.presaleAddress?.toLowerCase()]?.description)
              setTokenTg(images[info?.presaleAddress?.toLowerCase()]?.tg)
              setTokenX(images[info?.presaleAddress?.toLowerCase()]?.twitter)
              setTokenWeb(images[info?.presaleAddress?.toLowerCase()]?.web)
            }
        }
    }, [images, info, lastUpdateTime]);

  useEffect(() => {
    if( ftmPrice && info ){
        setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
    }
  }, [ftmPrice, info]);

  useEffect(() => {
    if( info ){ 
      //TIME SELECT
      const startIn =  Number(info?.info?.presaleInfo.presaleStartTime)
      const currentTime = new Date().getTime() / 1000
      const presaleDuration = Number(info?.info?.presaleInfo.presaleDuration)
      const endPool = Number(startIn + presaleDuration)
      // console.log(`End pool ${endPool}`)

      if( currentTime > startIn ){
         const duration =  startIn + presaleDuration;
        setTime(Number(duration.toFixed(0)))
        setLaunchStarted(true)
        if( endPool < currentTime ){
          setLaunchFinished(true)
        }
      }
      else{
        setTime(startIn)
      }
      const routers = getRouters(Number(selectedChainId))
      setLockedLps(Number(info?.lockLps))
      for( let i = 0; i < routers.length; i+=1 )
      {
        if( routers[i].address === info?.info?.router ){
            setAmm(routers[i].name)
            return
        }
      }
      if( Number(info?.raised) >= Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)) ){
        setLaunchStarted(true)
        setLaunchFinished(true)
        return;
      }
    }
  }, [info, lastUpdateTime, selectedChainId]);
  // console.log(tokenTg)

  const calculateMCapAtLaunch = (): string => {
    let mcap = 0
    if( info ){
       const totalSupply = info?.info?.tokenInfo?.totalSupply ? getBalanceInEther(info?.info?.tokenInfo?.totalSupply) : 0
       const priceAtLaunch = info?.info?.tokenInfo?.tokenLaunchPrice ? getBalanceInEther(info?.info?.tokenInfo?.tokenLaunchPrice) : 0
       mcap = Number(totalSupply) * Number(priceAtLaunch);
    }
    return mcap.toString();
}

  const onCloseModal = () => {
    setIsExpanded(false)
  };
  return (
    <> 
        {isExpanded && <PresaleModal tokenSocials={{tokenDescription: tokenDescription, tokenTg: tokenTg, tokenX: tokenX, tokenWeb: tokenWeb}} info={info} onClose={onCloseModal} tokenImage={tokenImage} tokenPrice={tokenPrice}/>}
        <Grid xs={12} md={6} sm={6} lg={4} xl={3} item   sx={{borderRadius: '10px', opacity: info?.info ? 1.0 : 1.0, position: 'relative', zIndex: isMouseOnTarget ? 1 : 0}}>
          <Origin sx={{pointerEvents: info?.info ? '' : 'none', position: 'relative'
          }}>
  
            <Card  onClick={() => {setIsExpanded( info?.info ? !isExpanded : false)}} minHeight='100px' justifyContent={'space-between'} alignItems='center' display={'flex'} flexDirection='column' 
                sx={{border: info?.isUserToken ? '0px solid green' : '0px solid green', borderRadius: '10px', padding: '0px', position: 'relative', paddingBottom: '0px', paddingTop: '0px'}}>
                <OpenInNewIcon sx={{width: '25px', height: '25px', position: 'absolute', right: 10, top: 10, fill: "#E9E9E9"}}/>
                 { info && info?.currentRouterLogo?.length > 0 &&
                 <>
                    <Box sx={{display: { xs: 'none', md: 'flex'}}}>
                      <img src={info ? info?.currentRouterLogo : ""}  style={{width: '40px', height: '40px', objectFit: 'cover', position: 'absolute', right: 20, top: '32%'}}/>  
                    </Box>
                    <Box sx={{display: { xs: '', md: 'none'}}}>
                          <img src={info ? info?.currentRouterLogo : ""}  style={{width: '40px', height: '40px', objectFit: 'cover', position: 'absolute', right: 0, top: '35%'}}/>  
                    </Box>
                    </>
                }
                { !info.info &&
                    <EmptyLoad width='100%' height='100%'/>
                }
                <Box mt={1} display='flex' flexDirection='column'  alignItems='center' gap='10px' sx={{height: '100%', minWidth: '98%', border: '0px solid red', position: 'relative'}}> 
                    { !tokenImage &&
                      <Box style={{width: '100%', height: '120px', border: "1px solid grey"}}>
                      </Box>
                    }
                    {tokenImage?.length > 0 && 
                      <img src={tokenImage.length > 0 ? tokenImage : ""}  style={{width: '100%', height: '120px', minWidth: '100px', objectFit: 'cover', borderRadius: '10px'}}/>  
                    }
                </Box>
                <Box mt={2} mb={2} display='flex' gap={'5px'} flexDirection='column' sx={{border: '0px solid blue', width: '100%', paddingLeft: '15px', paddingRight: '15px'}}>
                    <PoolName >
                        {info?.info?.tokenInfo.tokenName ? ((info?.info?.tokenInfo.tokenName.length ) * 13) > 300 ? truncate(info?.info?.tokenInfo.tokenName, 6) : info?.info?.tokenInfo.tokenName : ''}
                    </PoolName>
                    <PoolSymbol >
                        {info?.info?.tokenInfo.tokenSymbol ? info?.info?.tokenInfo.tokenSymbol.length > 15 ? truncate(info?.info?.tokenInfo.tokenSymbol, 6) : info?.info?.tokenInfo.tokenSymbol : ''}
                    </PoolSymbol>
                    <Box mt={1} display='flex' gap='10px' flexDirection='column'>
                    <InfoBox >
                            <Box display='flex' flexDirection={'row'} justifyContent='space-between' alignItems='center' sx={{border: '0px solid red' , width: '100%', padding: '5px'}}>
                              <Box justifyContent='center' alignItems='center' display='flex' gap='10px'>
                                <PoolName2>
                                      Status
                                  </PoolName2>
                              </Box>
                              <Box display='flex' gap={'10px'} alignItems='center'>
                              <Box sx={{minWidth: '20px', minHeight: '20px', maxHeight: "20px", background: isLaunchStarted && !isLaunchFinished ? 'green' : isLaunchFinished ? 'red' : "orange", borderRadius: '10px'}}/>
                                {!isLaunchFinished  && 
                                  <>
                                  <PoolName2 sx={{border: '0px solid red'}}>
                                      {  isLaunchStarted ? 'Ends in' : 'Starts in' } {`${ days > 0 ?  days <= 9 ? "0" + days + " /": days + " /" : ""}  ${hours <=9 ? "0"+hours : hours}:${minutes <=9 ? "0"+minutes : minutes}:${seconds <= 9 ? "0" + seconds: seconds}` }
                                  </PoolName2>
                                  </>

                                }
                                {isLaunchFinished && 
                                  <PoolName2>Ended</PoolName2>
                                }
                              </Box>


                            </Box>
                        </InfoBox>
                        {/* <InfoBox >
                            <Box display='flex' flexDirection={'row'} justifyContent='space-between' alignItems='center' sx={{border: '0px solid red' , width: '100%', padding: '5px'}}>
                                <PoolName2>
                                    Token Total Supply:
                                </PoolName2>
                                <PoolName2>
                                    {info?.info?.tokenInfo?.totalSupply ? Number(getBalanceInEther(info?.info.tokenInfo?.totalSupply)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                </PoolName2>
                            </Box>
                        </InfoBox> */}
                        {/* <InfoBox >
                            <Box display='flex' flexDirection={'row'} justifyContent='space-between' alignItems='center' sx={{border: '0px solid red' , width: '100%', padding: '5px'}}>
                                <PoolName2>
                                    Tokens For Presale
                                </PoolName2>
                                <PoolName2>
                                    {info?.info?.presaleInfo?.tokensForPresale ? Number(getBalanceInEther(info?.info.presaleInfo?.tokensForPresale )).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                </PoolName2>
                            </Box>
                        </InfoBox> */}
                        <InfoBox >
                            <Box display='flex' flexDirection={'row'} justifyContent='space-between' alignItems='center' sx={{border: '0px solid red' , width: '100%', padding: '5px'}}>
                                <PoolName2>
                                    Participants
                                </PoolName2>
                                <PoolName2>
                                    {info ? info?.participants : '-.-'}
                                </PoolName2>
                            </Box>
                        </InfoBox>
                        <InfoBox >
                            <Box display='flex' flexDirection={'row'} justifyContent='space-between' alignItems='center' sx={{border: '0px solid red' , width: '100%', padding: '5px'}}>
                                <PoolName2>
                                   MCap at launch
                                </PoolName2>
                                <PoolName2>
                                    {`${calculateMCapAtLaunch().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} FTM`}
                                </PoolName2>
                            </Box>
                        </InfoBox>
                        <ProgressBar midPoint={info?.raised} startPoint={Number(getBalanceInEther(info?.info?.presaleInfo?.softCap) * 100 / Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)))}
                         endPoint={Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap))}/>
                         <Box display='flex' justifyContent='center' sx={{width: '100%'}}>
                         <PoolName2 textAlign='center'>
                            Soft Cap: ( {Number(getBalanceInEther(info?.info?.presaleInfo?.softCap))} FTM )
                          </PoolName2>
                         </Box>
  
                    </Box>

                </Box>
            </Card>
            </Origin>
        </Grid>

    </>
  );
};

export default PresaleMini;
