import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, Radio, TextField, Typography, styled } from '@mui/material';
import DataSelect from 'components/common/Select/DataSelect';
import { getRouters, getTokenDeployerInfo } from 'utils/deployTokens';
import ImageUploadBox from 'components/DeployToken/ImapeUploadBox';
import { ImageType } from 'react-images-uploading';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import axios from 'axios';
import { useAppSelector } from 'state/hooks';

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

  const LenTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'rgba(255,255,255,0.7)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'block',
      },
    },
  }));

  const InfoCardLabelFees = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    width: '100%',
    cursor: 'pointer',
    textWrap: 'wrap',
    textAlign: 'left',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '16px',
      img: {
        display: 'block',
      },
    },
  }));
interface Props {
  isCheckErrors: boolean;
    liquiditySectionChanges: (ammm: any, lockDuration: string, isBurnChecked: boolean,
        isLiquidityChecked: boolean, isLiquidityCreateChecked: boolean, images: ImageType[], isElock: boolean, 
        description: string, tokenTg: string, tokenX: string, tokenDiscord: string, tokenWeb: string
    ) => void
}

const LiquiditySection: FC<Props> = ({ liquiditySectionChanges, isCheckErrors }) => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

    const [isLiquidityChecked, setChecked] = useState(true);
    const [isBurnChecked, setBurnChecked] = useState(false);
    const [isLiquidityCreateChecked, setLiquidityCreateChecked] = useState(false);
    const [ammm, setAmm] = useState<any>(undefined);
    const [amountDays, setAmountDays] = useState<string>('');
    const [isAmountDaysError, setAmountDaysError] = useState(false);
    const [imageUploaded, setImageUploaded] = useState(false);
    const [images, setImages] = useState<ImageType[]>([]);
    const [isSpecsExpanded, setSpecsExpanded] = useState<boolean>(false);
    const [isELock, setIsElock] = useState(false);
    const tokenDeployerInfo = getTokenDeployerInfo(Number(selectedChainId));
    const [tokenDescrtiption, setTokenDescription] = useState<string>('');
    const [tokenTg, setTokenTg] = useState<string>('');
    const [tokenX, setTokenX] = useState<string>('');
    const [tokenDiscord, setTokenDiscord] = useState<string>('');
    const [tokenWeb, setTokenWeb] = useState<string>('');

    const onChangeDescription = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenDescription(newAmount);
      liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock,
         newAmount, tokenTg, tokenX, tokenDiscord, tokenWeb)
      // tokenomicsSectionChanges( totalSupply ,tokensPresale, newAmount)
  };
    const onChangeTg = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenTg(newAmount);
      liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
         isELock, tokenDescrtiption, newAmount, tokenX, tokenDiscord, tokenWeb)
      // tokenomicsSectionChanges( totalSupply ,tokensPresale, newAmount)
  };
  const onChangeX = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenX(newAmount);
      liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
        isELock, tokenDescrtiption, tokenTg, newAmount, tokenDiscord, tokenWeb)
      // tokenomicsSectionChanges( totalSupply ,tokensPresale, newAmount)
  };
  const onChangeDiscord = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenDiscord(newAmount);
    liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
      isELock, tokenDescrtiption, tokenTg, tokenX, newAmount, tokenWeb)
    // tokenomicsSectionChanges( totalSupply ,tokensPresale, newAmount)
  };
  const onChangeWeb = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenWeb(newAmount);
    liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images,
      isELock, tokenDescrtiption, tokenTg, tokenX, tokenDiscord, newAmount)
    // tokenomicsSectionChanges( totalSupply ,tokensPresale, newAmount)
  };
    const imageUpload = (imageList: any) => {
        setImages(imageList)
        liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, imageList, isELock, tokenDescrtiption, 
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }

    const onChangeAmountDays= (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
    
        if( Number(newAmount) < 15 ){
          setAmountDaysError(true)
        }
        else 
          setAmountDaysError(false)
    
        setAmountDays(newAmount);
        liquiditySectionChanges(ammm, newAmount, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
        // setAmountTokenInWei(getBalanceInWei(Number(newAmount).toString() || '0'));
      };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(true);
        setBurnChecked(false)
        setLiquidityCreateChecked(false)
        setIsElock(false)
        liquiditySectionChanges(ammm, amountDays, false, true, false, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };
    
      const handleCreateLpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(false);
        setBurnChecked(false)
        setLiquidityCreateChecked(true)
        setIsElock(false)
        liquiditySectionChanges(ammm, amountDays, false, false, true, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };
    
      const handleBurnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBurnChecked(true); //event.target.checked
        setChecked(false);
        setLiquidityCreateChecked(false)
        setIsElock(false)
        liquiditySectionChanges(ammm, amountDays, true, false, false, images,isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };
      const handleChangeElock = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBurnChecked(false); //event.target.checked
        setIsElock(true)
        setChecked(false);
        setLiquidityCreateChecked(false)
        liquiditySectionChanges(ammm, amountDays, true, false, false, images, event.target.checked, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
      };

      const onChangeChain = (option: any) => {
        const routers = getRouters(Number(selectedChainId))
        for( let i = 0; i < routers.length; i+=1 )
        {
          if( routers[i].value === option ){
              setAmm(routers[i])
              liquiditySectionChanges(routers[i], amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock, tokenDescrtiption,
                tokenTg, tokenX, tokenDiscord, tokenWeb
              )
              return
          }
        }
        liquiditySectionChanges(undefined, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, images, isELock, tokenDescrtiption,
          tokenTg, tokenX, tokenDiscord, tokenWeb
        )
        setAmm(undefined)
        setChainSelectOption(option)
        // const selectedChainOption = chainSelectOptions.find((row) => Number(row.id) === Number(option.id));
        // setChainSelectOption(option);
      };


      // TODO USE EFFECT?
  const [chainSelectOption, setChainSelectOption] = useState<any>("Spooky");


  const getAmmSelectOptions = () => {
    const finOptions = []
    const ammOptions = getRouters(Number(selectedChainId));
    for( let i = 0; i < ammOptions.length; i++ ){
      if( isELock && ammOptions[i].value === "5"  ){
          finOptions.push(ammOptions[i])
      }
      else if( !isELock && !isLiquidityChecked ) {
        // if( ammOptions[i].value !== "5" )
          finOptions.push(ammOptions[i])
      }
      if( !isELock && isLiquidityChecked ) {
        if( ammOptions[i].value !== "5" )
          finOptions.push(ammOptions[i])
      }
    }
    // setAmmSelectOptions(finOptions)
    return finOptions
  };

  const getNotionText = () => {
    if (isLiquidityCreateChecked ){
      return tokenDeployerInfo ? tokenDeployerInfo.noLockDescription : ""
    }
      if( isBurnChecked ){
        return tokenDeployerInfo ? tokenDeployerInfo.sccDescription : ""
      }
      if( isLiquidityChecked ){
        return tokenDeployerInfo ? tokenDeployerInfo.lockDescription : ""
      }
      if( isELock )
        return tokenDeployerInfo ? tokenDeployerInfo.eLockDescription : ""

      return tokenDeployerInfo ? tokenDeployerInfo.tokenDescription : ""
  };

  const ammSelectOptions = getAmmSelectOptions()

  return (
    <>
        <Box mt={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
            <InfoCardLabel mt={1}>
                Liquidity Options
            </InfoCardLabel>
            <Box mt={2} display='flex'>
                <Box display='flex' > 
                      {/* sx={{opacity: 0.3, pointerEvents: 'none'}} */}
                        <Radio checked={isBurnChecked} 
                            onChange={handleBurnChange}/>
                        <ValueTitle>
                        SCC
                        </ValueTitle>
                      </Box>
                      <Box display='flex'>
                      <Radio  
                      checked={isELock}
                      onChange={handleChangeElock}/>
                      <ValueTitle>
                        ELock
                      </ValueTitle>
                    </Box>
                      <Box display='flex'>
                        <Radio  
                        checked={isLiquidityChecked}
                        onChange={handleChange}/>
                        <ValueTitle>
                          Liquidity Lock
                        </ValueTitle>
                      </Box>
                      <Box display='flex'>
                        <Radio  
                        checked={isLiquidityCreateChecked}
                        onChange={handleCreateLpChange}/>
                        <ValueTitle>
                          No Lock
                        </ValueTitle>
                      </Box>
                </Box>
                <Box display='flex' flexDirection='column' justifyContent='center' sx={{width: '100%'}}>
                  { !isELock &&
                      <Box display='flex' flexDirection='row'>
                      <Box mb={1} mt={1} display='flex' justifyContent='center' sx={{width: '100%'}}>
                          <DataSelect label='' width='200px' isError={isCheckErrors ? ammm === undefined : false}  onSelect={onChangeChain} options={ammSelectOptions} value={chainSelectOption} />
                      </Box>
                  </Box>                
                  }
                </Box>
                { isELock && 
                        <Box display='flex' mt={2} mb={1} justifyContent='center' alignItems='center' textAlign='center'> 
                        <ValueTitle >
                           EqualizerDex (V2)
                        </ValueTitle>
                      </Box>                        
                      }

<Box display='flex' sx={{padding: '10px', border: '1px solid grey', borderRadius: '10px'}}>
                    <ValueTitle mt={1} mb={1}>
                            {getNotionText()}
                      </ValueTitle>
                  </Box>

                { (isLiquidityChecked || isELock)  && 
                                  <Box display='flex' mt={0} mb={0} sx={{
                                  border: '0px solid grey',
                                  padding: '10px',
                                  width: '100%',
                                  justifyContent: 'center',
                                  }}>
                                  <TextField variant="outlined" placeholder='Lock Duration' required={true} onChange={onChangeAmountDays} autoComplete='off' error={isCheckErrors || isAmountDaysError ? amountDays.length === 0 ||  Number(amountDays) < ( isELock ? 0 :  15 )  : false} label={ isAmountDaysError && Number(amountDays) < ( isELock ? 0 :  15 )  ? "Lock Duration minimun 15 days" : "Lock Duration (days)"}  value={amountDays} />
                              </Box>
                }
                <ImageUploadBox imageUpload={imageUpload} hide={imageUploaded}/>
                <ValueTitle >
                  Presale Description 
                </ValueTitle>
                <Box gap={1} flexDirection='column'  display='flex' sx={{
                        border: '0px solid grey',
                        padding: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        position: 'relative'
                        }}>
                  <LenTitle sx={{position: "absolute", top: 5, right: 15}} >
                    {tokenDescrtiption.length}/250 
                  </LenTitle>  
                  <TextField disabled={images.length === 0} sx={{width: '100%'}} rows={3} multiline={true}  placeholder='Presale Description'
                        onChange={onChangeDescription}  autoComplete='off' 
                        label="Presale Description" value={tokenDescrtiption} />
                  <Box display='flex' sx={{flexDirection: {xs: 'column', md: "row"}}} gap={1}>
                  <TextField disabled={images.length === 0} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Telegram URL'
                        onChange={onChangeTg}  autoComplete='off' 
                        label="Telegram URL" value={tokenTg} />
                  <TextField disabled={images.length === 0} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Twitter URL'
                        onChange={onChangeX}  autoComplete='off' 
                        label="Twitter URL" value={tokenX} />
                  <TextField disabled={images.length === 0} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Web URL'
                        onChange={onChangeWeb}  autoComplete='off' 
                        label="Web URL" value={tokenWeb} />
                  </Box>
                </Box>
              </Box>
    </>
  );
};

export default LiquiditySection;
