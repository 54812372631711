import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Radio, TextField, Typography, styled } from '@mui/material';
import DataSelect from 'components/common/Select/DataSelect';
import { getRouters, getTokenDeployerInfo } from 'utils/deployTokens';
import ImageUploadBox from 'components/DeployToken/ImapeUploadBox';
import { ImageType } from 'react-images-uploading';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { resetDataPresale, setPresaleReloadCountAsync } from 'state/actions';
import { usePinata } from 'hooks/usePinata';

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

  const LenTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'rgba(255,255,255,0.7)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '10px',
      lineHeight: '12px',
      img: {
        display: 'block',
      },
    },
  }));

  const InfoCardLabelFees = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    width: '100%',
    cursor: 'pointer',
    textWrap: 'wrap',
    textAlign: 'left',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '16px',
      img: {
        display: 'block',
      },
    },
  }));
interface Props {
    // liquiditySectionChanges: (ammm: any, lockDuration: string, isBurnChecked: boolean,
    //     isLiquidityChecked: boolean, isLiquidityCreateChecked: boolean, images: ImageType[], isElock: boolean, 
    //     description: string, tokenTg: string, tokenX: string, tokenDiscord: string, tokenWeb: string
    // ) => void
    close: () => void
    tokenImage: string
    presaleAddress: string
}

const DescriptionEdit: FC<Props> = ({ tokenImage, close, presaleAddress  }) => {
    const [imageUploaded, setImageUploaded] = useState(false);
    const [images, setImages] = useState<ImageType[]>([]);
    const [tokenDescrtiption, setTokenDescription] = useState<string>('');
    const [tokenTg, setTokenTg] = useState<string>('');
    const [tokenX, setTokenX] = useState<string>('');
    const [tokenDiscord, setTokenDiscord] = useState<string>('');
    const [tokenWeb, setTokenWeb] = useState<string>('');
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [isImageUploadingError, setIsImageUploadingError] = useState(false);
    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [imageUrlUploaded, setImageUrlUploaded] = useState("");

    const dispatch = useAppDispatch();
    const { presaleReloadCount } = useAppSelector((presale) => presale.presale);
    const {uploadImageToPinata, deleteJsonFromPinata, uploadJsonToPinata} = usePinata(0)

    const onChangeDescription = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenDescription(newAmount);
  };
    const onChangeTg = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenTg(newAmount);
  };
  const onChangeX = (e: any) => {
      const newAmount = e.target.value;
      if( newAmount.length > 250 )
          return
      setTokenX(newAmount);
  };
  const onChangeDiscord = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenDiscord(newAmount);
  };
  const onChangeWeb = (e: any) => {
    const newAmount = e.target.value;
    if( newAmount.length > 250 )
        return
    setTokenWeb(newAmount);
  };
    const imageUpload = (imageList: any) => {
        setImages(imageList)
        // liquiditySectionChanges(ammm, amountDays, isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked, imageList, isELock, tokenDescrtiption, 
        //   tokenTg, tokenX, tokenDiscord, tokenWeb
        // )
      };

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }


      const onConfirm = async () => {
        if( tokenImage ){
            console.log("Change existing")
            setIsImageUploading(true)

            // sendFileToIPFSExisting(presaleAddress)
            try{
                await deleteJsonFromPinata(presaleAddress)
            }
            catch(err){
                // 
            }
            try{
                await uploadJsonToPinata(presaleAddress,tokenDescrtiption, tokenTg, tokenX, tokenWeb)
                // dispatch(resetDataPresale())
                // dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
            }
            catch(err){

            }
            setIsImageUploading(false)
            close()
            dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
        }
        else{
            
            if( images.length > 0 ){
                console.log(`Presale address ${presaleAddress}`)
                try{
                    if( images[0].file ){
                        setIsImageUploading(true)
                        const url = await uploadImageToPinata(presaleAddress, images[0].file, 1);
                        if( url.length > 0 ){
                            console.log(`Image updated succesufully!`)
                            setIsImageUploading(false)
                            setIsImageUploadingError(false)
                            setImageUrlUploaded(url)
                        } else {
                            console.log(`Error while Image update!`)
                            setIsImageUploading(false)
                            
                            setIsImageUploadingError(true)
                        }
                    }
                }
                catch(err){
                    setIsImageUploading(false)

                }
                // sendFileToIPFS(presaleAddress)
            }
            try{
                await deleteJsonFromPinata(presaleAddress)
            }
            catch(err){

            }
            try{
                await uploadJsonToPinata(presaleAddress,tokenDescrtiption, tokenTg, tokenX, tokenWeb)
            }
            catch(err){

            }
            close()
            dispatch(setPresaleReloadCountAsync(presaleReloadCount + 1));
        }
      };
      const [imageUrl, setImageUrl] = useState(null);

  
  return (
    <>
        <Box mt={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
            <InfoCardLabel mt={1}>
                Deployer Dashboard
            </InfoCardLabel>
                {tokenImage?.length > 0 &&
                                <Box mt={2} display='flex' sx={{position: 'relative'}}>
                                <img src={tokenImage?.length > 0 ? tokenImage : ""} style={{
                                    // width: '100%',
                                    // height: '100%',
                                    minWidth: '165px',
                                    minHeight: '165px',
                                    maxWidth: '200px',
                                    
                                    objectFit: 'cover',
                                    borderRadius: '10px'
                                }}/>
                                </Box>
                }
                {tokenImage?.length === 0 || !tokenImage &&
                    <Box justifyContent='center' alignItems='center' textAlign='center'>
                        <ImageUploadBox imageUpload={imageUpload} hide={imageUploaded}/>
                    </Box>
                }
                <Box gap={1} flexDirection='column'  display='flex' sx={{
                        border: '0px solid grey',
                        padding: '0px',
                        marginTop: '10px',
                        marginBottom: '10px',
                        width: '100%',
                        position: 'relative'
                 }}>
                  <LenTitle sx={{position: "absolute", top: 5, right: 15}} >
                    {tokenDescrtiption.length}/250 
                  </LenTitle>  
                  <TextField disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={3} multiline={true}  placeholder='Presale Description'
                        onChange={onChangeDescription}  autoComplete='off' 
                        label="Presale Description" value={tokenDescrtiption} />
                  <Box display='flex' sx={{flexDirection: {xs: 'column', md: "row"}}} gap={1}>
                  <TextField disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Telegram URL'
                        onChange={onChangeTg}  autoComplete='off' 
                        label="Telegram URL" value={tokenTg} />
                  <TextField disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Twitter URL'
                        onChange={onChangeX}  autoComplete='off' 
                        label="Twitter URL" value={tokenX} />
                  <TextField disabled={images.length === 0 && !tokenImage} sx={{width: '100%'}} rows={1} multiline={false}  placeholder='Web URL'
                        onChange={onChangeWeb}  autoComplete='off' 
                        label="Web URL" value={tokenWeb} />
                  </Box>
                </Box>
              </Box>
              <Box display='flex' gap={10} justifyContent='center'>
                    <Button onClick={onConfirm}>
                        {isImageUploading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : "Confirm"}
                    </Button>
                    <Button onClick={close}>
                        Cancel
                    </Button>
                </Box>
    </>
  );
};

export default DescriptionEdit;