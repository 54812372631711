import { presaleInfos } from 'config/constants/presale';
import PresaleAbi from 'config/abi/Presale.json';
import AtlanticTokenAbi from 'config/abi/AtlanticToken.json';
import PresaleFTMAbi from 'config/abi/PresaleDeployer.json';
import PresaleSmartFTMAbi from 'config/abi/SmartPresale.json';

export const getPresaleInfo = (chainId: number) => {
    const bridge = presaleInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
    return bridge;
};

export const getPresaleABI = (chainId: number) => {
    if( chainId === 169 ){
        return PresaleAbi
    }
    if( chainId === 250 ){
        return PresaleFTMAbi
    }
    return [];
};

export const getSmartPresaleABI = () => {
    return PresaleSmartFTMAbi;
};

export const getAtlanticTokenABI = (chainId: number) => {
    if( chainId === 169 ){
        return AtlanticTokenAbi
    }
    return [];
};