import { RouterCategory } from "types/deploy";
import { icons, tokens } from "./assets";

const tokenAdditionalInfo = [
  {
    tokenCA: "0xad531012c0651a85A212F10fC97b3EC15684548a",
    twitter: "https://x.com/skittyftm",
    tg: "https://t.me/SKITTY9",
    discord: "",
    web: ""
  },
  {
    tokenCA: "0xEE9fF3EFd508741492B5bd45269aa45a43bE59c8",
    twitter: "https://x.com/TangoJRT",
    tg: "",
    web: "https://www.tango.meme",
    discord: "https://discord.com/invite/v8YBtkVBXf"
  }
]

const fantomRouters = [
    {
      value: "1",
      address: '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
      name: "SpookySwap (V2)",
      routerType: RouterCategory.V2,
      logo: icons.spookyRouter,
      buyUrl: "https://spooky.fi/#/best-swap",
      isElock: false
    },
    {
      value: "2",
      address: '0x16327E3FbDaCA3bcF7E38F5Af2599D2DDc33aE52',
      name: "SpiritSwap (V2)",
      routerType: RouterCategory.V2,
      logo: icons.SpiritRouter,
      buyUrl: "https://spiritswap.finance/chain/ftm/swap",
      isElock: false
    },
    {
      value: "3",
      address: '0x5023882f4D1EC10544FCB2066abE9C1645E95AA0',
      name: "WigoSwap (V2)",
      routerType: RouterCategory.V2,
      logo: icons.WigoRouter,
      buyUrl: "https://wigoswap.io/swap",
      isElock: false
    },
    {
      value: "4",
      address: '0x2E14B53E2cB669f3A974CeaF6C735e134F3Aa9BC',
      name: "VelocimeterDex (V2)",
      routerType: RouterCategory.EQUAL_VEE3,
      logo: tokens.fvmLogo,
      buyUrl: "https://www.velocimeter.xyz/swap",
      isElock: false
    },
    {
      value: "5",
      address: '0x2aa07920E4ecb4ea8C801D9DFEce63875623B285',
      name: "EqualizerDex (V2)",
      routerType: RouterCategory.EQUAL_VEE3,
      logo: icons.equalRouter,
      buyUrl: "https://equalizer.exchange/swap",
      isElock: true
  },
  ]
  
  const baseRouters = [
    {
      value: "1",
      address: '0x2F87Bf58D5A9b2eFadE55Cdbd46153a0902be6FA',
      name: "EqualizerDex (V2)",
      routerType: RouterCategory.EQUAL_VEE3,
      logo: icons.equalRouter,
      buyUrl: "https://equalizer.exchange/swap",
      isElock: false
  },
  {
    value: "2",
    address: '0xcF77a3Ba9A5CA399B7c97c74d54e5b1Beb874E43',
    name: "Aerodrome (V2)",
    routerType: RouterCategory.V4,
    logo: tokens.aeroLogo,
    buyUrl: "https://aerodrome.finance",
    factory: "0x420DD381b31aEf6683db6B902084cB0FFECe40Da",
    isElock: false
  }
  ]

  const tokenDeployers = [ 
//     {
//       chain: 250,
//       // deployers: ["0x78647374a47E0642737e6d3353385B3eB78fd6a5", "0x147404571B8402F4ABb8378C5b56B59d0f3F17C8"],
//  //0x85ca6F0e11aB7359024B4D944580fc4A1b57388b

//       deployers: ["0x78647374a47E0642737e6d3353385B3eB78fd6a5", "0x415022f5Df6AD4e892fe39235C48276885c22297"],
//       tokenDeployer: "0x415022f5Df6AD4e892fe39235C48276885c22297",
//       ourLocker: "0x895D77E60313778D7e33334c62bD592F046760d7",
//       eLocker: "0x2f20A659601d1c161A108E0725FEF31256a907ad",
//       memeDao: "0xb48c4eBffc738e57479904BB14Cb8F8A2F7AC5Ee",
//       nativeToken: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
//       nativeTokenName: "FTM",
//       scc: true,
//       elock: true,
//       dexscreenerChain: "fantom",
//       basedLock: true,
//       version: 3, 
//       sccDescription: "This option lets you Create Liquidity on a dex of your choice and sends your LP to Sonic Community Council (SCC) multisig wallet.This option can be used to qualify for Meme Competition by Fantom Foundation that can migrate your liquidity to Sonic.",
//       lockDescription: "Liquidity Locker is a great way to secure community trust. Created liquidity is sent to a Vault that holds it and can be withdrawn only by You after lock period expiration. NOTE: Recommended is min 6-month lock to qualify for Fantom Foundation Meme Season Competition. For full info pls visit https://blog.fantom.foundation/introducing-meme-season-competition-up-to-10-million-sftmx-in-prizes/",      
//       eLockDescription: "This option lets you lock liquidity, claim trading fees, participate in Equalizer’s flywheel and be eligible for Meme Competition from FF. Based Labs keeps 10% of trading fees.",
//       tokenDescription: "This option lets You create a token without adding liquidity. Please make sure you lock or burn LP to keep investors safe!",
//       noLockDescription: "This option lets you Create Liquidity on a dex of your choice without any locking. This option can be used if You are preapproved for a gauge on a ve3,3 DEX."
//     },
{
  chain: 250,
  // deployers: ["0x78647374a47E0642737e6d3353385B3eB78fd6a5", "0x147404571B8402F4ABb8378C5b56B59d0f3F17C8"],
//0x85ca6F0e11aB7359024B4D944580fc4A1b57388b

  // deployers: ["0x78647374a47E0642737e6d3353385B3eB78fd6a5", "0x85ca6F0e11aB7359024B4D944580fc4A1b57388b"],
  // tokenDeployer: "0x85ca6F0e11aB7359024B4D944580fc4A1b57388b",
  deployers: ["0x78647374a47E0642737e6d3353385B3eB78fd6a5", "0x415022f5Df6AD4e892fe39235C48276885c22297"],
  tokenDeployer: "0x415022f5Df6AD4e892fe39235C48276885c22297",
  ourLocker: "0x895D77E60313778D7e33334c62bD592F046760d7",
  eLocker: "0x2f20A659601d1c161A108E0725FEF31256a907ad",
  memeDao: "0xb48c4eBffc738e57479904BB14Cb8F8A2F7AC5Ee",
  nativeToken: "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83",
  nativeTokenName: "FTM",
  scc: true,
  elock: true,
  dexscreenerChain: "fantom",
  basedLock: true,
  version: 3, 
  sccDescription: "This option lets you Create Liquidity on a dex of your choice and sends your LP to Sonic Community Council (SCC) multisig wallet.This option can be used to qualify for Meme Competition by Fantom Foundation that can migrate your liquidity to Sonic.",
  lockDescription: "Liquidity Locker is a great way to secure community trust. Created liquidity is sent to a Vault that holds it and can be withdrawn only by You after lock period expiration. NOTE: Recommended is min 6-month lock to qualify for Fantom Foundation Meme Season Competition. For full info pls visit https://blog.fantom.foundation/introducing-meme-season-competition-up-to-10-million-sftmx-in-prizes/",      
  eLockDescription: "This option lets you lock liquidity, claim trading fees, participate in Equalizer’s flywheel and be eligible for Meme Competition from FF. Based Labs keeps 10% of trading fees.",
  tokenDescription: "This option lets You create a token without adding liquidity. Please make sure you lock or burn LP to keep investors safe!",
  noLockDescription: "This option lets you Create Liquidity on a dex of your choice without any locking. This option can be used if You are preapproved for a gauge on a ve3,3 DEX."
},
    {
      chain: 8453,
      deployers: ["0x48d6b31680C4e8E2419749E1E65ac4d022d9ebBf"],
      tokenDeployer: "0x48d6b31680C4e8E2419749E1E65ac4d022d9ebBf",
      ourLocker: "0x86BBAf8cf41EF6B8aE7085aCf4243133dc6869c4",
      eLocker: "",
      memeDao: "",
      nativeToken: "0x4200000000000000000000000000000000000006",
      nativeTokenName: "ETH",
      dexscreenerChain: "base",
      scc: false,
      elock: false,
      basedLock: true,
      version: 2,
      sccDescription: "",
      lockDescription: "Liquidity Locker is a great way to secure community trust.",
      eLockDescription: "",
      tokenDescription: "This option lets You create a token without adding liquidity. Please make sure you lock or burn LP to keep investors safe!",
      noLockDescription: "This option lets you Create Liquidity on a dex of your choice without any locking. This option can be used if You are preapproved for a gauge on a ve3,3 DEX."
    }
  ]

  
  
  // FTM
  const routersInfo = [...fantomRouters];
  const baseRoutersInfo = [...baseRouters];
  const tokensAdditionalInfo = [...tokenAdditionalInfo]
  const tokenDeployersInfo = [...tokenDeployers]

  export { routersInfo, tokensAdditionalInfo, tokenDeployersInfo, baseRoutersInfo};