// https://docs.google.com/spreadsheets/d/1GfddTVlhnPX1MKUXJE3ihomIxf-lQS0yk3bKvZzbj_M/edit?usp=sharing

/* eslint-disable no-console */
import axios from 'axios';

export const getTokenIdFromCGC = (nativeCurrency: string): string => {
  if (nativeCurrency === 'FTM') return 'fantom';

  return 'fantom';
};

function parseCSV(csvText : any) {
    const rows = csvText.split(/\r?\n/); // Split CSV text into rows, handling '\r' characters
    const headers = rows[0].split(','); // Extract headers (assumes the first row is the header row)
    const data = []; // Initialize an array to store parsed data
    for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split(','); // Split the row, handling '\r' characters
        const rowObject: any = {};
        for (let j = 0; j < headers.length; j++) {
            rowObject[headers[j]] = rowData[j];
        }
        data.push(rowObject);
    }
    return data;
}


export const getGoogleImages = async (): Promise<any> => {

    // const response = await fetch('https://docs.google.com/spreadsheets/d/1GfddTVlhnPX1MKUXJE3ihomIxf-lQS0yk3bKvZzbj_M/edit?usp=sharing', {
    // method: 'GET',
    // mode: 'cors',

    // })
    // console.log(response.json())

    const proxy = {
        protocol: 'https',
        host: '176.193.113.206',
        port: 8989
      }



  const tableUrl = `https://docs.google.com/spreadsheets/u/2/d/e/2PACX-1vQMgWP7Jg0t-0GBlzftFmz3MpIlZMUhNgP1BRpxh9Kjcs2HC-Gd1nxqarsp3RuYrZJUKwl-SsFkyuK_/pubhtml`;
  try {
   const res = await axios({
        method: 'get',
        url: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQMgWP7Jg0t-0GBlzftFmz3MpIlZMUhNgP1BRpxh9Kjcs2HC-Gd1nxqarsp3RuYrZJUKwl-SsFkyuK_/pub?output=csv',
        withCredentials: false,
      });
    if (res.status === 200) {
        const parsedCsvData = parseCSV(res.data);
      return parsedCsvData;
    }
  } catch (err) {
    console.log('Google error: ', err);
    return 0;
  }

  return 0;
};

export const getFTMPriceFromCGC = async (): Promise<number> => getGoogleImages();
