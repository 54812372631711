import React from 'react';
import {Box, Typography, Tooltip, Grid, styled, useMediaQuery, useTheme} from '@mui/material';

const LegendTypographyBegin = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    whiteSpace: 'normal',
    textAlign: 'left',
    marginLeft: '10px',
    flex: 1,
}));

const LegendTypographyEnd = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    whiteSpace: 'normal',
    textAlign: 'right',
}));

const ColorBox = styled(Box)(({theme}) => ({
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
}));

const TotalSupplyTypography = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '20px',
    whiteSpace: 'normal',
    textAlign: 'left',
    marginLeft: '0px',
    flex: 1,
}));

const TotalSupplyValueTypography = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '20px',
    whiteSpace: 'normal',
    textAlign: 'right',
}));

interface TokenDistributionProps {
    tokenDiagrammInfo: {
        label: string;
        value: string;
        color: string;
    }[];
    totalSupply: string;
}

const TokenomicsDiagramm: React.FC<TokenDistributionProps> = ({ tokenDiagrammInfo, totalSupply }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const calculatePercentage = (value: string): number => {
        const numericValue = parseFloat(value);
        const numericTotalSupply = parseFloat(totalSupply);
        return (numericValue / numericTotalSupply) * 100;

    };

    function formatValue(value: string): string {
        const numberValue = parseFloat(value);

        if (isNaN(numberValue) || !isFinite(numberValue)) {
            return '--.--';
        }

        const formattedValue = numberValue === Math.floor(numberValue)
            ? numberValue.toFixed(0)
            : numberValue.toFixed(4);

        return numberValue >= 1000
            ? parseFloat(formattedValue).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 })
            : formattedValue;
    }

    return (
        <Box sx={{ width: '100%' }}>
            {/* Progress Bar Section */}
            <Box sx={{ overflow: 'hidden', display: 'flex', height: 30, mb: 2, borderRadius: '10px' }}>
                {tokenDiagrammInfo.map((token, index) => {
                    const percentage = calculatePercentage(token.value);
                    return (
                        <Tooltip
                            key={index}
                            title={percentage <= 2 ? `${percentage.toFixed(2)}%` : ''}
                            placement="bottom"
                            arrow
                        >
                            <Box
                                sx={{
                                    width: `${percentage}%`,
                                    bgcolor: token.color,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative'
                                }}
                            >
                                {percentage > 2 && (
                                    <Typography variant="body2" sx={{ color: 'white' }}>
                                        {`${percentage.toFixed(0)}%`}
                                    </Typography>
                                )}
                            </Box>
                        </Tooltip>
                    );
                })}
            </Box>

            {/* Token Legend Section */}
            <Grid container spacing={2}>
                {tokenDiagrammInfo.map((token, index) => (
                    <Grid item xs={12} key={index}>
                        <Grid container alignItems="center">
                            {/* Color Box */}
                            <Grid item>
                                <ColorBox sx={{ bgcolor: token.color }} />
                            </Grid>

                            {/* Label */}
                            <Grid item xs>
                                <LegendTypographyBegin>
                                    {token.label}
                                </LegendTypographyBegin>
                            </Grid>

                            {/* Value */}
                            <Grid item sx={{ textAlign: isMobile ? 'center' : 'right', mr: isMobile ? 0 : 2 }}>
                                <LegendTypographyEnd>
                                    {formatValue(token.value)}
                                </LegendTypographyEnd>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}

                {/* Total Supply Row */}
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        {/* Total Supply Label */}
                        <Grid item xs>
                            <TotalSupplyTypography>
                                Total Supply
                            </TotalSupplyTypography>
                        </Grid>

                        {/* Total Supply Value */}
                        <Grid item sx={{ textAlign: isMobile ? 'center' : 'right', mr: isMobile ? 0 : 2 }}>
                            <TotalSupplyValueTypography>
                                {formatValue(totalSupply)}
                            </TotalSupplyValueTypography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};


export default TokenomicsDiagramm;
