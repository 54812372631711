import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, Typography, styled } from '@mui/material';

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));  

  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

interface Props {
    tokenLaunchPrice: string
    totalSupply: string
    isCheckErrors: boolean
    postPresaleSectionChanges: (launchPrice: string) => void
}

const PostPresaleSection: FC<Props> = ({ tokenLaunchPrice, totalSupply, postPresaleSectionChanges, isCheckErrors  }) => {
    const [launchPrice, setLaunchPrice] = useState<string>('');
    const [tokensPresale, setTokensPresale] = useState<string>('');
    const [presaleTokenPrice, setPresaleTokenPrice] = useState<string>('');
    const [mcapAtLaunch, setMcap] = useState<string>('');

    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }

    const onChangeLaunchPrice = (e: any) => {
        if (!isNumeric(e.target.value)) return;
        const newAmount = e.target.value;
        setLaunchPrice(newAmount);
        postPresaleSectionChanges(newAmount)

    };
  return (
    <>
        <InfoCardLabel mt={2}>
          Token Launch Specs
        </InfoCardLabel>
        <Box mt={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>

            <Box mt={2} gap='10px' display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{border: '0px solid blue', width: '100%'}}>
              <Box  display='flex' sx={{
                      border: '0px solid grey',
                      padding: '10px',
                      width: '100%'
                      }}>
                      <TextField placeholder='Launch Price' disabled={tokenLaunchPrice.length === 0 || Number(tokenLaunchPrice) === 0} required={true} 
                      onChange={onChangeLaunchPrice}  autoComplete='off' error={ Number(launchPrice) <  Number(tokenLaunchPrice)  ? launchPrice.length === 0 || Number(launchPrice) <  Number(tokenLaunchPrice) : false} 
                      label="Token Launch Price" value={launchPrice} />
              </Box>
                <Box  display='flex' flexDirection='column' alignItems='center' sx={{
                        border: '0px solid grey',
                        borderRadius: '10px',
                        background: "rgba(6, 1,1, 0.3)",
                        padding: '10px',
                        width: '100%'
                        }}>
                    <ValueTitle> MCap at Launch: </ValueTitle>
                    <ValueTitle> {totalSupply ? Number(totalSupply) * Number(launchPrice) : 0} FTM </ValueTitle>
                </Box>
            </Box>

        </Box>
    </>
  );
};

export default PostPresaleSection;
