import { FC, useEffect, useRef, useState } from 'react';
import { Box, Checkbox, CircularProgress, Grid, Pagination, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { BigNumber } from 'ethers';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { useNotification } from 'hooks/useNotification';
import { useWeb3React } from '@web3-react/core';
import { usePinata } from 'hooks/usePinata'
import { ceil } from 'lodash';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { fetchDeployedPresalesDataAsync, fetchDeployedTokensDataAsync } from 'state/actions';
import PresaleMini from './PresaleMini';

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  maxWidth: '1900px',
  alignItems: 'center',
  justifyContent: 'center',
//   alignItems: 'center',
  flexDirection: 'column',
  minHeight: '200px',
  minWidth: '300px',
//   borderRadius: '20px',
//   background: "#2A3239",
//   boxShadow:
//     '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',

  [theme.breakpoints.up('xs')]: {
    padding: '0px 16px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '20px 32px',
  },
}));

const Card = styled(Box)(({ theme }) => ({
  background: "rgba(42,49,57, 0.4)",
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'column',
      gap: '20px'
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: '50px'
    },

}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const FilterLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '16px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

interface PoolCardProps {
  isStaking: boolean
}

const ListedPresale: FC<PoolCardProps> = ({ isStaking }) => {
  const { size, deployedPresales, presaleReloadCount } = useAppSelector((presale) => presale.presale);
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const { onShowNotification } = useNotification();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const { account } = useWeb3React();
  // const deployerInfo = useDeployerInfo();
  const [reloadData, setReloadData] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);
  const {combinedData} = usePinata(reloadCounter)
  const [isLiquidityChecked, setChecked] = useState(false);
  const [isMCapChecked, setMcapChecked] = useState(false);
  const [isAgeChecked, setAgeChecked] = useState(false);

  const [deployedTokensLen, setDeployedLen] = useState(0);
  const [tokensPerPage, setTokensPerPage] = useState(20);
  const [pages, setPages] = useState(0);
  const [loadedTokens, setLoadedTokens] = useState([])
  const [page, setPage] = useState(1);
  const [tokens, setTokens] = useState<any>([]);
  const dispatch = useAppDispatch();
  const [presaleReloads, setPresaleReloads] = useState<number>(0);

  const [isStakerOpened, setStakerOpen] = useState<boolean>();
  const [stakerInfo, setStakerInfo] = useState<any>();
  const [stakerIndex, setStakerIndex] = useState<number>();

  function isNumeric(n: any) {
    return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
  }
  const ftmPrice = useFtmPrice(Number(selectedChainId));

  useEffect(
    () => {
      const timer1 = setTimeout(() => { 
        if( reloadData ){
          setReloadCounter(reloadCounter+1) 
          setReloadData(false);
        }
      }, 25 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deployedPresales, size]
  );

  useEffect(
    () => {
      if( presaleReloads != presaleReloadCount ){
        setPresaleReloads(presaleReloadCount)
        setReloadCounter(reloadCounter+1) 
        setReloadData(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deployedPresales, size, presaleReloadCount]
  );


  useEffect(
    () => {
      if( ftmPrice === 0 )
        return
      if( size > 0 ){
        const tokens = []
        const lastTokens: [number] = [0]
        for( let i = size; i > 0; i-= 1 ){
          tokens.push({index: i - 1})
          if( i < size - tokensPerPage ){
            lastTokens.push(i - 1)
          }
        }
        setTokens(tokens)
        // dispatch(fetchDeployedPresalesDataAsync(account ? account : "", lastTokens , ftmPrice))

      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [size, ftmPrice]
  );

  // useEffect(
  //   () => {
  //     if( Object.values(deployedTokens).length === size ){
  //       setTokens(Object.values(deployedTokens).reverse())
        
  //       if( isLiquidityChecked ){
  //         Object.freeze(deployedTokens);
    
  //         const arrCopy = [...Object.values(deployedTokens)];
  //         const arr = arrCopy.filter((row: any) => isLiquidityChecked ? row?.info?.lockedLiquidity.gt(0) : true)

  //         if( isMCapChecked ){
  //             const arr2 = arr.sort((a : any, b: any) => {
  //             const nameA = a.mcapInUSD; // ignore upper and lowercase
  //             const nameB = b.mcapInUSD; // ignore upper and lowercase
  //             if (nameA < nameB) {
  //               return 1;
  //             }
  //             if (nameA > nameB) {
  //               return -1;
  //             }
  //             // names must be equal
  //             return 0;
  //           });
  //           setTokens(arr2);

  //           // {info?.info.lockedLiquidity.gt(0) ? `$${(Number(getBalanceInEther(info?.info.supply)) * Number(tokenPrice)).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : "--.--"}
  //         }
  //         else if ( isAgeChecked )
  //         {          
  //           const arr2 = arr.sort((a : any, b: any) => {
  //             const nameA = a.priceInUSD; // ignore upper and lowercase
  //             const nameB = b.priceInUSD; // ignore upper and lowercase
  //             if (nameA < nameB) {
  //               return 1;
  //             }
  //             if (nameA > nameB) {
  //               return -1;
  //             }
  //             // names must be equal
  //             return 0;
  //           });
  //           setTokens(arr2);
  //         }
  //         else {
  //           setTokens(arr.reverse());
  //         }
  //       } else if( isMCapChecked ){
  //         const arrCopy = [...Object.values(deployedTokens)];

  //         const arr2 = arrCopy.sort((a : any, b: any) => {
  //           const nameA = a.mcapInUSD; // ignore upper and lowercase
  //           const nameB = b.mcapInUSD; // ignore upper and lowercase
  //           if (nameA < nameB) {
  //             return 1;
  //           }
  //           if (nameA > nameB) {
  //             return -1;
  //           }
  //           // names must be equal
  //           return 0;
  //         });
  //         setTokens(arr2);
  //       } else if( isAgeChecked ) {
  //         const arrCopy = [...Object.values(deployedTokens)];
          
  //         const arr2 = arrCopy.sort((a : any, b: any) => {
  //           const nameA = a.priceInUSD; // ignore upper and lowercase
  //           const nameB = b.priceInUSD; // ignore upper and lowercase
  //           if (nameA < nameB) {
  //             return 1;
  //           }
  //           if (nameA > nameB) {
  //             return -1;
  //           }
  //           // names must be equal
  //           return 0;
  //         });
  //         setTokens(arr2);

  //       }

  //     }
  //   },

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [ isLiquidityChecked, isMCapChecked, isAgeChecked]
  // );

  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleMcapChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMcapChecked(event.target.checked);
    setAgeChecked(false);
  };

  const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgeChecked(event.target.checked);
    setMcapChecked(false);
  };

  const openStaker = (index: number, info: any, images: any) => {
    setStakerIndex(index)
    setStakerInfo(info)
    setStakerOpen(true)
  };

  const closeStaker = () => {
    setStakerOpen(false)
  };

  useEffect(
    () => {
      if( !isStaking ){
        setStakerOpen(false)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isStaking]
  );

  useEffect(
    () => {
      if( tokens ){
        setPages(ceil(tokens.length / tokensPerPage))
        setLoadedTokens(tokens.slice(0,tokensPerPage))
        setPage(1)
        
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokens]
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    try{
      setLoadedTokens(tokens.slice((value-1) * tokensPerPage, (value ) * tokensPerPage))
      setPage(value);
      console.log(loadedTokens)
    }
    catch(err){
      console.log(err)
    }
  };

  return (
    <>
      <Container>
        <InfoLabel>
            Current LBMs
        </InfoLabel>
        <Grid mt={2} container spacing={4} justifyContent='center'>
            <>{loadedTokens && loadedTokens
            // .filter((row: any) => isLiquidityChecked ? row?.info?.lockedLiquidity.gt(0) : true)
            .map((row: any) => <PresaleMini preinfo={row} images={combinedData}/>)}</>
            
        </Grid>
        <Pagination page={page} onChange={handlePageChange} sx={{marginTop: '40px'}} count={pages} color="primary" />
      </Container>
    </>
  );
};

export default ListedPresale;
