import { FC, useState } from 'react';
import { Box, ClickAwayListener, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';

import { ContainedButton } from 'components/common/Button';
import { icons } from 'config/constants/assets';
import React from 'react';

const ActiveItemLogo = styled('img')(() => ({
  width: '21px',
  borderRadius: '50%'
}));


interface DataSelectProps {
  disabled?: boolean;
  options: any[];
  value: any;
  label: string;
  onSelect: (option: any) => void;
  width: string;
  isError: boolean
}

const DataSelect: FC<DataSelectProps> = ({ value, disabled, options, onSelect, label, width , isError}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickAway = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSelectItem = (item: any) => {
    onSelect(item);
    setIsOpen(false);
  };

  const [age, setAge] = useState('0');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
    onSelect(event.target.value)
  };
//   const selectedOption = value ? options.find((option) => option.chainId === value.chainId) : options[0];

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box display='flex' justifyContent='center' sx={{ position: 'relative', zIndex: 1 , border: '0px solid red', width}}>
      <Select

          error={isError}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={age}
          onChange={handleChange}
          autoWidth
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{maxHeight: '35px', minWidth: '150px'}}
        >
          <MenuItem value={0}>
            Choose AMM
          </MenuItem>

          {options
            ?.map((chain) => (
                // <React.Fragment key={chain.name}>
                    <MenuItem value={chain.value}>{chain.name}</MenuItem>
                // </React.Fragment>
          ))}
        </Select>
      </Box>
    </ClickAwayListener>
  );
};

export default DataSelect;
