import { createSlice } from '@reduxjs/toolkit';
import { FarmInfo } from 'types/stake';
import { fetchDeployedTokensGlobalInfo, fetchDeployedTokensGlobalInfoByCA, fetchDeployedTokensSizeInfo, fetchDeployedTokensSizeInfoDB, fetchStakerInfoData, fetchStakerShortInfoData } from './fetchDeployedTokensGlobalInfo';

interface DeployedTokens {
    deployedTokens: { [index: number]: {isUserToken: boolean, symbol: string} };
    size: number;
    sizeDB: number;
    fetchingTokens: { [index: number] : {isLoading: boolean}};
    stakersInfo: { [index: number]: {isUserToken: boolean}}
    stakersPools: { [address: number]: {tokenAddress: string, pools: []}}
    tokenInfoPage: {}
    tokenReloadCount: number;
}

const initialState: DeployedTokens = {
  deployedTokens: [],
  size: 0,
  sizeDB: 0,
  fetchingTokens: [],
  stakersInfo: [],
  stakersPools: [],
  tokenInfoPage: {},
  tokenReloadCount: 0
};

export const deployedTokensSlice = createSlice({
    name: 'deployedTokens',
    initialState,
    reducers: {
        reset: (state) => {
            state.deployedTokens = []
            state.tokenInfoPage = {}
            state.size = 0;
            state.sizeDB = 0;
            state.stakersInfo = [];
            state.stakersPools = [];
            state.tokenReloadCount = 0;
        },
        setFarmGlobalData: (state, action) => {
              for( let i = 0; i < action.payload.data.tokens.length; i+=1 ){
                if( action.payload.data.tokens[i].index !== -1 )
                  state.deployedTokens[action.payload.data.tokens[i].index] = action.payload.data.tokens[i];
              }
          },
        setTokenInfoPage: (state, action) => {
            state.tokenInfoPage = action.payload.data
        },
        setStakerGlobalData: (state, action) => {
              if( action.payload.data.index !== -1 )
                state.stakersInfo[action.payload.data.index] = action.payload.data;
        },

        setStakerPoolsData: (state, action) => {
          if( action.payload.data.tokenAddress.length !== 0 ){
            state.stakersPools[action.payload.data.tokenAddress] = action.payload.data.pools;
          }
    },


        setDeployedTokensSize: (state, action) => {
            state.size = action.payload.data;
        },
        setDeployedTokensSizeDB: (state, action) => {
          state.sizeDB = action.payload.data;
      },
      setTokenReloadCount: (state, action) => {
        state.tokenReloadCount = action.payload.data;
    },

        setUserPresaleInfo: (state, action) => {
        },
    },
});

export const { reset, setFarmGlobalData, setUserPresaleInfo, setDeployedTokensSize, setStakerGlobalData, setStakerPoolsData,setTokenReloadCount, setTokenInfoPage, setDeployedTokensSizeDB } = deployedTokensSlice.actions;

export const resetDataTokens = () => async (dispatch: any): Promise<void> => { 
  dispatch( reset( ))
}
export const setTokenReloadCountAsync = (count: number) => async (dispatch: any): Promise<void> => { 
  console.log(`Reset counter ${count}`)
  dispatch( setTokenReloadCount( {
      data: count
  }))
}
export const fetchDeployedTokensDataAsync =
  (chainId: number, account: string, indexes: [number], ftmPrice: number) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensGlobalInfo(chainId, account, indexes, ftmPrice);
    
    dispatch(
      setFarmGlobalData({
        data,
      })
    );
  };

export const fetchDeployedTokensSizeAsync =
  (chainId: number, deployerAddresses: string[]) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensSizeInfo(chainId, deployerAddresses);

    dispatch(
      setDeployedTokensSize({
        data,
      })
    );
    // const { data2 } = await fetchDeployedTokensSizeInfoDB();
    // console.log(`DB SIZE ${data2}`)
    // dispatch(
    //   setDeployedTokensSizeDB({
    //     data2,
    //   })
    // );
  };

  export const fetchDeployedTokensSizeDBAsync =
  (chainId: number) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensSizeInfoDB(chainId);

    dispatch(
      setDeployedTokensSizeDB({
        data,
      })
    );
  };

  export const fetchStakerShortInfoAsync =
  (account: string, index: number, ftmPrice: number, info: any) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchStakerShortInfoData(account, index, ftmPrice, info);

    dispatch(
      setStakerGlobalData({
        data,
      })
    );
  };

  export const fetchStakerInfoAsync =
  (chainId: number, account: string, ftmPrice: number, info: any) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchStakerInfoData(chainId, account, ftmPrice, info);
    dispatch(
      setStakerPoolsData({
        data,
      })
    );
  };
  export const fetchDeployedTokensDataByCAAsync =
  (chainId: number, account: string, address: string, ftmPrice: number) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchDeployedTokensGlobalInfoByCA(chainId, account, address, ftmPrice);
    
    dispatch(
      setTokenInfoPage({
        data,
      })
    );
  };


  
export default deployedTokensSlice.reducer;
