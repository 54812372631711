import { presaleInfos } from 'config/constants/presale';
import DeployAbi from 'config/abi/Deployer.json';
import DeployV2Abi from 'config/abi/DeployerV2.json';
import DeployV2AbiEq from 'config/abi/DeployerV2Eq.json';

import { routersInfo, tokensAdditionalInfo, tokenDeployersInfo, baseRoutersInfo } from 'config/constants/tokenDeploy';
import { RouterCategory } from 'types/deploy';
import RouterV2 from 'config/abi/UniswapRouterABI.json';
import RouterEqualVee3 from 'config/abi/Vee3RouterABI.json';
import FactoryAerodrome from 'config/abi/AerodoromeABI.json';

// OLD DEPLOYER 0xd5C57b6D3fCc8007ddB780983532EeAFDBdd1Fa7
// OLD 2 0xDBCfC19FaE62dbF2a55E10374091470e19F77769

export const getDeployAddresses = (chainId: number) => {
    const tokens = tokenDeployersInfo.filter((row: any) => chainId === row.chain);
    if( tokens.length > 0 )
        return tokens[0].deployers
    return []
//  return ["0x78647374a47E0642737e6d3353385B3eB78fd6a5", "0x147404571B8402F4ABb8378C5b56B59d0f3F17C8"];
};

export const getTokenDeployer = (chainId: number) => {
    const tokens = tokenDeployersInfo.filter((row: any) => chainId === row.chain);
    if( tokens.length > 0 )
        return tokens[0].tokenDeployer
    return ""
    // return "0x78647374a47E0642737e6d3353385B3eB78fd6a5";
};

export const getLockerAddress = (chainId: number) => {
    const tokens = tokenDeployersInfo.filter((row: any) => chainId === row.chain);
    if( tokens.length > 0 )
        return tokens[0].ourLocker
    return ""
    // return "0xb9B5a0C817e25781E1cf671651b8734B545fe152"
};

export const getTokenDeployerInfo = (chainId: number) => {
    const tokens = tokenDeployersInfo.filter((row: any) => chainId === row.chain);
    if( tokens.length > 0 )
        return tokens[0]
    return undefined
    // return "0xb9B5a0C817e25781E1cf671651b8734B545fe152"
};

export const getDeployTokenABI = (version: number) => {
    if( version === 2 )
        return DeployV2AbiEq;
    return DeployV2Abi;
};

export const getRouters = (chainId: number) => {
    if( chainId === 8453 )
        return baseRoutersInfo;
    return routersInfo
};

export const getTokensAdditioanalInfo = (tokenCa: string) => {
    const tokens = tokensAdditionalInfo.filter((row: any) => tokenCa.toLowerCase() === row.tokenCA.toLowerCase());
    if( tokens.length > 0 )
        return tokens[0]
    return undefined
};

export const getMemDaoAddress = (chainId: number) => {
    const tokens = tokenDeployersInfo.filter((row: any) => chainId === row.chain);
    if( tokens.length > 0 )
        return tokens[0].memeDao
    return ""
    // return "0xb48c4eBffc738e57479904BB14Cb8F8A2F7AC5Ee";
};

export const getRouterABI = (category : RouterCategory) => {
    if( category === RouterCategory.EQUAL_VEE3 ) {
        return RouterEqualVee3
    }
    else if( category === RouterCategory.V4 ){
        return FactoryAerodrome
    }
    return RouterV2
};

// export const getPresaleInfo = (chainId: number) => {
//     const bridge = presaleInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
//     return bridge;
// };