import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import { Config, Login } from 'types/wallet';
import { connectorLocalStorageKey } from 'config/constants/wallet';
import { useAppSelector } from 'state/hooks';

const CardContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '16px',
  cursor: 'pointer',
  padding: '12px 12px',
  background: theme.palette.mode === 'dark' ? 'rgba(217, 217, 217, 0.2)' : 'rgba(217, 217, 217, 0.2)',
  border: '1px solid transparent',
  textAlign: 'center',

  '&:hover': {
    borderColor: theme.palette.mode === 'dark' ? '#2F91FA' : '#2F91FA',
  },
}));

const WalletIconWrapper = styled(Box)({
  width: '62px',
  height: '62px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '5px',
  margin: 'auto',
});

const WalletIcon = styled('img')({
  width: '32px',
});

const WalletName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  marginTop: '9px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

interface Props {
  walletConfig: Config;
  login: Login;
  onDismiss: () => void;
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon, bgColor } = walletConfig;

  const { selectedChainId } = useAppSelector((state) => state.chain);

  return (
    <CardContainer
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
      onClick={() => {
        if (walletConfig.title === 'Other') {
          onDismiss();
        } else {
          login(walletConfig.connectorId, selectedChainId);
          window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
          onDismiss();
        }
      }}
    >
      <WalletIconWrapper sx={{ background: bgColor }}>
        <WalletIcon alt="wallet icon" src={icon} />
      </WalletIconWrapper>
      <WalletName>{title}</WalletName>
    </CardContainer>
  );
};

export default WalletCard;
