import { getBalanceInEther } from 'utils/formatBalance';
import ERC20 from 'config/abi/Erc20.json';
import multicall from 'utils/multicall';
import DeployerAbi from "config/abi/DeployerV2.json"
import { getDeployAddresses, getDeployTokenABI, getLockerAddress, getRouterABI, getRouters, getTokenDeployer, getTokenDeployerInfo, getTokensAdditioanalInfo } from 'utils/deployTokens';
import RouterABI from 'config/abi/UniswapRouterABI.json';
import FactoryABI from 'config/abi/UniswapFactoryABI.json';
import LpPair from 'config/abi/LpPair.json';
import Locker from 'config/abi/LockerABI.json'
import { RouterCategory } from 'types/deploy';
import { getMasterChefAbi } from 'utils/stake';
import { BigNumber, ethers } from 'ethers';
import { getDeployedTokensInfo } from 'config/constants/backend';

// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchDeployedTokens = async (chainId: number, account: string, indexes: [number], ftmPrice: number): Promise<any> => {
  const availabelRouters =  getRouters(chainId);
  let deployerAddress = getTokenDeployer(chainId);

  let ABI = []

  if( chainId === 8453 ){
    
    ABI = getDeployTokenABI(2);
  }
  else 
    ABI = getDeployTokenABI(1);

  // if( chainId === 250 ){
      const offchainDataDeployed = await getDeployedTokensInfo({chain: chainId});
        const tokensDeployed = await Promise.all(
          indexes.map(async (row: any) => {
            try{

              const tokenData = offchainDataDeployed[row]
              if(!tokenData)
                return { index: -1 }
              if( chainId !== tokenData.chain_id )
                return { index: -1 }

              let lpAddress = tokenData.pairAddress
              let currentRouterLogo = ""
              let routerBuyURL = ""
              let price = tokenData.priceFTM;
              let lpCount = tokenData.lpCount;
              let lockerAddress = ""
              let lockerInfo = {id: 0, unlockDate: 0, amount: 0, lockDate: 0, owner: "", index: 0}
              const initialPrice = tokenData.initialPriceFtm
              // console.log(`Initial price ${initialPrice} eth ${lockEth} lock ${lockLiq}`)
              let isUserToken = false;
              if( account ){
                  if( tokenData.deployerAddress.toLowerCase() === account.toLowerCase() ){
                      isUserToken = true
                  }
              }
              const priceInUSD = price * ftmPrice;
              const mcapInUSD = getBalanceInEther(tokenData.supply) * priceInUSD
              let unlockDate = 0;

              if( lpAddress.length > 0 ){
                // console.log(`Get locks ${info.deployerAddress} lp: ${lpAddress}`)
                
                const equalRouter = "0x2aa07920E4ecb4ea8C801D9DFEce63875623B285"
                // const tokenDeployerPresale = "0x147404571B8402F4ABb8378C5b56B59d0f3F17C8"
                
                let lockCheked = false

              // if( tokenData.tokenDeployer.toLowerCase() === tokenDeployerPresale.toLowerCase())
              //   {          
                if( tokenData.router.toLowerCase() === equalRouter.toLowerCase() ) {

                  if( tokenData.elockId > 0 ){

                  }
                  else if( tokenData.elockId <= 0 )
                  {
                  lockerAddress = ""
                    const [numLocks] = await multicall(
                      Locker,
                      [
                        {
                          address: getLockerAddress(chainId),
                          name: 'getUserNumLocksForToken',
                          params: [tokenData.deployerAddress, lpAddress],
                        },
                      ]
                    );
          
                    if( numLocks > 0 ) {
                      const [lockInfo] = await multicall(
                        Locker,
                        [
                          {
                            address: getLockerAddress(chainId),
                            name: 'getUserLockForTokenAtIndex',
                            params: [tokenData.deployerAddress, lpAddress, 0],
                          },
                        ]
                      );
          
                      // getUserLockForTokenAtIndex
                      // console.log(`Num locks = ${numLocks}`)
                      
                      if( lockInfo.length > 4 ){
                        // console.log(`Lock date ${Number(lockInfo[0])} Lock Amount ${getBalanceInEther(lockInfo[1])} Unlock Date ${Number(lockInfo[2])} Owner ${lockInfo[4]}`)
                        unlockDate = Number(lockInfo[2])
                        // console.log(`Current Date: ${new Date().getTime()/ 1000}`)
                        if( new Date().getTime()/ 1000 > unlockDate ){
                          unlockDate = 0;
                        }
                        lockerInfo = {id: lockInfo[3], unlockDate: lockInfo[2], amount: Number(lockInfo[1]), lockDate: lockInfo[0], owner: lockInfo[4], index: 0}
          
                      }
                    }
                    lockCheked = true;
                    
                  }
                }
                // }
                if( !lockCheked ) {

                  lockerAddress = getLockerAddress(chainId)
                  const [numLocks] = await multicall(
                    Locker,
                    [
                      {
                        address: getLockerAddress(chainId),
                        name: 'getUserNumLocksForToken',
                        params: [tokenData.deployerAddress, lpAddress],
                      },
                    ]
                  );
        
                  if( numLocks > 0 ) {
                    const [lockInfo] = await multicall(
                      Locker,
                      [
                        {
                          address: getLockerAddress(chainId),
                          name: 'getUserLockForTokenAtIndex',
                          params: [tokenData.deployerAddress, lpAddress, 0],
                        },
                      ]
                    );
        
                    // getUserLockForTokenAtIndex
                    // console.log(`Num locks = ${numLocks}`)
                    
                    if( lockInfo.length > 4 ){
                      // console.log(`Lock date ${Number(lockInfo[0])} Lock Amount ${getBalanceInEther(lockInfo[1])} Unlock Date ${Number(lockInfo[2])} Owner ${lockInfo[4]}`)
                      unlockDate = Number(lockInfo[2])
                      // console.log(`Current Date: ${new Date().getTime()/ 1000}`)
                      if( new Date().getTime()/ 1000 > unlockDate ){
                        unlockDate = 0;
                      }
                      lockerInfo = {id: lockInfo[3], unlockDate: lockInfo[2], amount: Number(lockInfo[1]), lockDate: lockInfo[0], owner: lockInfo[4], index: 0}
        
                    }
                  } 
                }          
              }
              if( tokenData.router.length > 0 && tokenData.router != "0x0000000000000000000000000000000000000000" ){

                let currentRouter = availabelRouters[0];
                
                for( let i = 0; i < availabelRouters.length; i+=1){
                  if( availabelRouters[i].address === tokenData.router ){
                    currentRouter = availabelRouters[i];
                  }
                }
                currentRouterLogo = currentRouter.logo;
                routerBuyURL = currentRouter.buyUrl;
              }

              const tokenAddInfo =  getTokensAdditioanalInfo(tokenData.tokenAddress);
              const lockedLiquidity = BigNumber.from(tokenData.lockLiq)
              const lockETH = Number(ethers.utils.formatEther(tokenData.lockEth))
              return {index: row, info: {dao: tokenData.dao,
                      deployerAddress: tokenData.deployerAddress,
                      ethAmountLocked: tokenData.lockEth,
                      lockedLiquidity: lockedLiquidity,
                      mainTokenPool: tokenData.pool,
                      name: tokenData.tokenName,
                      router: tokenData.router,
                      supply: ethers.utils.parseEther(tokenData.supply.toString()),
                      timeStamp: tokenData.timestamp,
                      tokenAddress: tokenData.tokenAddress,
                      tokenAmountLocked: tokenData.lockAmount}, symbol: tokenData.tokenSymbol, isUserToken, logo: "", tokenPriceFTM: price, lpAddress, lockLps: lockETH * 2, initialPrice, currentRouterLogo,routerBuyURL, priceInUSD, mcapInUSD, lpPrice: lpCount * 2 , unlockDate, lockerInfo, tokenAddInfo, tokenDeployer: tokenData.tokenDeployer, lockerAddress, elockId : tokenData.elockId };

            }
            catch(err){
              console.log(err)
              return { index: -1 }
            }
          })
        )
        return { tokens: tokensDeployed };
  // }

  try {
        const tokenDeployerInfo = getTokenDeployerInfo(chainId)
        let lockerAddress = getLockerAddress(chainId)
        const tokensDeployed = await Promise.all(
            indexes.map(async (row: any) => { 
              try{
               const [deployedTokenAddress] = await multicall(
                    ABI,
                    [
                      {
                        address: deployerAddress,
                        name: 'deployedTokens',
                        params: [row],
                      },
                    ]
                );
                const [info] = await multicall(
                    ABI,
                    [
                      {
                        address: deployerAddress,
                        name: 'tokensInfo',
                        params: [deployedTokenAddress[0]],
                      },
                    ]
                );

                let infoFin = info
                let lpAddress = ""
                let currentRouterLogo = ""
                let routerBuyURL = ""
                let price = 0;
                let lpCount = 0;
                let lockerInfo = {id: 0, unlockDate: 0, amount: 0, lockDate: 0, owner: "", index: 0}
                let lockedLiquidity = info.lockedLiquidity;
                if( info.router.length > 0 && info.router != "0x0000000000000000000000000000000000000000" ){

                  let currentRouter = availabelRouters[0];
                  
                  for( let i = 0; i < availabelRouters.length; i+=1){
                    if( availabelRouters[i].address === info.router ){
                      currentRouter = availabelRouters[i];
                    }
                  }
                  currentRouterLogo = currentRouter.logo;
                  routerBuyURL = currentRouter.buyUrl;
                  if( currentRouter.routerType === RouterCategory.EQUAL_VEE3 ){
                    const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
                    try{
                      const [pair] = await multicall(
                        getRouterABI(currentRouter.routerType),
                        [
                          {
                            address: info.router,
                            name: 'pairFor',
                            params: [info.tokenAddress, native, false],
                          },
                        ]
                      );
                      lpAddress = pair[0]
                      console.log(lpAddress)
                      const [reserves] = await multicall(
                        LpPair,
                        [
                          {
                            address: lpAddress,
                            name: 'getReserves',
                            params: [],
                          },
                        ]
                      );
  
                      const [token0] = await multicall(
                        LpPair,
                        [
                          {
                            address: lpAddress,
                            name: 'token0',
                            params: [],
                          },
                        ]
                      );
                      if( token0[0].toLowerCase() === native) {
                        price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                        lpCount = getBalanceInEther(reserves._reserve0)
                      }
                      else {
                        price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                        lpCount = getBalanceInEther(reserves._reserve1)
                      }
                    }
                    catch(err){
                      console.log(err)
                    }
                  } else if( currentRouter.routerType === RouterCategory.V4) {
                    const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
                    try{
                      const [pair] = await multicall(
                        getRouterABI(currentRouter.routerType),
                        [
                          {
                            address: "0x420DD381b31aEf6683db6B902084cB0FFECe40Da", //Aerodrome factory
                            name: 'getPool',
                            params: [info.tokenAddress.toLowerCase(), native, false],
                          },
                        ]
                      );
                      lpAddress = pair[0]
                      console.log(lpAddress)
                      const [reserves] = await multicall(
                        LpPair,
                        [
                          {
                            address: lpAddress,
                            name: 'getReserves',
                            params: [],
                          },
                        ]
                      );
  
                      const [token0] = await multicall(
                        LpPair,
                        [
                          {
                            address: lpAddress,
                            name: 'token0',
                            params: [],
                          },
                        ]
                      );
                      if( token0[0].toLowerCase() === native) {
                        price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                        lpCount = getBalanceInEther(reserves._reserve0)
                      }
                      else {
                        price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                        lpCount = getBalanceInEther(reserves._reserve1)
                      }
                    }
                    catch(err){
                      console.log(err)
                    }
                  }
                  else {
                      try{
                        const [factory] = await multicall(
                          RouterABI,
                          [
                            {
                              address: info.router,
                              name: 'factory',
                              params: [],
                            },
                          ]
                        );
                        
                        const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
                        const [lp] = await multicall(
                          FactoryABI,
                          [
                            {
                              address: factory[0],
                              name: 'getPair',
                              params: [info.tokenAddress, native],
                            },
                          ]
                        );
                        lpAddress = lp[0]

                        const [reserves] = await multicall(
                          LpPair,
                          [
                            {
                              address: lpAddress,
                              name: 'getReserves',
                              params: [],
                            },
                          ]
                        );

                        const [token0] = await multicall(
                          LpPair,
                          [
                            {
                              address: lpAddress,
                              name: 'token0',
                              params: [],
                            },
                          ]
                        );
                        if( token0[0].toLowerCase() === native) {
                          price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                          lpCount = getBalanceInEther(reserves._reserve0)
                        }
                        else {
                          price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                          lpCount = getBalanceInEther(reserves._reserve1)
                        }
                    }
                    catch(err){
                       console.log(err)
                    }
                  }
                }              
                if( info.tokenAddress === '0x954da5AC1ae490b53f0954dd7105f43b8Fa9AaEe')
                {
                  lockerAddress = "";
                  const routerLocal = "0x2F87Bf58D5A9b2eFadE55Cdbd46153a0902be6FA";
                  lockedLiquidity = BigNumber.from(1);
                  const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 

                  let currentRouter = availabelRouters[0];
                  for( let i = 0; i < availabelRouters.length; i+=1){
                    if( availabelRouters[i].address === routerLocal ){
                      currentRouter = availabelRouters[i];
                    }
                  }
                  currentRouterLogo = currentRouter.logo;
                  routerBuyURL = currentRouter.buyUrl;
                  try{
                        lpAddress = "0x202e2F0A3B981f711054c04bcD648394454b0DE5"

                        const [reserves] = await multicall(
                          LpPair,
                          [
                            {
                              address: lpAddress,
                              name: 'getReserves',
                              params: [],
                            },
                          ]
                        );

                        const [token0] = await multicall(
                          LpPair,
                          [
                            {
                              address: lpAddress,
                              name: 'token0',
                              params: [],
                            },
                          ]
                        );
                        if( token0[0].toLowerCase() === native) {
                          price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                          lpCount = getBalanceInEther(reserves._reserve0)
                        }
                        else {
                          price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                          lpCount = getBalanceInEther(reserves._reserve1)
                        }
                    }
                    catch(err){
                       console.log(err)
                    }
                  }
                
                if( info.tokenAddress === '0xEE9fF3EFd508741492B5bd45269aa45a43bE59c8') {
                  const routerLocal = "0x2aa07920E4ecb4ea8C801D9DFEce63875623B285"
                  lockedLiquidity = BigNumber.from(1);
                  let currentRouter = availabelRouters[0];
                  for( let i = 0; i < availabelRouters.length; i+=1){
                    if( availabelRouters[i].address === routerLocal ){
                      currentRouter = availabelRouters[i];
                    }
                  }
                  currentRouterLogo = currentRouter.logo;
                  routerBuyURL = currentRouter.buyUrl;
                  if( currentRouter.routerType === RouterCategory.EQUAL_VEE3 ){
                    const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
                    try{
                      const [pair] = await multicall(
                        getRouterABI(currentRouter.routerType),
                        [
                          {
                            address: routerLocal,
                            name: 'pairFor',
                            params: [info.tokenAddress, native, false],
                          },
                        ]
                      );
                      lpAddress = pair[0]
                      
                      const [reserves] = await multicall(
                        LpPair,
                        [
                          {
                            address: lpAddress,
                            name: 'getReserves',
                            params: [],
                          },
                        ]
                      );
  
                      const [token0] = await multicall(
                        LpPair,
                        [
                          {
                            address: lpAddress,
                            name: 'token0',
                            params: [],
                          },
                        ]
                      );

                      if( token0[0].toLowerCase() === native) {
                        price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                        lpCount = getBalanceInEther(reserves._reserve0)
                      }
                      else {
                        price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                        lpCount = getBalanceInEther(reserves._reserve1)
                      }
                    }
                    catch(err){
                      console.log(err)
                    }
                  }
                  else {
                      try{
                        const [factory] = await multicall(
                          RouterABI,
                          [
                            {
                              address: routerLocal,
                              name: 'factory',
                              params: [],
                            },
                          ]
                        );
                        
                        const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
                        const [lp] = await multicall(
                          FactoryABI,
                          [
                            {
                              address: factory[0],
                              name: 'getPair',
                              params: [info.tokenAddress, native],
                            },
                          ]
                        );
                        lpAddress = lp[0]

                        const [reserves] = await multicall(
                          LpPair,
                          [
                            {
                              address: lpAddress,
                              name: 'getReserves',
                              params: [],
                            },
                          ]
                        );

                        const [token0] = await multicall(
                          LpPair,
                          [
                            {
                              address: lpAddress,
                              name: 'token0',
                              params: [],
                            },
                          ]
                        );
                        if( token0[0].toLowerCase() === native) {
                          price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                          lpCount = getBalanceInEther(reserves._reserve0)
                        }
                        else {
                          price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                          lpCount = getBalanceInEther(reserves._reserve1)
                        }
                    }
                    catch(err){
                       console.log(err)
                    }
                  }
                }

                const lockEth = getBalanceInEther(info.ethAmountLocked)
                const lockLiq = getBalanceInEther(info.lockedLiquidity)
                const initialPrice = info.ethAmountLocked / info.lockedLiquidity
                // console.log(`Initial price ${initialPrice} eth ${lockEth} lock ${lockLiq}`)
                const [symbol] = await multicall(
                    ERC20,
                    [
                      {
                        address: deployedTokenAddress[0],
                        name: 'symbol',
                        params: [],
                      },
                    ]
                );
                let isUserToken = false;
                if( account ){
                    if( info.deployerAddress === account ){
                        isUserToken = true
                    }
                }
                const priceInUSD = price * ftmPrice;
                const mcapInUSD = getBalanceInEther(info.supply) * priceInUSD
                let unlockDate = 0;

                if( lpAddress.length > 0 ){
                  // console.log(`Get locks ${info.deployerAddress} lp: ${lpAddress}`)
                  
                  const [numLocks] = await multicall(
                    Locker,
                    [
                      {
                        address: getLockerAddress(chainId),
                        name: 'getUserNumLocksForToken',
                        params: [info.deployerAddress, lpAddress],
                      },
                    ]
                  );

                  if( numLocks > 0 ) {
                    const [lockInfo] = await multicall(
                      Locker,
                      [
                        {
                          address: getLockerAddress(chainId),
                          name: 'getUserLockForTokenAtIndex',
                          params: [info.deployerAddress, lpAddress, 0],
                        },
                      ]
                    );

                    // getUserLockForTokenAtIndex
                    // console.log(`Num locks = ${numLocks}`)
                    
                    if( lockInfo.length > 4 ){
                      // console.log(`Lock date ${Number(lockInfo[0])} Lock Amount ${getBalanceInEther(lockInfo[1])} Unlock Date ${Number(lockInfo[2])} Owner ${lockInfo[4]}`)
                      unlockDate = Number(lockInfo[2])
                      // console.log(`Current Date: ${new Date().getTime()/ 1000}`)
                      if( new Date().getTime()/ 1000 > unlockDate ){
                        unlockDate = 0;
                      }
                      lockerInfo = {id: lockInfo[3], unlockDate: lockInfo[2], amount: Number(lockInfo[1]), lockDate: lockInfo[0], owner: lockInfo[4], index: 0}
  
                    }
                  }
                }
                const tokenAddInfo =  getTokensAdditioanalInfo(info.tokenAddress.toLowerCase());
                return {index: row, info: {dao: info.dao,
                         deployerAddress: info.deployerAddress,
                         ethAmountLocked: info.ethAmountLocked,
                         lockedLiquidity: lockedLiquidity,
                         mainTokenPool: info.mainTokenPool,
                         name: info.name,
                         router: info.router,
                         supply: info.supply,
                         timeStamp: info.timeStamp,
                         tokenAddress: info.tokenAddress.toLowerCase(),
                         tokenAmountLocked: info.tokenAmountLocked}, symbol: symbol[0], isUserToken, logo: "", tokenPriceFTM: price, lpAddress, lockLps: lockEth * 2, initialPrice, currentRouterLogo,routerBuyURL, priceInUSD, mcapInUSD, lpPrice: lpCount * 2 , unlockDate, lockerInfo, tokenAddInfo, lockerAddress, elockId: 0 };
              }
              catch(err){
                console.log(err)
                return { index: -1 }
              }
            } 
            ) 
        )
        return { tokens: tokensDeployed };
  } catch (error: any) {
     console.log(error)
    return {tokens: []};
  }
};

export const fetchDeployedTokensGlobalInfo = async (chainId: number, account: string, indexes: [number], ftmPrice: number): Promise<any> => {
  const data = await fetchDeployedTokens(chainId, account, indexes, ftmPrice);
  return {
    data: data,
  };
};


export const fetchDeployedTokensSize = async (chainId: number,  deployerAddresses: string[]): Promise<any> => {

  let ABI = []

  if( chainId === 8453 ){
    ABI = getDeployTokenABI(2);
  }
  else 
    ABI = getDeployTokenABI(1);

    try {
      let tokensSize = 0;

      for(let i = 0; i < deployerAddresses.length; i+=1 )
      {
        const deployerAddress = deployerAddresses[i]
        const [deployedCount] = await multicall(
            ABI,
            [
              {
                address: deployerAddress,
                name: 'deployedCount',
                params: [],
              },
            ]
          );
          tokensSize += Number(deployedCount[0])
      }
      return tokensSize;

    } catch (error: any) {
      console.log(error)
    }
  
  //  return 0;
};

export const fetchDeployedTokensSizeInfo = async (chainId: number, deployerAddresses: string[]): Promise<any> => {
  const offchainDataDeployed = await getDeployedTokensInfo({chain: chainId});
  const size = offchainDataDeployed.length;
  console.log(`Fetch size ${size}`)
  return {
    data: size,
  };
};

export const fetchDeployedTokensSizeInfoDB = async (chainId: number): Promise<any> => {
  const offchainDataDeployed = await getDeployedTokensInfo({chain: chainId});
  const size = offchainDataDeployed.length;
  console.log(`Fetch size db ${size}`)
  // TODO Parse data from DB ( blocked or not tokens )
  // 
  return {
    data: size,
  };
};


export const fetchStakerShortInfo = async (account: string, index: number, ftmPrice: number, info: any): Promise<any> => {

  // console.log("Start fetch staker short INFO ", index)
  try {
    let poolCount = 0;
    let poolRewards = 0;
    if ( info.info.mainTokenPool ){
      const address = info.info.mainTokenPool
      let promises = [];

      for( let i = 0; i < 10; i+=1 ) {

        promises.push(new Promise(async (resolve, reject) => {
          try{
            const [poolInfo] = await multicall(
              getMasterChefAbi(),
              [
                {
                  address: address,
                  name: 'poolInfo',
                  params: [i],
                }
              ]
            );
            // console.log(`Pool ${i} ${poolInfo}`)
            poolCount += 1;
            
            const [balance] = await multicall(
              ERC20,
              [
                {
                  address: info.info.tokenAddress,
                  name: 'balanceOf',
                  params: [address],
                },
              ]
            );
            poolRewards = getBalanceInEther(balance[0]);
          }
          catch(err){
            resolve(0)
          }
          resolve(1);
        }))
       }

       await Promise.all(promises).then(function(res) {
        // console.log(res)
      })


      }
      return {index: index, poolCount, poolRewards};
  } catch (error: any) {
    // console.log(error)
    return {index: -1};
  }
};

export const fetchStakerShortInfoData = async (account: string, index: number, ftmPrice: number, info: any): Promise<any> => {
  const data = await fetchStakerShortInfo(account, index, ftmPrice, info);
  return {
    data: data,
  };
};

export const fetchStakerInfo = async (chainId: number, account: string, ftmPrice: number, info: any): Promise<any> => {

  const stakeTokens =  getRouters(chainId);
  try {
    let poolCount = 0;
    const pools: any[] = []

    if ( info.info.mainTokenPool ){
      const address = info.info.mainTokenPool
      let pool = {}
      console.log(`Pool Address ${address}`)
      let promises = [];

      for( let i = 0; i < 20; i+=1 ){
        promises.push(new Promise(async (resolve, reject) => {
          try{
            const [poolInfo, totalAllocPoint,totalDfundStaked, rewardPerSecond, poolStartTime, poolEndTime] = await multicall(
              getMasterChefAbi(),
              [
                {
                  address: address,
                  name: 'poolInfo',
                  params: [i],
                },
                {
                  address: address,
                  name: 'totalAllocPoint',
                },
                {
                  address: address,
                  name: 'totalFundStaked',
                },
                {
                  address: address,
                  name: 'rewardPerSecond',
                },
                {
                  address: address,
                  name: 'poolStartTime',
                },                {
                  address: address,
                  name: 'poolEndTime',
                },
              ]
            );
  
              const [balance] = await multicall(
              ERC20,
              [
                {
                  address: info.info.tokenAddress,
                  name: 'balanceOf',
                  params: [address],
                },
                
              ]
            );
  
            const [balanceStaked] = await multicall(
            ERC20,
              [
                {
                  address: poolInfo.token,
                  name: 'balanceOf',
                  params: [address],
                },
                
              ]
            );
          
          let poolRewards = getBalanceInEther(balance[0]);
          const lockTime =  poolInfo.lockTime;
          const allocPoint = Number(poolInfo.allocPoint);
          const totalAllocPointRaw = Number(totalAllocPoint[0]);
          const poolWeight = allocPoint / totalAllocPointRaw;
          const aprYear = poolRewards * allocPoint / 100 / getBalanceInEther(balanceStaked[0]) * 100;
          const rew = poolRewards/365/ (getBalanceInEther(balanceStaked[0]))
            

          console.log(`Pool ${i} ${poolInfo} totalAlloc  ${totalAllocPoint} r/s ${rewardPerSecond} ${poolStartTime}
             dfundStaked ${totalDfundStaked} balance ${balance} stakedBalance ${balanceStaked} Tvl ${getBalanceInEther(totalDfundStaked[0])} rewardPerSecond ${Number(rew)} Apr year ${aprYear}`)

            pool = {aprYear, poolInfo, lockTime, poolRewards, tvl: getBalanceInEther(totalDfundStaked[0]),
            name: info.info.name, masterChefAddress: info.info?.mainTokenPool, stakingToken: poolInfo.token, rewardPerSecond: Number(rew), poolStartTime, poolId: i, totalAllocPointRaw, allocPoint, poolEndTime}
            poolCount += 1;
            pools.push(pool)
            // console.log(pool)
          }
          catch(err){
            console.log(err)
          }
          resolve(1);
          }))
      }
      // for( let i = 0; i < 100; i+=1 ) {


      //  }
      await Promise.all(promises).then(function(res) {
      })
      }
      
      return {tokenAddress: info.info.tokenAddress, pools};
  } catch (error: any) {
    // console.log(error)
    return {tokenAddress: "", pools: []};
  }
};

export const fetchStakerInfoData = async (chainId: number, account: string, ftmPrice: number, info: any): Promise<any> => {
  const data = await fetchStakerInfo(chainId, account, ftmPrice, info);
  return {
    data: data,
  };
};


export const fetchDeployedTokensByCA = async (chainId: number, account: string, address: string, ftmPrice: number): Promise<any> => {

  const availabelRouters =  getRouters(chainId);
  const tokenDeployerInfo = getTokenDeployerInfo(chainId)

  let ABI = []

  if( chainId === 8453 ){
    
    ABI = getDeployTokenABI(2);
  }
  else 
    ABI = getDeployTokenABI(2);

  let isElockCheck = false
  try {

    try{
      const deployers = getDeployAddresses(chainId)
      let info: any = undefined
      for( let i = 0; i < deployers.length; i+=1 ){
        console.log(`Deployer check ${deployers[i]}`)

        try{
            const [infoL] = await multicall(
              ABI,
              [
                {
                  address: deployers[i],
                  name: 'tokensInfo',
                  params: [address],
                },
              ]
          );
          if( infoL.deployerAddress === "0x0000000000000000000000000000000000000000")
            continue
           if( deployers[i] === "0x415022f5Df6AD4e892fe39235C48276885c22297"){
              isElockCheck = true;
           }

           info = infoL
           break
        }
        catch(err){

        }

      }

      // return
       let lpAddress = ""
       let currentRouterLogo = ""
       let routerBuyURL = ""
       let price = 0;
       let lpCount = 0;
       let elockId = 0;
       console.log(`ELOCK  ${isElockCheck}`)

       if( info.elockid && isElockCheck){
        elockId = Number(info.elockid)

       }
       let lockerInfo = {id: 0, unlockDate: 0, amount: 0, lockDate: 0, owner: "", index: 0}
       let lockedLiquidity = info.lockedLiquidity;

       if( info.router.length > 0 && info.router != "0x0000000000000000000000000000000000000000" ){

         let currentRouter = availabelRouters[0];
         
         for( let i = 0; i < availabelRouters.length; i+=1){
           if( availabelRouters[i].address === info.router ){
             currentRouter = availabelRouters[i];
           }
         }
         currentRouterLogo = currentRouter.logo;
         routerBuyURL = currentRouter.buyUrl;
         if( currentRouter.routerType === RouterCategory.EQUAL_VEE3 ){
          const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
          try{
             const [pair] = await multicall(
               getRouterABI(currentRouter.routerType),
               [
                 {
                   address: info.router,
                   name: 'pairFor',
                   params: [info.tokenAddress, native, false],
                 },
               ]
             );
             lpAddress = pair[0]
             
             const [reserves] = await multicall(
               LpPair,
               [
                 {
                   address: lpAddress,
                   name: 'getReserves',
                   params: [],
                 },
               ]
             );

             const [token0] = await multicall(
               LpPair,
               [
                 {
                   address: lpAddress,
                   name: 'token0',
                   params: [],
                 },
               ]
             );
             if( token0[0].toLowerCase() === native?.toLowerCase()) {
               price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
               lpCount = getBalanceInEther(reserves._reserve0)
             }
             else {
               price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
               lpCount = getBalanceInEther(reserves._reserve1)
             }
           }
           catch(err){
             console.log(err)
           }
         }
         else {
             try{
               const [factory] = await multicall(
                 RouterABI,
                 [
                   {
                     address: info.router,
                     name: 'factory',
                     params: [],
                   },
                 ]
               );
               
               const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
               const [lp] = await multicall(
                 FactoryABI,
                 [
                   {
                     address: factory[0],
                     name: 'getPair',
                     params: [info.tokenAddress, native],
                   },
                 ]
               );
               lpAddress = lp[0]

               const [reserves] = await multicall(
                 LpPair,
                 [
                   {
                     address: lpAddress,
                     name: 'getReserves',
                     params: [],
                   },
                 ]
               );

               const [token0] = await multicall(
                 LpPair,
                 [
                   {
                     address: lpAddress,
                     name: 'token0',
                     params: [],
                   },
                 ]
               );
               if( token0[0].toLowerCase() === native?.toLowerCase()) {
                 price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                 lpCount = getBalanceInEther(reserves._reserve0)
               }
               else {
                 price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                 lpCount = getBalanceInEther(reserves._reserve1)
               }
           }
           catch(err){
              console.log(err)
           }
         }
       }

       if( info.tokenAddress === '0x954da5AC1ae490b53f0954dd7105f43b8Fa9AaEe')
        {
          const routerLocal = "0x2F87Bf58D5A9b2eFadE55Cdbd46153a0902be6FA";
          lockedLiquidity = BigNumber.from(1);
          const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 

          let currentRouter = availabelRouters[0];
          for( let i = 0; i < availabelRouters.length; i+=1){
            if( availabelRouters[i].address === routerLocal ){
              currentRouter = availabelRouters[i];
            }
          }
          currentRouterLogo = currentRouter.logo;
          routerBuyURL = currentRouter.buyUrl;
          try{
                lpAddress = "0x202e2F0A3B981f711054c04bcD648394454b0DE5"

                const [reserves] = await multicall(
                  LpPair,
                  [
                    {
                      address: lpAddress,
                      name: 'getReserves',
                      params: [],
                    },
                  ]
                );

                const [token0] = await multicall(
                  LpPair,
                  [
                    {
                      address: lpAddress,
                      name: 'token0',
                      params: [],
                    },
                  ]
                );
                if( token0[0].toLowerCase() === native) {
                  price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                  lpCount = getBalanceInEther(reserves._reserve0)
                }
                else {
                  price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                  lpCount = getBalanceInEther(reserves._reserve1)
                }
            }
            catch(err){
               console.log(err)
            }
          }

       if( info.tokenAddress === '0xEE9fF3EFd508741492B5bd45269aa45a43bE59c8') {
        const routerLocal = "0x2aa07920E4ecb4ea8C801D9DFEce63875623B285"
        lockedLiquidity = BigNumber.from(1);
        let currentRouter = availabelRouters[0];
        for( let i = 0; i < availabelRouters.length; i+=1){
          if( availabelRouters[i].address === routerLocal ){
            currentRouter = availabelRouters[i];
          }
        }
        currentRouterLogo = currentRouter.logo;
        routerBuyURL = currentRouter.buyUrl;
        const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 

        if( currentRouter.routerType === RouterCategory.EQUAL_VEE3 ){
          const wftm = ("0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83").toLowerCase()
          try{
            const [pair] = await multicall(
              getRouterABI(currentRouter.routerType),
              [
                {
                  address: routerLocal,
                  name: 'pairFor',
                  params: [info.tokenAddress, wftm, false],
                },
              ]
            );
            lpAddress = pair[0]
            
            const [reserves] = await multicall(
              LpPair,
              [
                {
                  address: lpAddress,
                  name: 'getReserves',
                  params: [],
                },
              ]
            );

            const [token0] = await multicall(
              LpPair,
              [
                {
                  address: lpAddress,
                  name: 'token0',
                  params: [],
                },
              ]
            );
            if( token0[0].toLowerCase() === native?.toLowerCase()) {
              price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
              lpCount = getBalanceInEther(reserves._reserve0)
            }
            else {
              price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
              lpCount = getBalanceInEther(reserves._reserve1)
            }
          }
          catch(err){
            console.log(err)
          }
        }
        else {
            try{
              const [factory] = await multicall(
                RouterABI,
                [
                  {
                    address: routerLocal,
                    name: 'factory',
                    params: [],
                  },
                ]
              );
              
              const native = tokenDeployerInfo?.nativeToken.toLowerCase(); 
              const [lp] = await multicall(
                FactoryABI,
                [
                  {
                    address: factory[0],
                    name: 'getPair',
                    params: [info.tokenAddress, native],
                  },
                ]
              );
              lpAddress = lp[0]

              const [reserves] = await multicall(
                LpPair,
                [
                  {
                    address: lpAddress,
                    name: 'getReserves',
                    params: [],
                  },
                ]
              );

              const [token0] = await multicall(
                LpPair,
                [
                  {
                    address: lpAddress,
                    name: 'token0',
                    params: [],
                  },
                ]
              );
              if( token0[0].toLowerCase() === native?.toLowerCase()) {
                price = getBalanceInEther(reserves._reserve0) / getBalanceInEther(reserves._reserve1)
                lpCount = getBalanceInEther(reserves._reserve0)
              }
              else {
                price = getBalanceInEther(reserves._reserve1) / getBalanceInEther(reserves._reserve0)
                lpCount = getBalanceInEther(reserves._reserve1)
              }
          }
          catch(err){
             console.log(err)
          }
        }
      }


       const lockEth = getBalanceInEther(info.ethAmountLocked)
       const lockLiq = getBalanceInEther(info.lockedLiquidity)
       const initialPrice = info.ethAmountLocked / info.lockedLiquidity
       // console.log(`Initial price ${initialPrice} eth ${lockEth} lock ${lockLiq}`)
       const [symbol] = await multicall(
           ERC20,
           [
             {
               address: address,
               name: 'symbol',
               params: [],
             },
           ]
       );
       let isUserToken = false;
       if( account ){
           if( info.deployerAddress === account ){
               isUserToken = true
           }
       }
       const priceInUSD = price * ftmPrice;
       const mcapInUSD = getBalanceInEther(info.supply) * priceInUSD
       let unlockDate = 0;

       if( lpAddress.length > 0 ){
         // console.log(`Get locks ${info.deployerAddress} lp: ${lpAddress}`)
         
         const [numLocks] = await multicall(
           Locker,
           [
             {
               address: getLockerAddress(chainId),
               name: 'getUserNumLocksForToken',
               params: [info.deployerAddress, lpAddress],
             },
           ]
         );

         if( numLocks > 0 ) {
           const [lockInfo] = await multicall(
             Locker,
             [
               {
                 address: getLockerAddress(chainId),
                 name: 'getUserLockForTokenAtIndex',
                 params: [info.deployerAddress, lpAddress, 0],
               },
             ]
           );

           // getUserLockForTokenAtIndex
           // console.log(`Num locks = ${numLocks}`)
           
           if( lockInfo.length > 4 ){
             // console.log(`Lock date ${Number(lockInfo[0])} Lock Amount ${getBalanceInEther(lockInfo[1])} Unlock Date ${Number(lockInfo[2])} Owner ${lockInfo[4]}`)
             unlockDate = Number(lockInfo[2])
             // console.log(`Current Date: ${new Date().getTime()/ 1000}`)
             if( new Date().getTime()/ 1000 > unlockDate ){
               unlockDate = 0;
             }
             lockerInfo = {id: lockInfo[3], unlockDate: lockInfo[2], amount: Number(lockInfo[1]), lockDate: lockInfo[0], owner: lockInfo[4], index: 0}

           }
         }
       }
       const tokenAddInfo =  getTokensAdditioanalInfo(info.tokenAddress);

       return {index: 1, info: {dao: info.dao,
        deployerAddress: info.deployerAddress,
        ethAmountLocked: info.ethAmountLocked,
        lockedLiquidity: lockedLiquidity,
        mainTokenPool: info.mainTokenPool,
        name: info.name,
        router: info.router,
        supply: info.supply,
        timeStamp: info.timeStamp,
        tokenAddress: info.tokenAddress,
        tokenAmountLocked: info.tokenAmountLocked}, symbol: symbol[0], isUserToken, logo: "", tokenPriceFTM: price, lpAddress, lockLps: lockEth * 2, initialPrice, currentRouterLogo,routerBuyURL, priceInUSD, mcapInUSD, lpPrice: lpCount * 2 , unlockDate, lockerInfo, tokenAddInfo, elockId: elockId };
     }
     catch(err){
       return { index: -1 }
     }
  } catch (error: any) {
     console.log(error)
    return {tokens: { index: -1}};
  }
};

export const fetchDeployedTokensGlobalInfoByCA = async (chainId: number, account: string, address: string, ftmPrice: number): Promise<any> => {
  const data = await fetchDeployedTokensByCA(chainId, account, address, ftmPrice);
  return {
    data: data,
  };
};