import { initializeConnector, Web3ReactHooks } from '@web3-react/core';
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2';
import { MetaMask } from '@web3-react/metamask';
import { CoinbaseWallet } from '@web3-react/coinbase-wallet';
import { Network } from '@web3-react/network';

import getNodeUrl from 'utils/getRpcUrl';
import { getItem } from 'utils/localstorage';
import { SUPPORTED_CHAINS } from 'config/constants/chains';
import { NETWORK_RPC_URLS } from 'config/constants/rpc';

const supportedChainIds = SUPPORTED_CHAINS.map((row: any) => row.chainId);
const selectedChainId = getItem('selectedChainId');
const chainId = parseInt(selectedChainId || '250', 10);

type WalletConnector = MetaMask | WalletConnectV2 | CoinbaseWallet | Network;

// metamask
const [metaMask, metaMaskHooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }));

// wallet connect v2
const [walletConnectV2, walletConnectV2Hooks] = initializeConnector<WalletConnectV2>(
  (actions: any) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!,
        chains: [250, 8453],
        optionalChains: [250, 8453],
        showQrModal: true,
        rpcMap: { ...NETWORK_RPC_URLS },
      },
    })
);

// coinbase
const [coinbaseWallet, coinbaseWalletHooks] = initializeConnector<CoinbaseWallet>(
  (actions) =>
    new CoinbaseWallet({
      actions,
      options: {
        url: getNodeUrl(chainId),
        appName: 'web3-react',
      },
    })
);

const connectors: [WalletConnector, Web3ReactHooks][] = [
  [metaMask, metaMaskHooks],
  [walletConnectV2, walletConnectV2Hooks],
  [coinbaseWallet, coinbaseWalletHooks],
];

export { type WalletConnector, connectors };
