import {FC} from 'react';

import {
    Box,
    Button,
    Dialog,
    Grid,
    styled,
    Typography,
    DialogActions,
    Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ImageType } from 'react-images-uploading';
import TokenomicsDiagramm from "./Components/TokenomicsDiagramm";
import MiddleSection from "./Components/MiddleSection";
import {presaleInfos} from "../../../config/constants/presale";


const ModalDialog = styled(Dialog)(({theme}) => ({
    '.MuiDialog-container > .MuiPaper-root': {
        borderRadius: '10px',

        width: '85%',
        background: "rgba(34,41,57, 1)",
        padding: '15px 25px 15px',
        border: '1px solid #5E6980',
        boxShadow:
            '0px 2.1574831008911133px 29.22142219543457px 0px rgba(0, 0, 0, 0.05), 0px 3.806384325027466px 39.8596076965332px 0px rgba(0, 0, 0, 0.07), 0px 5.703558921813965px 44.46048355102539px 0px rgba(0, 0, 0, 0.08), 0px 10.160801887512207px 47.2956428527832px 0px rgba(0, 0, 0, 0.09), 0px 25.993473052978516px 52.371063232421875px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

        [theme.breakpoints.up('xs')]: {
            padding: '10px 15px 10px',
            margin: '24px',
            maxWidth: '320px'
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '800px'
        },
    },
}));



const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    // background: '#2A3139',
    // boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
}));

const InfoBox = styled(Box)(({ theme }) => ({
    background: "rgba(6, 1, 1, 0.3)",
    minHeight: '34px',
    borderRadius: '10px',
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),

}));


const PoolInfoLabel = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'left',
        whiteSpace: 'break-spaces',
    },
}));

const PoolInfoLabelEnd = styled(Typography)(({theme}) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '165px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        textAlign: 'right',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        textAlign: 'right',
        whiteSpace: 'break-spaces',
    },
}));


interface PresaleUIInfo {
    tokenName: string;
    tokenSymbol: string;
    totalSupply: string;
    tokensPresale: string;
    presaleTokenPrice: string;
    softCap: string;
    minBuy: string;
    presaleStartsIn: string;
    hardCap: string;
    maxBuy: string;
    presaleLen: string;
    amm: { address?: string; name?: string } | undefined;
    lockDuration: string;
    isBurnChecked: boolean;
    isLiquidityChecked: boolean;
    isLiquidityCreateChecked: boolean;
    launchPrice: string;
    isElock: boolean;
}

const getLockDuration = (info: PresaleUIInfo): string => {
    if (info.isElock) {
        return "ELock " + info.lockDuration + " days";
    }
    else if (info.isBurnChecked) {
        return "SCC";
    } else if (info.isLiquidityChecked) {
        return info.lockDuration + " days";
    } else {
        return "No Lock";
    }
};



const calculateTotalSupply = (totalSupply:any): string => {

    return totalSupply.toString();
}

const calculateHardCap = (data: PresaleUIInfo): string => {
    const presaleTokenCount = parseFloat(data.tokensPresale);
    const presalePrice = parseFloat(data.presaleTokenPrice)

    return (presaleTokenCount * presalePrice).toString();
}

const calculateLiquidityAtLaunch = (data: PresaleUIInfo): string => {
        return  (parseFloat(calculateHardCap(data)) * 2).toString();
}

const calculateTokensUsedForLiquidityIfHardCap = (data: PresaleUIInfo): string => {

    const hardCap = parseFloat(calculateHardCap(data))
    const presaleLaunchPrice = parseFloat(data.launchPrice)

    return (hardCap / presaleLaunchPrice).toString();
}

const calculateTokensSentToCreator = (data: PresaleUIInfo): string => {
    const totalSupply = parseFloat(data.totalSupply);
    const presaleTokenCount = parseFloat(data.tokensPresale);
    const presaleLaunchPrice = parseFloat(data.launchPrice);
    const hardCap = parseFloat(calculateHardCap(data))

    const tokensSentToCreator = totalSupply - (totalSupply/100)- presaleTokenCount - (hardCap/presaleLaunchPrice);
    return tokensSentToCreator.toString();
}

const calculateTokensSentToDrop = (data: PresaleUIInfo): string => {
    const totalSupply = parseFloat(data.totalSupply);


    const tokensSentToDrop = (totalSupply/100) * 0.5;
    return tokensSentToDrop.toString();
}

const calculateTokensSentToBasedLabs= (data: PresaleUIInfo): string => {
    const totalSupply = parseFloat(data.totalSupply);

    const tokensSentToBasedLabs = (totalSupply/100) * 0.5;
    return tokensSentToBasedLabs.toString();
}

const calculateMCapAtLaunch = (data: PresaleUIInfo): string => {

    const mcap = Number(data.totalSupply) * Number(data.launchPrice);
    return mcap.toString();
}


function formatValue(value: string): string {
    const numberValue = parseFloat(value);

    if (isNaN(numberValue) || !isFinite(numberValue)) {
        return '--.--';
    }

    const formattedValue = numberValue === Math.floor(numberValue)
        ? numberValue.toFixed(0)
        : numberValue.toFixed(4);

    return numberValue >= 1000
        ? parseFloat(formattedValue).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 4 })
        : formattedValue;
}


interface DeployPresaleModalProps {
    isDeployed: boolean;
    open: boolean;
    handleClose: () => void;
    presaleInfo: PresaleUIInfo;
    images: ImageType[];
    handleDeploy: () => void;
}


const DeployPresaleModal: FC<DeployPresaleModalProps> = ({isDeployed, open,
                                                             handleClose,
                                                             presaleInfo,
                                                             images, handleDeploy}) =>
{
    const {
        tokenName, tokenSymbol, totalSupply,
        tokensPresale, presaleTokenPrice, softCap,
        minBuy, presaleStartsIn, hardCap,
        maxBuy, presaleLen, amm, lockDuration,
        isBurnChecked, isLiquidityChecked, isLiquidityCreateChecked,
        launchPrice, isElock
    } = presaleInfo;

    const liquidityAtLaunch = calculateLiquidityAtLaunch(presaleInfo);
    const tokensUsedForLiquidityIfHardCap = calculateTokensUsedForLiquidityIfHardCap(presaleInfo);
    const tokensSentToCreator = calculateTokensSentToCreator(presaleInfo);
    const newlockDuration = getLockDuration(presaleInfo);
    const newTotalSupply = calculateTotalSupply(presaleInfo.totalSupply);

    const mCapAtLaunch = calculateMCapAtLaunch(presaleInfo);
    const tokensSentToBasedLabs = calculateTokensSentToBasedLabs(presaleInfo);
    const tokensSentToDrop= calculateTokensSentToDrop(presaleInfo);
    const hardcap = calculateHardCap(presaleInfo);

    const tokenDiagrammInfo = [
        { label: 'Tokens for Presale', value: presaleInfo.tokensPresale, color: 'green' },
        { label: 'Tokens for LP (if hardcap is reached)', value: tokensUsedForLiquidityIfHardCap, color: 'olive' },
        { label: 'Tokens held by Creator', value: tokensSentToCreator, color: 'red' },
        { label: 'Tokens sent to Airdrop Farming Pools', value: tokensSentToDrop, color: 'orange' },
        { label: 'Tokens sent to Based Labs Treasury', value: tokensSentToBasedLabs, color: 'blue' },
    ];

    const ammName = isElock
        ? 'EqualizerDex (V2)'
        : (amm?.address ? amm?.name : '--.--');



    return (
        <ModalDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <ToggleSection onClick={handleClose}>
                <CloseIcon sx={{width: '20px', height: '20px'}}/>
            </ToggleSection>

            <Box
                mb={2}
                mt={4}
                justifyContent="center"
                sx={{
                    border: '0px solid green',
                }}
            >
                <Grid
                    container
                    gap={2}
                    justifyContent="center"
                    sx={{
                        border: '0px solid red',
                    }}
                >

                    <Grid item xs={12} md={4}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                height: '100%',
                                maxWidth: '100%',
                                minWidth: '200px',
                                borderRadius: '10px',
                                background: "rgba(6,1,1,0.3)",
                                overflow: 'hidden',
                                p: 2
                            }}
                        >
                            {images.map((image, index) => (
                                <div key={index} className="image-item">
                                    <img
                                        src={image['data_url']}
                                        alt="Logo"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            borderRadius: '10px',
                                            objectFit: 'contain'
                                        }}
                                        />
                                </div>
                            ))}
                        </Box>
                    </Grid>


                    <Grid item xs={12} md={6}>

                        <Box gap={2}
                             display="flex"
                             flexDirection="column"
                             justifyContent='space-between'
                             alignItems='space-between'
                             sx={{border: '0px solid red', height: '100%'}}>


                            <Box
                                flex={1}
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                                sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '10px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px',
                                    maxWidth: '100%',
                                    wordWrap: 'break-all',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                <Typography variant="h1"
                                            sx={{width: '100%', wordBreak: 'break-word'}}
                                >{tokenName}</Typography>
                            </Box>



                            <Box
                                flex={1}
                                textAlign="center"
                                alignItems="center"
                                justifyContent="center"
                                display="flex"
                                sx={{
                                    background: "rgba(6,1,1,0.3)",
                                    padding: '10px',
                                    minHeight: '34px',
                                    borderRadius: '10px',
                                    minWidth: '200px',
                                    maxWidth: '100%',
                                    wordWrap: 'break-all',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>
                                <Typography variant="h1"
                                            sx={{width: '100%', wordBreak: 'break-word'}}
                                >{tokenSymbol}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Box mb={2}>
                <Divider sx={{borderColor: 'white', my: 2}}/>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{fontSize: '2rem', fontWeight: 'bold'}}
                >
                    Tokenomics Overview
                </Typography>

                {/*<MiddleSection presaleInfo={presaleInfo}></MiddleSection>*/}

                <TokenomicsDiagramm tokenDiagrammInfo={tokenDiagrammInfo} totalSupply={newTotalSupply}/>
            </Box>



            <Box gap={2}>
                <Divider sx={{borderColor: 'white', my: 2}}/>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <InfoBox>
                            <PoolInfoLabel> Presale Price: </PoolInfoLabel>
                            <PoolInfoLabelEnd>{formatValue(presaleTokenPrice)}</PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> Soft Cap (ftm): </PoolInfoLabel>
                            <PoolInfoLabelEnd>{formatValue(softCap)}</PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> HardCap (ftm) </PoolInfoLabel>
                            <PoolInfoLabelEnd> {formatValue(hardcap)}</PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> Presale Starts in </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                                {formatValue(presaleStartsIn) != '--.--' ? presaleStartsIn + " days":'--.--' }
                            </PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> Presale Length </PoolInfoLabel>
                            <PoolInfoLabelEnd>
                                {formatValue(presaleLen) != '--.--' ? presaleLen + " days":'--.--' }
                            </PoolInfoLabelEnd>
                        </InfoBox>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <InfoBox>
                            <PoolInfoLabel> Price at Launch(ftm) </PoolInfoLabel>
                            <PoolInfoLabelEnd> {formatValue(launchPrice)} </PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> MCap at launch (ftm): </PoolInfoLabel>
                            <PoolInfoLabelEnd> {totalSupply ? formatValue((Number(totalSupply) * Number(launchPrice)).toFixed(4)) : 0} </PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> Liquidity at launch (ftm):</PoolInfoLabel>
                            <PoolInfoLabelEnd> {formatValue(liquidityAtLaunch)} </PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> Liquidity Lock </PoolInfoLabel>
                            <PoolInfoLabelEnd> {getLockDuration(presaleInfo)} </PoolInfoLabelEnd>
                        </InfoBox>
                        <InfoBox>
                            <PoolInfoLabel> Chosen AMM </PoolInfoLabel>
                            <PoolInfoLabelEnd> {ammName} </PoolInfoLabelEnd>
                        </InfoBox>
                    </Grid>
                </Grid>
            </Box>

            <Box>






            </Box>

            <DialogActions>
                <Grid container justifyContent="center">
                    <Button
                        disabled={isDeployed}
                        onClick={handleDeploy}
                        variant="contained"
                        sx={{
                            backgroundColor: "rgb(25,28,37)",
                            fontSize: '1.6rem',
                            padding: '15px 30px',
                            borderRadius: '10px',
                            '&:hover': {
                                backgroundColor: '#333333',
                            },
                        }}
                    >
                        CREATE PRESALE
                    </Button>
                </Grid>
            </DialogActions>
        </ModalDialog>
    );
};

export default DeployPresaleModal;