import { tokenInfos } from 'config/constants/tokens';

export const getTokenInfo = (tokenAddr: string) => {
  const tokenInfo = tokenInfos.find((row: any) => tokenAddr.toLowerCase() === row.address.toLowerCase());
  if (tokenInfo)
    return {
      address: tokenInfo.address,
      // symbol: tokenInfo.symbol,
      // decimals: tokenInfo.decimals,
      tokenImage: tokenInfo.logo,
      tokenName: tokenInfo.name,
      isRounded: tokenInfo.isRounded,
      lp: tokenInfo.tokenLp,
      decimals: tokenInfo.decimals,
      cgc: tokenInfo.cgc,
      chain: tokenInfo.chain,
      buyUri: tokenInfo.buyUri
    };
  return null;
};

export const getTokens = (chainId: number) => {
  const tokens = tokenInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
  return tokens;
};
