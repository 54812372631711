import { useEffect, useState } from "react";
import { getGoogleImages } from "utils/googleDownload";

export const useGetTokensLogo = (deployedTokens: any) => {
    const [stat, setStat] = useState<any>();

    useEffect(() => {
        async function getStakeInfo() {
          try {
            if(!deployedTokens || deployedTokens.length === 0 )
                return
            const images = await getGoogleImages();
            const depl = []

            if( images ){
                for( let i = 0; i < deployedTokens.length; i++ ){
                    let isLogo = false
                    for( let k = 0; k < images.length; k++ ) {
                        if( deployedTokens[i].info.tokenAddress === Object.values(images[k])[1] )
                        {
                            // deployedTokens[i].logo = images[k].ImageUrl
                            let url: string = Object.values<string>(images[k])[2];
                            url = url.replace("open", 'thumbnail')
                            depl.push({info: deployedTokens[i].info, symbol: deployedTokens[i].symbol, logo: url })
                            isLogo = true
                            break;
                        }
                    }
                    if( !isLogo )
                        depl.push({info: deployedTokens[i].info, symbol: deployedTokens[i].symbol, logo: "" })
                }
            }

            setStat(depl)

            // setStat({value: res});
          } catch (err) {
            console.log(err);
          }
        }
     
        getStakeInfo().then();
      }, [ deployedTokens]);
      return stat;
}

const pinataConfig = {
  root: 'https://api.pinata.cloud',
  headers: {
    'pinata_api_key': process.env.REACT_APP_PINATA_API_API,
    'pinata_secret_api_key': process.env.REACT_APP_PINATA_API_KEY
  }
}; 

export const useGetTokensLogoPiniata = (deployedTokens: any, reloadCounter: number) => {
  const [stat, setStat] = useState<any>();

  useEffect(() => {
      async function getStakeInfo() {
        try {
          if(!deployedTokens || deployedTokens.length === 0 )
              return

          const depl = []
          let response2: any
          const jwt = process.env.REACT_APP_PINATA_API_JWT
          try {
            const options = {method: 'GET', headers: {Authorization: `Bearer ${jwt}`}};
            // https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/bafybeifl4bwgrvtolsbcdhuni5zxlc4tssuentmznauzhbcv4u4mkc7jba
            const res = await fetch('https://api.pinata.cloud/data/pinList?status=pinned', options)
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  response2 = data
                })
                .catch(err => {
                  console.error(err)
                 return
                });
            } catch (error) {
              console.log(error)
              return
            }
          if( response2 ){
              for( let i = 0; i < deployedTokens.length; i++ ){
                  let isLogo = false
                  for( let k = 0; k < response2.rows.length; k++ ) {
                      if( deployedTokens[i].info.tokenAddress === response2.rows[k].metadata.name )
                      {
                          depl.push({info: deployedTokens[i].info, symbol: deployedTokens[i].symbol, logo: `https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/${response2.rows[k].ipfs_pin_hash}`, tokenPriceFTM: deployedTokens[i].tokenPriceFTM, lpAddress: deployedTokens[i].lpAddress  })
                          isLogo = true
                          break;
                      }
                  }
                  if( !isLogo )
                      depl.push({info: deployedTokens[i].info, symbol: deployedTokens[i].symbol, logo: "", tokenPriceFTM: deployedTokens[i].tokenPriceFTM, lpAddress: deployedTokens[i].lpAddress })
              }
              setStat(depl)
          }
          else {
            setStat(deployedTokens)
          }


          // setStat({value: res});
        } catch (err) {
          console.log(err);
        }
      }
   
      getStakeInfo().then();
    }, [ deployedTokens, reloadCounter]);
    return stat;
}

export const useGetTokensLogoPiniata2 = (reloadCounter: number) => {
  const [stat, setStat] = useState<any>();

  useEffect(() => {
      async function getStakeInfo() {
        try {
            
          const depl = []
          let response2: any
          const jwt = process.env.REACT_APP_PINATA_API_JWT
          try {
            const options = {method: 'GET', headers: {Authorization: `Bearer ${jwt}`}};
            // https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/bafybeifl4bwgrvtolsbcdhuni5zxlc4tssuentmznauzhbcv4u4mkc7jba
            const res = await fetch('https://api.pinata.cloud/data/pinList?status=pinned&pageLimit=100', options)
                .then((response) => {
                  return response.json();
                })
                .then((data) => {
                  response2 = data
                })
                .catch(err => {
                  console.error(err)
                 return
                });
            } catch (error) {
              console.log(error)
              return
            }

          if( response2 ){
              for( let k = 0; k < response2.rows.length; k++ ) {
                if( response2.rows[k].metadata.keyvalues ){
                  depl[response2.rows[k].metadata.name.toLowerCase()] = {img: `https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/${response2.rows[k].ipfs_pin_hash}`,
                   desc: response2.rows[k].metadata.keyvalues['desc'], tg: response2.rows[k].metadata.keyvalues['tg'],
                   twitter: response2.rows[k].metadata.keyvalues['twitter'], web: response2.rows[k].metadata.keyvalues['web']}
                } else {
                  depl[response2.rows[k].metadata.name.toLowerCase()] = {img: `https://aquamarine-deliberate-harrier-731.mypinata.cloud/ipfs/${response2.rows[k].ipfs_pin_hash}`, desc: '', tg: '', twitter: '', web: ''}

                }
              }

              setStat(depl)
          }

          // setStat({value: res});
        } catch (err) {
          console.log(err);
        }
      }
   
      getStakeInfo().then();
    }, [reloadCounter]);
    return stat;
}
