export enum StakeCategory {
    ACTIVE,
    RETIRED,
    ACTIVE_RETIRED,
    TEAM,
    TEST,
    INVISIBLE,
    HIDDEN
  }
  export interface FarmInfo {
    name: string;
    poolId: number;
    stakingToken: string;
    rewardToken: string;
    receiptToken: string;
    rewardTokenName: string;
    rewardTokenDecimal: number;
    tokenImage: string;
    rewardTokenImage: string;
    masterChefAddress: string;
    farmType: string;
    chainId: number;
    apr?: number;
    dailyApr?: number;
    tvlInUsd?: number;
    userInfo: any;
    category: StakeCategory;
    location?: string;
    isTest?: boolean;
    autocompounder: string;
    place: StakeCategory;
    isFake?: boolean;
    isAprZero?: boolean;
    isLp: boolean;
    stakingToken0: string;
  }