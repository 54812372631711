import { useWeb3React } from "@web3-react/core";
import useContract from "./useContract";
import { getLockerAddress } from "utils/deployTokens";
import { AbiItem } from 'web3-utils';
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { ethers } from "ethers";
import LockerABI from 'config/abi/LockerABI.json';
import { useAppSelector } from "state/hooks";

export const useLocker = ( info: any ) => {
    const { account } = useWeb3React();
    const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

    const contract = useContract(LockerABI as unknown as AbiItem, getLockerAddress(Number(selectedChainId)));
    const dispatch = useDispatch();

    const handleWithdraw= useCallback(
        async (): Promise<string | undefined> => {
    
          if (!account || !contract || !info ) return '';

          console.log(info)
          const tx = await contract.withdraw(info.lpAddress, Number(info.lockerInfo.index), Number(info.lockerInfo.id)); 

          const receipt = await tx.wait();
    
          if (receipt.status !== 1) {
            throw new Error();
          }
          return tx.txHash;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [account, contract]
    );

    return {
        onWithdraw: handleWithdraw,
    }
}