import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, Typography, styled } from '@mui/material';

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    // overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'center',
    },
  }));  
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));
interface PoolCardProps {
}

const InfoSection: FC<PoolCardProps> = ({  }) => {
    const [name, setName] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [isCheckErrors, setCheckErrors] = useState(false);

    const onChangeName = (e: any) => {
        const newAmount = e.target.value;
        setName(newAmount);
    };
      const onChangeSymbol = (e: any) => {
        const newAmount = e.target.value;
        setSymbol(newAmount);
    };

  return (
    <>
        <Box display='flex' flexDirection='column' justifyContent='center' sx={{border: '0px solid blue'}}>
            <Box mt={1} display='flex' flexDirection='column' sx={{
                    border: '1px solid #1B1E29',
                    background: '#1B1E29',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '10px',
                    height: '100%'
                    }}>
                  <ValueTitle>
                  IMPORTANT INFO FOR PRESALE CREATORS
Any investor can call Launch Token if presale conditions are met ( soft cap reached and presale ended or hard cap reached).Launch Token will create your token ,all funds raised  will be used to create Liquidity and sent to SCC, Presale Creator wallet , or Locked depending on settings set by Presale Creator. 
                 </ValueTitle>
                 <ValueTitle mt={1}>
                 0.5 % from Token supply will be sent to Based Labs Treasury.
                 </ValueTitle>
                 <ValueTitle>
                 0.5 % from Token supply will be sent to staking pool for Based 
                 and Fantom community stakers (airdrop farming for 90 days).  
                 </ValueTitle>
            </Box>
        </Box>
    </>
  );
};

export default InfoSection;
