import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import ImageUploading, { ImageType } from 'react-images-uploading';
import axios from 'axios';

interface Props {
    imageUpload: (images: ImageType[]) => void
    hide: boolean,
}

const ImageUploadBox: FC<Props> = ({ imageUpload, hide }) => {
  const [fileImg, setFileImg] = useState(null);
  const [address, setAddress] = useState("");

  const [images, setImages] = useState<ImageType[]>([]);
  const maxNumber = 1;

  const onChange = (imageList: any, addUpdateIndex: any) => {
    // data for submit
    setImages(imageList);
    imageUpload(imageList);
  };
  const onChangeAddress = (e: any) => {
    console.log(e.target.value)
    setAddress(e.target.value)
  };

  const key = process.env.REACT_APP_PINATA_API_KEY
  const jwt = process.env.REACT_APP_PINATA_API_JWT
  const apiKey = process.env.REACT_APP_PINATA_API_API

  const sendJSONtoIPFS = async (ImgHash: any) => {

      try {

          const resJSON = await axios({
              method: "post",
              url: "https://api.pinata.cloud/pinning/pinJsonToIPFS",
              data: {
                  "name": address,
                  "description": "",
                  "image": ImgHash
              },
              headers: {
                  'pinata_api_key': `${apiKey}`,
                  'pinata_secret_api_key': `${key}`,
              },
          });

          console.log("final ", `ipfs://${resJSON.data.IpfsHash}`)
          const tokenURI = `ipfs://${resJSON.data.IpfsHash}`;
          console.log("Token URI", tokenURI);
        //   mintNFT(tokenURI, currentAccount)   // pass the winner

      } catch (error) {
          console.log("JSON to IPFS: ")
          console.log(error);
      }


  }
  const pinataConfig = {
    root: 'https://api.pinata.cloud',
    headers: {
      'pinata_api_key': apiKey,
      'pinata_secret_api_key': key
    }
  }; 

const sendFileToIPFS = async (e: any) => {

    try {
        if (images.length > 0 && images[0] ) {
          const formData = new FormData();
          formData.append('file', images[0].file ? images[0].file : "");
          const pinataBody = {
            options: {
              cidVersion: 1,
            },
            metadata: {
              name: `${address}`,
            }
          }
          formData.append('pinataOptions', JSON.stringify(pinataBody.options));
          formData.append('pinataMetadata', JSON.stringify(pinataBody.metadata));
          const url = `${pinataConfig.root}/pinning/pinFileToIPFS`;
          const response = await axios({
            method: 'post',
            url: url,
            data: formData,
            headers: pinataConfig.headers
          })
          console.log(response.data)
        //   queryPinataFiles();
        } else {
          alert('select file first')
        }
      } catch (error) {
        console.log(error)
      }
  }

  return (
    <>
    {/* <input placeholder='Test Upload Collection Address' onChange={onChangeAddress} style={{marginBottom: '10px',}} >
    </input> */}
    {/* <button style={{marginBottom: '10px'}}
        onClick={sendFileToIPFS}
    >
        Test Upload
    </button> */}
   <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div style={{marginTop: "10px", marginBottom: '10px', display: hide ? 'none' : ''}} className="upload__image-wrapper">
            <Button
            color="primary" 
            variant="outlined"             
              style={isDragging ? { color: 'red'} : {border: images.length <= 0 ? '1px solid red' : '0px solid red'}}
              onClick={onImageUpload}
              {...dragProps}
            >
              <Typography style={{fontSize: '13px', color: "lightgray"}} color='primary' variant={'h5'} >{'Click or Drop here Your Logo'}
              </Typography>
            </Button>
            &nbsp;
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" style={{marginTop: '10px'}} />
                <div className="image-item__btn-wrapper" style={{justifyContent: 'center'}}>
                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                  <Button sx={{marginTop: '10px'}}
                    color="primary" 
                    variant="outlined"   
                    onClick={() => onImageRemove(index)}>
                        <Typography style={{fontSize: '13px', color: "lightgray"}} color='primary' variant={'h5'} >{'Remove'}
                        </Typography>
                    </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
    </>
  );
};

export default ImageUploadBox;
