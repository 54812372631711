import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CircularProgress, styled, useMediaQuery, useTheme } from '@mui/material';

import Header from 'components/Header';
import Footer from 'components/Footer';
import { ConnectModal } from 'components/Modal';
import { useAppSelector } from 'state/hooks';
import useAuth from 'hooks/useAuth';

const MainLayoutBox = styled(Box)(() => ({
  minWidth: '320px',
  // minHeight: '100vh',
  background: 'linear-gradient(180deg, #1B2F60 0%, #16191E 71.35%)',
}));

const OutLetContainer = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey.A100,
}));

const layoutBgColors: any = {
  '250': 'linear-gradient(180deg, #0221B5 0%, #0221B5 71.35%)',
  '137': 'linear-gradient(180deg, #5000A0 0%, #16191E 71.35%)',
  '43114': 'linear-gradient(180deg, #D12C48 0%, #16191E 71.35%)',
};

const MainLayout: FC = () => {
  const { isWalletConnectModalOpen, isAccountModalOpen } = useAppSelector((state) => state.modal);
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const { login } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  return (
    <MainLayoutBox sx={{ marginLeft: isMobile ? "0px" : '0px'  }}>
      {isWalletConnectModalOpen && <ConnectModal login={login} />}
      <Header />
      <Box sx={{minHeight: 'calc(100vh - 175px)'}}>
        <Suspense
          fallback={
            <Box
              sx={{
                minHeight: 'calc(100vh - 100px)',
                fontSize: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress size={40} sx={{ color: 'white' }} />
            </Box>
          }
        >
          <OutLetContainer>
            <Box justifyContent='center' sx={{ display: 'flex' }}>
              <Outlet />
            </Box>
          </OutLetContainer>
        </Suspense>
        </Box>
      <Footer />
    </MainLayoutBox>
  );
};

export default MainLayout;
