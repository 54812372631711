import { FC, useState } from 'react';
import { Box, ClickAwayListener, styled } from '@mui/material';

import { ContainedButton } from 'components/common/Button';
import { icons } from 'config/constants/assets';

// origin
const SelectOrigin = styled(Box)(() => ({}));

const SelectOriginButton = styled(ContainedButton)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '6px 7px',
  width: '35px',
  
}));

const ActiveItemLogo = styled('img')(() => ({
  width: '25px',
  borderRadius: '50%'
}));

const ToggleIconWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#AAA06C',
  boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '20px',
  width: '20px',
  height: '20px',
}));

const ToggleIcon = styled('img')(() => ({
  width: '10px',
}));

// expanded
const SelectExpanded = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  gap: '10px',
  flexDirection: 'column',
  width: '140px',
  background: '#2A3139',
  boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
  borderRadius: '20px',
  padding: '25px 16px',
  marginTop: '8px',
}));

const SelectItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  background: '#4D5359',
  borderRadius: '10px',
  padding: '6px 5px',
}));

const SelectItemLogo = styled('img')(() => ({
  width: '21px',
  borderRadius: '50%',
}));

const SelectItemLabel = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '18px',
  color: 'rgba(255, 255, 255, 0.7)',
  textTransform: 'uppercase',
}));

interface ChainSelectProps {
  disabled?: boolean;
  options: any[];
  value: any;
  onSelect: (option: any) => void;
}

const ChainSelect: FC<ChainSelectProps> = ({ value, disabled, options, onSelect }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClickAway = () => {
    setIsOpen(false);
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onSelectItem = (item: any) => {
    onSelect(item);
    setIsOpen(false);
  };

  const selectedOption = value ? options.find((option) => option.chainId === value.chainId) : options[0];

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box sx={{ position: 'relative' }}>
        <SelectOrigin>
          <SelectOriginButton disabled={disabled} onClick={onToggle}>
            <ActiveItemLogo alt="chain logo" src={selectedOption.logo} />
            {/* <ToggleIconWrapper>
              <ToggleIcon
                alt="toggle"
                src={icons.chevronDown1}
                sx={{ transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }}
              />
            </ToggleIconWrapper> */}
          </SelectOriginButton>
        </SelectOrigin>
        {isOpen && (
          <SelectExpanded>
            {options.map((option) => (
              <SelectItem key={option.chainId} onClick={() => onSelectItem(option)}>
                <SelectItemLogo alt="chain logo" src={option.logo} />
                <SelectItemLabel> {option.label}</SelectItemLabel>
              </SelectItem>
            ))}
          </SelectExpanded>
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default ChainSelect;
