import React, { FC, useState } from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Prompt',
    width: '100%',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    padding: '8px 19px',
    background: 'linear-gradient(180deg, rgb(33, 102, 181) 0%, rgb(39, 60, 100) 100%)',
    color: 'white',
    borderColor: 'transparent',
    borderRadius: '10px',
    whiteSpace: 'nowrap',
    boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.2), inset 0 -1px 0 rgba(0, 0, 0, 0.2)',
    transition: 'color 0.4s, background-color 0.4s, box-shadow 0.4s',
    transform: 'translateZ(0)',

    // Hover state
    '&:hover': {
        background: 'linear-gradient(180deg, rgb(45, 145, 255) 0%, rgb(70, 100, 180) 100%)',
        boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.4)',
        opacity: '1.0'
    },

    // Active state
    '&.active': {
        background: 'linear-gradient(180deg, rgb(20, 80, 140) 0%, rgb(30, 50, 90) 100%)',
        boxShadow: 'inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.4)',
        opacity: '0.7'
    },
}));

interface ContainedButtonProps extends ButtonProps {
    disabled?: boolean;
    colorType?: string;
    isActive?: boolean;
}

const ContainedBlueButton: FC<ContainedButtonProps> = ({ children, disabled, onClick, isActive=false, ...props }) => {

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) onClick(event);
    };

    return (
        <StyledButton
            disabled={disabled}
            className={isActive ? 'active' : ''}
            onClick={handleClick}
            {...props}
        >
            {children}
        </StyledButton>
    );
};

export { ContainedBlueButton };
