import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, styled, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { BigNumber } from 'ethers';
import moment from 'moment';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { StakingModal } from 'components/Modal';
import { getBalanceInEther, getBalanceInWei, toTwoDigits } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { useStake, useTokenPrice } from 'hooks/useStakeInfo';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { getLockerAddress, getMemDaoAddress, getRouters, getTokenDeployerInfo } from 'utils/deployTokens';
import useCountDown from 'hooks/useCountDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TokenModal from 'components/Modal/TokenModal';
import { fetchDeployedTokensDataAsync, fetchStakerInfoAsync, fetchStakerShortInfoAsync } from 'state/actions';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import { useGetTokensLogoPiniata2 } from 'hooks/useGetDeployedTokenImage';
import StakerPool from './StakerPool';
import useCountDownUp from 'hooks/useCountDownUp';

const Card = styled(Box)(({ theme }) => ({
    background: "rgba(42,49,57, 0.4)",
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '20px'
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: '20px'
      },
  
}));

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '30px',
    background: "rgba(42,49,57, 0.4)",

    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    // alignItems: 'center',
    // justifyContent: 'center',
  //   alignItems: 'center',
    flexDirection: 'column',
    minHeight: '700px',
    minWidth: '300px',
  //   borderRadius: '20px',
  //   background: "#2A3239",
  //   boxShadow:
  //     '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
  
    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
      minWidth: '280px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 32px',
    },
  }));

// origin section
const Origin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  boxShadow:
  '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
 // 5A687D
  transform: 'translate3d(0px, 0px, 0px)',
  border: '1px solid rgba(46,145,250,0.0)', 
  "&:hover": {
    boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    transform: 'translate3d(0px, -4px, 0px)',
    border: '1px solid rgba(46,145,250,0.8)', 
   // 5A687D
  },
  transition: 'all 0.25s ease-in-out',
}));

// token img section
const TokenImgSection = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const TokenImg = styled('img')(({ theme }) => ({
  width: '60px',

  [theme.breakpoints.up('xs')]: {
    width: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60px',
  },
}));

const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));

const PoolSupply = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  }));

  const PoolInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
  }));
  const PoolInfoLabelEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'break-spaces',
    },
  }));

const PoolInfoLabelHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '16px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',
  width: '100%',
  marginLeft: '10px',
  [theme.breakpoints.up('xs')]: {
    maxWidth: '200px',
    display: 'block',
    textAlign: 'left',
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '400px',
    display: 'block',
    textAlign: 'left',
    whiteSpace: 'break-spaces',
  },
}));
const PoolInfoLabelEndHeader = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '16px',
  maxWidth: '200px',
  marginRight: '10px',
  whiteSpace: 'break-spaces',
  textAlign: 'right',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    textAlign: 'right',
    whiteSpace: 'break-spaces',
  },
}));

const PoolText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '16px',
  marginRight: '10px',
  whiteSpace: 'break-spaces',
  textAlign: 'center',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
}));

  const PoolNameInfoEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '160px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const SupplyValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
  }));


// info section
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    gap: '15px',
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    gap: '30px',
  },
}));

const InfoCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '30px',
  color: '#ffffff',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
  margin: 'auto',

  span: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginLeft: '4px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '19px',
    lineHeight: '23px',
    span: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px',
    span: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const RewardImg = styled('img')(() => ({
  width: '22px',
}));

// desktop expanded section
const ExpandedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent:'center',
  gap: '15px',
  alignItems: 'space-around',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    alignItems: 'space-around',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  },
}));

// global info section
const GlobalInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0px 15px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  borderBottom: '1.5px solid rgba(255, 255, 255, 0.3)',
  flexWrap: 'wrap',

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  },
  [theme.breakpoints.up('md')]: {},
}));

const GlobalInfoCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0px',
  },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  width: '100%',
  cursor: 'pointer',
  textWrap: 'wrap',
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '18px',
    img: {
      display: 'block',
    },
  },
}));

// action section
const ActionSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    // background: '#2A3139',
    // boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
  }));

// modal body
const ModalBody = styled(Box)(() => ({
  marginTop: '23px',
  marginBottom: '21px',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  gap: '20px'
}));

interface Props {
    index?: number,
    stakerInfo?: any,
    isSepparatePage: boolean,
    onClose?: () => void
}

const StakerPage: FC<Props> = ({ index, isSepparatePage,stakerInfo, onClose }) => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [lockedLps, setLockedLps] = useState<Number>(0);
  const [info, setInfo] = useState<any>({});
  const [stakers, setStakers] = useState<any>([]);
  const [stakerPools, setStakerPools] = useState<any>([]);
  const [currentStakerInfo, setCurrentStakerInfo] = useState<any>({});
  const [isPoolsStarted, setIsPoolsStarted] = useState<boolean>(false);
  const [isPoolsEnded, setIsPoolsEnded] = useState<boolean>(false);
  const [isShowTime, setIsShowTime] = useState<boolean>(false);
  const [poolStartTime, setPoolStartTime] = useState<Number>(0);
  const [poolEndTime, setPoolEndTime] = useState<Number>(0);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [days, hours, minutes, seconds] = useCountDownUp(isShowTime ? Number(isPoolsStarted ? poolEndTime : poolStartTime) * 1000 : new Date().getUTCSeconds() * 1000);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const { deployedTokens, size, stakersInfo, stakersPools } = useAppSelector((deployedTokens) => deployedTokens.deployedTokens);
  const [daysUntilUnlock, hoursUntilUnlock, minutesUntilUnclock, secsUntilUnlock] = useCountDownUp(info?.info ? Number(info.unlockDate) * 1000 : new Date().getUTCSeconds() * 1000);
  const tokenDeployerInfo = getTokenDeployerInfo(Number(selectedChainId));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const images = useGetTokensLogoPiniata2(0)
  useEffect(() => {
    const a = document.createElement('a');
    const url = new URL(window.location.href);
    const res = url.pathname.split('/');
    if( res.length > 4 ){
        if( deployedTokens[Number(res[3])] ){
            setInfo(deployedTokens[Number(res[3])])
            setIsLoading(false)
        }
    } else if (res.length > 3){

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    if( index != undefined && stakersInfo ){
            setCurrentStakerInfo(stakersInfo[index])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, stakersInfo]);

  useEffect(() => {
    if( index != undefined && stakerInfo ){
            setInfo(stakerInfo)
            setIsLoading(false)
        
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, stakerInfo]);

  const onCloseModal = () => {
    setIsExpanded(false)
  };

  useEffect(() => {
    if( images && info ){
        setTokenImage(images[info?.info?.tokenAddress]?.img)
    }
}, [images, info]);

useEffect(() => {
  if( stakerPools && stakerPools?.length > 0 ){
    const currentDate = Math.floor((new Date()).getTime() / 1000)
    setPoolStartTime(stakerPools[0].poolStartTime )
    setPoolEndTime(stakerPools[0].poolEndTime )
    if( currentDate > stakerPools[0].poolStartTime ){
      setIsPoolsStarted(true)
    } 
    else {
      setIsPoolsStarted(false)
      setIsShowTime(true)
      return;
    }
    if( currentDate > stakerPools[0].poolEndTime ){
      setIsPoolsEnded(true)
    } 
    else {
      setIsPoolsEnded(false)
    }
    setIsShowTime(true)

  }
}, [stakerPools]);

useEffect(() => {
    const pools = []
    if( stakersPools && info ){
        try{
            if( stakersPools[info.info.tokenAddress]) {
                for (const pool  of Object.values(stakersPools[info.info.tokenAddress])) {
                    pools.push(pool)
                    // break
                }
                setStakerPools(pools)
            }
        }
        catch(err){
            console.log(err)
        }
    }
}, [stakersPools]);

useEffect(() => {
if( ftmPrice && info ){
    setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
}
}, [ftmPrice, info]);


  
// useEffect(() => {
//     const stakers = []
//     for (const staker  of Object.values(stakersInfo)) {
//         stakers.push(staker)
//         break
//     } 
//     console.log(stakers)
//     setStakers(stakers)
// }, [stakersInfo]);

useEffect(() => {
    if( info ) {
        dispatch(fetchStakerInfoAsync(Number(selectedChainId), account ? account : "", ftmPrice, info));
    }
}, [info, selectedChainId]);

useEffect(() => {
if( info ){ 
  const routers = getRouters(Number(selectedChainId))
  setLockedLps(Number(info?.lockLps))
  for( let i = 0; i < routers.length; i+=1 )
  {
    if( routers[i].address === info?.info?.router ){
        setAmm(routers[i].name)
        return
    }
  }
}
}, [info, selectedChainId]);

const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };

  return (
    <> <Container>
        {/* body */}
        <ModalBody sx={{position: 'relative'}}>
          { info && info?.currentRouterLogo?.length > 0 &&
              <img src={info ? info?.currentRouterLogo : ""}  style={{width: '40px', height: '40px', objectFit: 'cover', position: 'absolute', right: 10, top: 0}}/>
          }
            { !isSepparatePage && 
                <>
                    <Box onClick={onClose} display='flex'  position='absolute' sx={{top: {xs: -50, md: -50}, left: {xs: -0, md: -50}, border: '1px solid #5E6980', borderRadius: '15px', padding: '10px', background: '#2A3139'}}>
                            <ArrowBackIosNewIcon sx={{width: '20px', height: '20px'}}/>
                    </Box>
                </>
            }
            <Box gap='20px' display='flex' alignItems='center' sx={{border: '0px solid red',width: '100%', flexDirection: {xs: 'column', md: 'row'}}}>
                        <Box display='flex' flexDirection='row'  alignItems='center' gap='10px' sx={{height: '100%', maxWidth: '200px', border: '0px solid red', position: 'relative'}}> 
                            {tokenImage?.length > 0 && 
                                <img src={tokenImage.length > 0 ? tokenImage : ""}  style={{width: '180px', height: '190px', minWidth: '150px', objectFit: 'cover', borderRadius: '10px'}}/>
                            }
                        </Box>
                        <Box display='flex' gap={'10px'} flexDirection='column'  justifyContent='center' alignItems={'center'} sx={{border: '0px solid blue', width: '100%', height: '100%'}}>
                            <PoolName >
                                {info?.info?.name}
                            </PoolName>
                            <PoolSymbol>
                                {info?.symbol}
                            </PoolSymbol>
                          <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', width: '100%'}}>
                            <PoolText>
                              Earn {info?.symbol} by staking assets below in their corresponding pools
                            </PoolText>

                          </Box>
                          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', width: '100%'}}>
                            <PoolInfoLabelHeader>
                              Total Rewards distributed between pools
                            </PoolInfoLabelHeader>
                            <PoolInfoLabelEndHeader>
                              {currentStakerInfo?.poolRewards ?  Number(currentStakerInfo?.poolRewards).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ` ` + info?.symbol : '--.--'}
                            </PoolInfoLabelEndHeader>
                          </Box>
                          <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', width: '100%'}}>
                            {/*<PoolInfoLabelHeader>*/}
                            {/* Rewards {`${ isPoolsStarted ? isPoolsEnded ? 'ended' : 'ends in' : 'starts in' }`}*/}
                            {/*</PoolInfoLabelHeader>*/}
                            {/*<PoolInfoLabelEndHeader>*/}
                            {/*    { isShowTime && !isPoolsEnded ? `${ days > 0 ?  days <= 9 ? "0" + days + " /": days + " /" : ""}  ${hours <=9 ? "0"+hours : hours}:${minutes <=9 ? "0"+minutes : minutes}:${seconds <= 9 ? "0" + seconds: seconds}` : `` }*/}
                            {/*</PoolInfoLabelEndHeader>*/}

                            <PoolInfoLabelHeader>
                              Rewards {`${isPoolsStarted ? (isPoolsEnded ? 'ended' : 'ends in') : 'starts in'}`}
                            </PoolInfoLabelHeader>
                            <PoolInfoLabelEndHeader>
                              {isShowTime && !isPoolsEnded ? (
                                  <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'right' }}>
                                    {days > 0 && (
                                        <div style={{ margin: '0 5px', textAlign: 'center' }}>
                                          <div>{days <= 9 ? "0" + days : days} /</div>
                                          <div style={{ fontSize: '12px' }}>d</div>
                                        </div>
                                    )}
                                    <div style={{ textAlign: 'center' }}>
                                      <div>{hours <= 9 ? "0" + hours : hours}:</div>
                                      <div style={{ fontSize: '12px' }}>h</div>
                                    </div>
                                    <div style={{  textAlign: 'center' }}>
                                      <div>{minutes <= 9 ? "0" + minutes : minutes}:</div>
                                      <div style={{ fontSize: '12px' }}>m</div>
                                    </div>
                                    <div style={{  textAlign: 'center' }}>
                                      <div>{seconds <= 9 ? "0" + seconds : seconds}</div>
                                      <div style={{ fontSize: '12px' }}>s</div>
                                    </div>
                                  </div>
                              ) : ``}
                            </PoolInfoLabelEndHeader>
                          </Box>

                    </Box>
            </Box>
        </ModalBody>
            <>
            <Box mt={2} gap='10px' display='flex' flexDirection='column'>
                 {stakerPools && stakerPools
                .map((row: any) => <StakerPool tokenInfo={info} poolInfo={row} />)}
            </Box>

            </>
                
        </Container>
    </>
  );
};

export default StakerPage;
