import { icons, tokens as tokenImages } from 'config/constants/assets';

const fantomTokenInfos = [
  {
    address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
    logo: tokenImages.basedLogo,
    name: 'Based',
    isRounded: true,
    tokenLp: "0x0981916Bd96d375dAd0067Fd24E19120D2fcF5e6",
    decimals: 18,
    buyUri: [{chain: 8543, url: 'test'}, {chain: 250, url: 'https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae'}],
    cgc: ""
  },
  {
    address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
    logo: tokenImages.obolLogo,
    name: 'Obol',
    isRounded: false,
    tokenLp: "0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9",
    decimals: 18,
    buyUri: [{chain: 250, url: 'https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216'}],
    cgc: ""
  },
  {
    address: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    logo: tokenImages.rewardFtm,
    name: 'WFTM',
    isRounded: false,
    tokenLp: "",
    decimals: 18,
    cgc: ""
  },
  {
    address: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
    logo: tokenImages.equalLogo,
    name: 'Equal',
    isRounded: false,
    tokenLp: "0x3d6c56f6855b7Cc746fb80848755B0a9c3770122",
    decimals: 18,
    cgc: ""
  },
  {
    address: "0xc83A698508d671f08C6775AeC4c8DA46bD828613",
    logo: tokenImages.sonicLogo,
    name: 'SonicX',
    isRounded: false,
    tokenLp: "0xd319b58E5A71BD6188b5C0118Fa97c2dBF36D739",
    decimals: 18,
    cgc: ""
  },
  {
    address: "0x07BB65fAaC502d4996532F834A1B7ba5dC32Ff96",
    logo: tokenImages.fvmLogo,
    name: 'Velo',
    isRounded: false,
    tokenLp: "0x0E8f117a563Be78Eb5A391A066d0d43Dd187a9E0",
    decimals: 18,
    cgc: ""
  },
  {
    address: "0x2F733095B80A04b38b0D10cC884524a3d09b836a",
    logo: tokenImages.usdcLogo,
    name: 'Usdc.e',
    isRounded: false,
    tokenLp: "",
    decimals: 6,
    cgc: ""
  },
  {
    address: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    logo: tokenImages.usdcLogo,
    name: 'Usdc',
    isRounded: false,
    tokenLp: "",
    decimals: 6,
    cgc: ""
  },
  {
    address: "0x4200000000000000000000000000000000000006",
    logo: tokenImages.ethLogo,
    name: 'ETH',
    isRounded: false,
    tokenLp: "",
    decimals: 18,
    chain: "base",
    cgc: 'ETH'
  },
  {
    address: "0x4ed4E862860beD51a9570b96d89aF5E1B0Efefed",
    logo: tokenImages.degenLogo,
    name: 'DEGEN',
    isRounded: true,
    tokenLp: "",
    decimals: 18,
    chain: "base",
    cgc: 'DEGEN'
  },
  {
    address: "0x940181a94A35A4569E4529A3CDfB74e38FD98631",
    logo: tokenImages.aeroLogo,
    name: 'AERO',
    isRounded: false,
    tokenLp: "",
    decimals: 18,
    chain: "base",
    cgc: 'AERODROME'
  },
  {
    address: "0x54016a4848a38f257B6E96331F7404073Fd9c32C",
    logo: tokenImages.scaleLogo,
    name: 'SCALE',
    isRounded: false,
    tokenLp: "",
    decimals: 18,
    chain: "base",
    cgc: 'SCALE'
  },
  {
    address: "0x532f27101965dd16442E59d40670FaF5eBB142E4",
    logo: tokenImages.brettLogo,
    name: 'BRETT',
    isRounded: false,
    tokenLp: "",
    decimals: 18,
    chain: "base",
    cgc: 'BRETT'
  },
  {
    address: "0x74235c64f806341C14bF2be0887EEcd55daE955E",
    logo: tokenImages.basedLogo,
    name: 'BASED',
    isRounded: false,
    tokenLp: "0xf7d85fc57a8a747b6919274f6adcce74c2229e97",
    decimals: 18,
    chain: "base",
    buyUri: [{chain: 8453, url: 'https://www.velocimeter.xyz/swap?from=0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee&to=0x74235c64f806341C14bF2be0887EEcd55daE955E'}],
    cgc: ''
  }
]

const tokenInfos = [...fantomTokenInfos];
export { tokenInfos};
