import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, useMediaQuery, Grid, styled, Tab, Tabs, Tooltip, Typography, useTheme } from '@mui/material';
import { BigNumber, utils } from 'ethers';
import moment from 'moment';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { icons } from 'config/constants/assets';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { StakingModal } from 'components/Modal';
import { getBalanceInEther, getBalanceInWei, toTwoDigits } from 'utils';
import { useWeb3React } from '@web3-react/core';
import { useStake, useStakeEstimateInfo, useTokenPrice } from 'hooks/useStakeInfo';
import { useFtmPrice } from 'hooks/useFTMPrice';
import { getRouters } from 'utils/deployTokens';
import useCountDown from 'hooks/useCountDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TokenModal from 'components/Modal/TokenModal';
import { fetchDeployedTokensDataAsync, fetchStakerInfoAsync, fetchStakerShortInfoAsync } from 'state/actions';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import { useGetTokensLogoPiniata2 } from 'hooks/useGetDeployedTokenImage';
import { getTokenInfo } from 'utils/tokens';
import TabPanel from 'components/Earn/Stakings/TabPanel';
import CountDown from 'components/Earn/Stakings/CountDown';

const Card = styled(Box)(({ theme }) => ({
    background: "rgba(42,49,57, 0.4)",
      [theme.breakpoints.up('xs')]: {
        flexDirection: 'column',
        gap: '20px'
      },
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: '20px'
      },
  
}));

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    marginTop: '10px',
    background: "rgba(42,49,57, 0.4)",
    border: '1px solid #5E6980',
    display: 'flex',
    maxWidth: '900px',
    borderRadius: '10px',
    // alignItems: 'center',
    // justifyContent: 'center',
  //   alignItems: 'center',
    flexDirection: 'column',
    // minHeight: '700px',
    // minWidth: '300px',
  //   borderRadius: '20px',
  //   background: "#2A3239",
  //   boxShadow:
  //     '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
  
    [theme.breakpoints.up('xs')]: {
      padding: '0px 16px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 32px',
    },
  }));

// origin section
const Origin = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  boxShadow:
  '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
 // 5A687D
  transform: 'translate3d(0px, 0px, 0px)',
  border: '1px solid rgba(46,145,250,0.0)', 
  "&:hover": {
    boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    transform: 'translate3d(0px, -4px, 0px)',
    border: '1px solid rgba(46,145,250,0.8)', 
   // 5A687D
  },
  transition: 'all 0.25s ease-in-out',
}));

// token img section
const TokenImgSection = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
}));

const TokenImg = styled('img')(({ theme }) => ({
  width: '60px',

  [theme.breakpoints.up('xs')]: {
    width: '50px',
  },
  [theme.breakpoints.up('md')]: {
    width: '60px',
  },
}));

const PoolName = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '24px',
  maxWidth: '200px',
  whiteSpace: 'break-spaces',
  textAlign: 'left',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    whiteSpace: 'break-spaces',
  },
}));

const PoolSymbol = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '500',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      whiteSpace: 'break-spaces',
    },
  }));

const PoolSupply = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '20px',
    lineHeight: '24px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',

    [theme.breakpoints.up('xs')]: {
        display: 'block',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
        whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
        display: 'block',
        whiteSpace: 'break-spaces',
    },
}));

const PoolName2 = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    },
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    },
  }));

  const PoolInfoLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'left',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolAprLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolAprValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    width: '100%',
    marginLeft: '10px',
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolInfoLabelEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    marginRight: '10px',
    whiteSpace: 'break-spaces',
    textAlign: 'right',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'right',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameInfoEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '16px',
    maxWidth: '200px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const PoolNameEnd = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '160px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'end',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'end',
      whiteSpace: 'break-spaces',
    },
  }));

  const SupplyValue = styled(Typography)(({ theme }) => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: '24px',
    maxWidth: '300px',
    whiteSpace: 'break-spaces',
    textAlign: 'end',
    width: '100%',
  
    [theme.breakpoints.up('xs')]: {
      display: 'block',
      margin: 'auto',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('sm')]: {
      whiteSpace: 'break-spaces',
    },
    [theme.breakpoints.up('md')]: {
      display: 'block',
      textAlign: 'center',
      whiteSpace: 'break-spaces',
    },
  }));

const TokenSymbol = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  maxWidth: '300px',
  whiteSpace: 'break-spaces',
  textAlign: 'end',
  width: '100%',

  [theme.breakpoints.up('xs')]: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  [theme.breakpoints.up('sm')]: {
    whiteSpace: 'break-spaces',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
  },
}));


// info section
const InfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '30px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    gap: '15px',
    width: '100%',
    justifyContent: 'center',
  },
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    gap: '30px',
  },
}));

const InfoCell = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: '21px',
  color: 'white',
  margin: 'auto',

  img: {
    width: '20px',
    marginRight: '8px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '21px',
    img: {
      display: 'block',
    },
  },
}));

const InfoValue = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '25px',
  lineHeight: '30px',
  color: '#ffffff',
  textAlign: 'start',
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'end',
  margin: 'auto',

  span: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: '400',
    marginLeft: '4px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '19px',
    lineHeight: '23px',
    span: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '24px',
    span: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}));

const RewardImg = styled('img')(() => ({
  width: '22px',
}));

// desktop expanded section
const ExpandedBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent:'center',
  gap: '15px',
  alignItems: 'space-around',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    alignItems: 'space-around',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row'
  },
}));

// global info section
const GlobalInfoSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '0px 15px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  width: '100%',
  borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  borderBottom: '1.5px solid rgba(255, 255, 255, 0.3)',
  flexWrap: 'wrap',

  [theme.breakpoints.up('xs')]: {
    borderTop: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    borderTop: '1.5px solid rgba(255, 255, 255, 0.3)',
  },
  [theme.breakpoints.up('md')]: {},
}));

const GlobalInfoCard = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',

  [theme.breakpoints.up('xs')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.up('md')]: {
    padding: '15px 0px',
  },
}));

const GlobalInfoCardLabel = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  width: '100%',
  cursor: 'pointer',
  textWrap: 'wrap',
  color: 'white',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  [theme.breakpoints.up('xs')]: {
    fontSize: '12px',
    lineHeight: '18px',
    img: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '14px',
    lineHeight: '18px',
    img: {
      display: 'block',
    },
  },
}));

// action section
const ActionSection = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '10px',
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '0',
    top: '0',
    // background: '#2A3139',
    // boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '45px',
    height: '45px',
    zIndex: 1
  }));

// modal body
const ModalBody = styled(Box)(({theme}) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  gap: '10px',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
    padding: '10px',
  },
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: '0px',
  },
}));

interface Props {
    tokenInfo: any,
    poolInfo: any,

}

function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        style={{width: '100%'}}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box display={'flex'} sx={{width: '100%'}}>
            {children}
          </Box>
        )}
      </div>
    );
  }


const StakerPool: FC<Props> = ({ tokenInfo, poolInfo}) => {
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const ftmPrice = useFtmPrice(Number(selectedChainId));
  const [tokenPrice, setTokenPrice] = useState<string>("--");
  const [amm, setAmm] = useState<string>("--");
  const [tokenImage, setTokenImage] = useState<string>("");
  const [lockedLps, setLockedLps] = useState<Number>(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const images = useGetTokensLogoPiniata2(0)
  const [value, setValue] = useState(0);
  const [stakedDFund, setStakedDfund] = useState<number>(0);
  const [totalStaked, setTotalStaked] = useState<number>(0);
  const [tvl, setTvl] = useState<number>(0);
  const [apr, setApr] = useState<number>(0);
  const [dailyApr, setDailyApr] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [pendingRewards, setPendingRewards] = useState<number>(0);
  const [stakeTime, setStakeTime] = useState<number>(0);
  const [lockTime, setLockTime] = useState<number>(0)
  const [counter, setCounter] = useState<number>(0)
  const info = useStakeEstimateInfo(Number(selectedChainId), poolInfo, counter, tokenInfo, ftmPrice)
  const isClaimable = Math.floor((new Date()).getTime() / 1000) > lockTime

//   const [days, hours, minutes, seconds] = useCountDown(lockTime > 0 ? Number(lockTime) * 1000 : new Date().getUTCSeconds() * 1000);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if( info  ){
      try{
        setApr(info.aprYear)
        setDailyApr(info.aprYear/ 365)
        setTvl(Number(utils.formatUnits(info.totalStaked, info.decimals))*info.stakeTokenPriceFTM* ftmPrice) //pp
        setStakedDfund(Number(utils.formatUnits(info.userStaked, info.decimals)))
        setPendingRewards((Number(utils.formatUnits(info.pendingRewards, 18))))
        if(Number(getBalanceInEther(info.pendingRewards)) < 0 )
          setPendingRewards(0)
    
        setLockTime(Number(info.stakeTime) + Number(info.lockTime))
      }
      catch(err){
        // 
      }

    }
  }, [info, account]);;

const onCloseModal = () => {
    setIsExpanded(false)
  };

  useEffect(() => {
    if( images && info ){
        setTokenImage(images[info?.info?.tokenAddress]?.img)
    }
}, [images, info]);


useEffect(() => {
if( ftmPrice && info ){
    setTokenPrice((info?.tokenPriceFTM * ftmPrice).toFixed(info?.tokenPriceFTM < 0.001 ? 6 : 3))
}
}, [ftmPrice, info]);

useEffect(() => {
    if( info ) {
        // console.log("Start fetching pools")
        dispatch(fetchStakerInfoAsync(Number(selectedChainId),account ? account : "", ftmPrice, info));
    }
}, [info, selectedChainId]);

useEffect(() => {
if( info ){ 
  const routers = getRouters(Number(selectedChainId))
  setLockedLps(Number(info?.lockLps))
  for( let i = 0; i < routers.length; i+=1 )
  {
    if( routers[i].address === info?.info?.router ){
        setAmm(routers[i].name)
        return
    }
  }
}
}, [info, selectedChainId]);

const getTokInfo = (address: string) : any => {
    return getTokenInfo(address)
};
const onUpdate = () => {
    setCounter(counter+1);
};

  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
  };
  return (
    <> <Container>
        {/* body */}
        <ModalBody sx={{position: 'relative', justifyContent: 'space-between'}}>
            <>
                <Box  display='flex' onClick={() => {setIsExpanded(!isExpanded)}}  position='absolute' sx={{bottom: 18, right: {xs: 0, md: -50 } , border: '1px solid #5E6980', borderRadius: '25px', padding: '10px', background: '#2A3139'}}>
                        <ArrowBackIosNewIcon sx={{width: '15px', height: '15px', transform: !isExpanded ? 'rotate(-90deg)' : 'rotate(90deg)'}}/>
                </Box>
            </>
            
            <Box display='flex' flexDirection='column'  alignItems='center' gap='10px' sx={{ border: '0px solid red', height: '100%', width: '100%', maxWidth: '140px', overflow: getTokInfo(poolInfo?.stakingToken)?.isRounded ? 'clip' : '', position: 'relative'}}>
                {poolInfo &&
                    <>

                        <img src={getTokInfo(poolInfo?.stakingToken)?.tokenImage} style={{
                          maxWidth: '60px',
                          minHeight: '60px',
                          borderRadius: getTokInfo(poolInfo?.stakingToken)?.isRounded ? '50px' : '0px',
                          border: '0px solid red',
                          // left: -5,
                          // position: 'absolute',
                          objectFit: 'fill'
                        }}/>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={()=>{    navigator.clipboard.writeText(getTokInfo(poolInfo?.stakingToken)?.address);}}
                            style={{maxHeight: '24px', borderRadius: '0px', display: tokenInfo?.info?.deployerAddress ? "" : "none", width: '140px', maxWidth: '140px',}}

                        >

                          <Typography style={{fontSize: '11px', color: "lightgray"}} color='primary' variant={'h5'} >{truncate(getTokInfo(poolInfo?.stakingToken)?.address, 4)}
                          </Typography>
                          <FileCopyIcon style={{marginLeft: '2px',maxHeight:"18px", width: 'auto'}}/>
                        </Button>

                        </>
                }
            </Box>
            <Box display='flex' flexDirection='column' gap={'10px'} justifyContent='center' alignItems='center' sx={{background: "rgba(6,1,1,0.3)",  minHeight: '77px', height: '100%', borderRadius: '10px', minWidth: '150px'}}>

              <Box display={'flex'} flexDirection={'row'}>
                <TokenSymbol>
                  {getTokInfo(poolInfo?.stakingToken)?.tokenName.toUpperCase()}

                </TokenSymbol>
              </Box>
              <Box display={'flex'} flexDirection={'row'}>
                <PoolAprLabel>
                  APR
                </PoolAprLabel>
                <PoolAprValue>
                  {info?.apr ? info?.apr < 0 ? "Ended" : info?.apr < 1 ? `${info?.apr.toFixed(3)}%` : `${info?.apr.toFixed(0)}%` : ''}
                </PoolAprValue>
              </Box>
            </Box>
            <Box display='flex' gap={'10px'} sx={{flexDirection: {xs: 'column'}}}>    
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                <PoolInfoLabel sx={{minWidth: '120px'}}>
                                    TVL:  
                                </PoolInfoLabel>
                                <PoolInfoLabelEnd>
                                    ${tvl.toFixed(2)}
                                </PoolInfoLabelEnd>
                </Box> 
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                <PoolInfoLabel sx={{minWidth: '120px'}}>
                                    Deposit Fee:  
                                </PoolInfoLabel>
                                <PoolInfoLabelEnd>
                                    {info ? `${info?.depositFee}%` : `--.--`}
                                </PoolInfoLabelEnd>
                </Box>
            </Box>
            <Box display='flex' gap={'10px'} sx={{flexDirection: {xs: 'column'}}}>    
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                <PoolInfoLabel sx={{minWidth: '120px'}}>
                                    Your Stake
                                </PoolInfoLabel>
                                <PoolInfoLabelEnd>
                                  {`${Number(Number(stakedDFund || 0).toFixed(4)).toLocaleString()}`}
                                </PoolInfoLabelEnd>
                </Box> 
                <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                <PoolInfoLabel sx={{minWidth: '120px'}}>
                                    Rewards:  
                                </PoolInfoLabel>
                                <PoolInfoLabelEnd>
                                    {pendingRewards?.toFixed(2)}
                                </PoolInfoLabelEnd>
                </Box>
            </Box>

        </ModalBody>
        {isExpanded && 
                <>
                    <Box mt={2} sx={{width: '100%', height: '2px', background: '#5E6980'}}/>
                    <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} alignItems='center' justifyContent='space-between'>
                        <Box mt={2} display='flex' >
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.0)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                    <PoolInfoLabel sx={{minWidth: '120px'}}>
                                        Time until no tax claim
                                    </PoolInfoLabel>
                            </Box> 
                            <Box display='flex' flexDirection='row'  alignItems='center' justifyContent='center' gap='10px' sx={{height: '100%', maxWidth: '200px', border: '0px solid red', position: 'relative', minWidth: '100px'}}> 
                                <Box display='flex' justifyContent='center' alignItems='center' gap='5px' sx={{background: "rgba(6,1,1,0.7)",position: 'relative', display: {xs: 'flex', md: 'flex'}, padding: '2px', maxWidth: '100px', paddingRight: '8px', paddingLeft: '8px', borderRadius: '10px'}}>
                                    <img src={icons.timeIcon}  style={{width: '22px', height: '22px', minWidth: '20px', objectFit: 'fill'}}/>  
                                    <PoolName2 sx={{border: '0px solid red'}}>
                                    <CountDown lockTime={lockTime}/>
                                    </PoolName2>
                                </Box>
                            </Box>
                        </Box>
                        <Box mt={2} display='flex' gap='5px'>
                            <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' sx={{background: "rgba(6,1,1,0.3)", padding: '5px', minHeight: '34px', borderRadius: '10px', minWidth: '200px'}}>
                                    <PoolInfoLabel sx={{minWidth: '130px'}}>
                                        Early withdraw tax
                                    </PoolInfoLabel>
                                    <PoolInfoLabelEnd>
                                        { `10%`}
                                    </PoolInfoLabelEnd>
                            </Box> 
                        </Box>
                    </Box>
                    <Box mt={2} sx={{display: 'flex'}} flexDirection='column' justifyContent='center' alignItems='center'>
                        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} sx={{width: '100%'}} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Stake" {...a11yProps(0)} />
                            <Tab label="Unstake" {...a11yProps(1)} />
                            <Tab label="Claim" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                        <Box mt={2} display='flex' sx={{width: '100%'}}>
                        <CustomTabPanel value={value} index={0}>
                            <TabPanel key={1} type='Stake'  onUpdate={onUpdate} pool={poolInfo} info={info} isClaimable={isClaimable}/>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <TabPanel key={2} type='Unstake' onUpdate={onUpdate} pool={poolInfo} info={info} isClaimable={isClaimable} />
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <TabPanel key={3} type='Claim'  onUpdate={onUpdate} pool={poolInfo} info={info} isClaimable={isClaimable}/>
                        </CustomTabPanel>
                        </Box>
                    </Box>

                </>
            }
        </Container>
    </>
  );
};

export default StakerPool;
