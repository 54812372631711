import { ChainInfo } from 'types/chain';
import { chains } from 'config/constants/assets';

export const DEFAULT_ACTIVE_CHAIN_ID: number = Number(process.env.REACT_APP_CHAIN_ID) || 250;

export const SUPPORTED_CHAINS: ChainInfo[] = [
  { name: 'Fantom', chainId: 250, nativeCurrency: 'FTM', label: 'fantom', isMainnet: true, logo: chains.fantom },
  { name: 'Base', chainId: 8453, nativeCurrency: 'ETH', label: 'base', isMainnet: true, logo: chains.base }
];

export const CHAIN_INFO: { [key: number]: any } = {
  250: {
    explorer: 'https://ftmscan.com/',
    explorerName: 'FTMScan',
    label: 'Fantom',
    logoUrl: chains.fantom,
    nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  },
  8453: {
    explorer: 'https://basescan.org',
    explorerName: 'BaseScan',
    label: 'Base',
    logoUrl: chains.base,
    nativeCurrency: { name: 'Base', symbol: 'ETH', decimals: 18 },
  }
};
