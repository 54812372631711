import {FC} from "react";
import {Box, Divider, Grid, styled, Typography} from "@mui/material";
import TokenomicsDiagramm from "../../DeployPresaleModal/Components/TokenomicsDiagramm";
import {getBalanceInEther} from "../../../../utils";

const Body = styled(Box)(({ theme }) => ({
    gap:'16px',
    marginBottom: '20px',
    border: '0px solid red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '165px',
    flexDirection: 'column',
}));

const ColumnItem = styled(Box)(({ theme }) => ({
    gap:'16px',
    marginBottom: '20px',
    border: '0px solid red',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '165px',
    flexDirection: 'column',
}));

interface Props {
    open: boolean;
    handleClose: () => void;
    info: any;
    tokenDescription: string
}



const TokenomicsTab: FC<Props> = ({ open, handleClose, info, tokenDescription}) => {


    function getPresalePrice(): string {
        return info?.info?.presaleInfo?.presalePrice ? Number(getBalanceInEther(info?.info?.presaleInfo?.presalePrice)).toString() : '';
    }
    function getLaunchPrice(): string {
        return info?.info?.tokenInfo?.tokenLaunchPrice ? Number(getBalanceInEther(info?.info?.tokenInfo?.tokenLaunchPrice)).toString() : '';
    }

    function getHardCap() : string {

        return info?.info?.presaleInfo?.hardCap ? Number(getBalanceInEther(info?.info?.presaleInfo?.hardCap)).toString() : '';
    }

    function getTotalSupply(): string {
        return info?.info?.tokenInfo?.totalSupply ? Number(getBalanceInEther(info?.info.tokenInfo?.totalSupply)).toFixed(0).toString() : '';
    }

    function getTokensForPresale(): string {

        return info?.info?.presaleInfo?.tokensForPresale ? Number(getBalanceInEther(info?.info.presaleInfo?.tokensForPresale)).toFixed(0).toString() : '';
    }

    function getTokensForLp(): string {
        return (parseFloat(getHardCap())  / parseFloat(getLaunchPrice())).toString();
    }

    function getTokensHeldByCreator(): string {
        // totalSupply - presaleTokenCount - hardCap/presaleLaunchPrice;
        const totalSupply = parseFloat(getTotalSupply());
        const presaleTokenCount = parseFloat(getTokensForPresale());
        const tokensForLp = parseFloat(getTokensForLp())

        return ( totalSupply - totalSupply/100 - presaleTokenCount - tokensForLp).toString()
    }

    function getTokensSentToAirdrop(): string {
        const totalSupply = getTotalSupply();
        if(totalSupply != '')
        {
            let ret = parseFloat(totalSupply);
            ret = (ret/100) * 0.5;
            return ret.toString();
        }
        return '';
    }

    function getTokensSentToBasedLabs(): string {
        const totalSupply = getTotalSupply();
        if(totalSupply != '')
        {
            let ret = parseFloat(totalSupply);
            ret = (ret/100) * 0.5;
            return ret.toString();
        }
        return '';
    }




    const tokenDiagrammInfo = [
        { label: 'Tokens for Presale', value: getTokensForPresale(), color: 'green' },
        { label: 'Tokens for LP (if hardcap is reached)', value: getTokensForLp(), color: 'olive' },
        { label: 'Tokens held by Creator', value: getTokensHeldByCreator(), color: 'red' },
        { label: 'Tokens sent to Airdrop Farming Pools', value: getTokensSentToAirdrop(), color: 'orange' },
        { label: 'Tokens sent to Based Labs Treasury', value: getTokensSentToBasedLabs(), color: 'blue' },
    ];

    return(
        <Box display={'flex'} sx={{
            gap: 2,
            border: '0px solid red',
            height: '100%',
            minHeight: '300px',
            flexDirection: 'column',
        }}>
            <Box sx={{
                border: '0px solid green',
                //flex: 1,
                height: '200px',
                width: '100%',
                background: 'rgba(6,1,1,0.33)',
                borderRadius: '10px',
            }}>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{ fontSize: '2rem', fontWeight: 'bold' }}
                >
                    Project Description
                </Typography>
                <Typography
                    variant="h6"
                    align="center"
                    gutterBottom
                    sx={{ fontSize: '1rem', padding: '5px', fontWeight: 'lighter', wordWrap: 'wrap', whiteSpace: 'normal', wordBreak: "break-word" }}
                >
                    {tokenDescription}
                </Typography>
            </Box>

            <Box sx={{
                flex: 1,
                height: '100%',
                width: '100%',
                borderRadius: '10px',
            }}>
                <Box>
                    <Divider sx={{ borderColor: 'white', }} />
                    <Typography
                        variant="h6"
                        align="center"
                        gutterBottom
                        sx={{ fontSize: '2rem', fontWeight: 'bold' }}
                    >
                        Tokenomics Overview
                    </Typography>

                    <TokenomicsDiagramm tokenDiagrammInfo={tokenDiagrammInfo} totalSupply={getTotalSupply()} />
                </Box>
            </Box>
        </Box>

    )

};

export default TokenomicsTab;