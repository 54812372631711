import random from 'lodash/random';
import { NETWORK_RPC_URLS } from 'config/constants/rpc';

const getNodeUrl = (networkId?: number): string => {
  const chainId = networkId || parseInt(process.env.REACT_APP_CHAIN_ID || '250', 10);
  const nodes = [NETWORK_RPC_URLS[chainId], NETWORK_RPC_URLS[chainId], NETWORK_RPC_URLS[chainId]];
  const randomIndex = random(0, nodes.length - 1);
  return nodes[randomIndex];
};

export default getNodeUrl;
