import { FC, useEffect, useState } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';

import { useAppSelector } from 'state/hooks';
import { icons, images, tokens } from 'config/constants/assets';
import Footer from 'components/Footer';
import { Link } from 'react-scroll';
import PresaleSection from './sections/Presale';
import TokenomicsSection from './sections/Tokenomics';
import GoalSection from './sections/Goal';
import HowToSection from './sections/HowTo';
import DeployToken from 'components/DeployToken/DeployToken';
import ListedTokens from 'components/ListedTokens/ListedTokens';
import DeployPresale from 'components/DeployPresale/DeployPresale';
import ListedPresale from 'components/ListedPresale/ListedPresale';

// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  // gap: '20px',
  background: 'transparent',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

const OverviewTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.text.primary,
  fontSize: '47px',
  lineHeight: '26px',

  [theme.breakpoints.up('xs')]: {
    marginBottom: '5px',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: '18px',
  },
}));
const OverviewSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  color: theme.palette.text.primary,
  fontSize: '20px',
  lineHeight: '26px',
  maxWidth: '500px',
  lineWidth: '500px',
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: 'break-word',
  // overflow: 'break-word',
  nowrap: false,
  // textOverflow: "ellipsis",
  // overflowWrap: 'break-word',
  // wordBreak: "break-word",
  [theme.breakpoints.up('xs')]: {
    marginBottom: '5px',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: '18px',
  },
}));

interface Props {
  isStaking: boolean
}

const PresalePage: FC<Props> = ({ isStaking }) => {
  const [isBurnAcceleratorExpanded, setBurnAcceleratorExpanded] = useState<boolean>(false);
  const [isBoilerRoomExpanded, setIsBoilerRoomExpanded] = useState<boolean>(false);
  const { selectedChainId } = useAppSelector((state) => state.chain);

  return (
        <>
        <OverviewSection mt={5}>
          {isStaking &&
            <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{}}>
              <DeployPresale/>
            </Box>
          }

            <Box mt={2} flexDirection={'column'} display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{width: '100%'}}>
              <ListedPresale isStaking={isStaking}/>
            </Box>
        </OverviewSection>
        </>
  );
};

export default PresalePage;
