import { ApolloClient, gql, InMemoryCache } from '@apollo/client';

export const GRAPHQL_URL = 'https://based-graph.herokuapp.com/graphql';

export const graphqlClient = new ApolloClient({
    uri: GRAPHQL_URL,
    cache: new InMemoryCache(),
  });
  
  const tokensQuery = `
    query tokensQuery($limit: Int!, $skip: Int!) {
      tokensMany(limit: $limit, skip: $skip)
        {
          tokenAddress
          symbol
          decimals
          priceInUSD
          priceUSD
          priceInWFTM
          tradeVolume
        }
    }
  `;
  
  export const getTokensInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(tokensQuery),
        variables: {
          limit: limit || 50,
          skip: skip || 0,
        },
        fetchPolicy: 'network-only',
      });
  
      return result.data.tokensMany;
    } catch (err) {
      return [];
    }
  };

  const tokensDeployedQuery = `
query getTokensCookiesV2ByChain($limit: Int!, $skip: Int!, $chain: Int!){
  getTokensCookiesV2ByChain(limit: $limit, skip: $skip, chain: $chain){
		tokenName,
    tokenAddress,
    pairAddress,
    blocked,
    priceFTM,
    dao,
    mcapFTM,
    initialPriceFtm,
    chain_id,
    lpCount,
    deployerAddress,
    tokenDeployer,
    tokenSymbol,
    lockAmount,
    supply,
    pool,
    lockEth,
    lockLiq,
    router,
    timestamp,
    elockId
  }
}
`;

export const getDeployedTokensInfo = async ({ limit, skip, chain }: { limit?: number; skip?: number, chain: number }) => {
  try {
    const result = await graphqlClient.query({
      query: gql(tokensDeployedQuery),
      variables: {
        limit: limit || 500,
        skip: skip || 0,
        chain: chain 
      },
      fetchPolicy: 'network-only',
    });

    const arr = []
    for( let i = result.data.getTokensCookiesV2ByChain.length - 1; i >= 0; i-=1 ){
      const val = result.data.getTokensCookiesV2ByChain[i];
      // console.log(val)
      if( val.tokenDeployer === '0x85ca6f0e11ab7359024b4d944580fc4a1b57388b' || val.blocked === true)
        continue;
      arr.push(val)
    }
    return arr;
  } catch (err) {
    return [];
  }

  };

  const tokensDexQuery = `
  query getTokensDexCookies($limit: Int!, $skip: Int!){
    getTokensDexCookies(limit: $limit, skip: $skip){
      pairAddress,
      priceUSD,
      priceNative,
      chain,
      tokenName
    }
  }
  `;
  export const getTokensDexInfo = async ({ limit, skip }: { limit?: number; skip?: number }) => {
    try {
      const result = await graphqlClient.query({
        query: gql(tokensDexQuery),
        variables: {
          limit: limit || 500,
          skip: skip || 0,
        },
        fetchPolicy: 'network-only',
      });
      return result.data.getTokensDexCookies;
    } catch (err) {
      return [];
    }
  };