import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, Typography, styled } from '@mui/material';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

const InfoCardLabel = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    cursor: 'pointer',
    textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '18px',
      lineHeight: '18px',
      textAlign: 'right',
    },
  }));
  
  const ValueTitle = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    // width: '100%',
    // cursor: 'pointer',
    // textAlign: 'center',
    // textWrap: 'wrap',
    color: 'white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    [theme.breakpoints.up('xs')]: {
      fontSize: '12px',
      lineHeight: '18px',
      img: {
        display: 'none',
      },
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '14px',
      lineHeight: '18px',
      img: {
        display: 'block',
      },
    },
  }));

interface Props {
  isCheckErrors: boolean
    tokenSectionChanges: (tokenName: string, tokenSymbol: string, totalSupply: string) => void,
    isTotalSupplyError: boolean
    shouldBeTotalSupply: string
}

const TokenSection: FC<Props> = ({ tokenSectionChanges, isCheckErrors, isTotalSupplyError, shouldBeTotalSupply }) => {
    const [name, setName] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [totalSupply, setTotalSupply] = useState<string>('');
    const [isSpecsExpanded, setSpecsExpanded] = useState<boolean>(false);

    function isNumeric(n: any) {
      return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
    }

    const onChangeName = (e: any) => {
        const newAmount = e.target.value;
        setName(newAmount);
        tokenSectionChanges(newAmount, symbol, totalSupply)
    };
      const onChangeSymbol = (e: any) => {
        const newAmount = e.target.value;
        setSymbol(newAmount);
        tokenSectionChanges(name, newAmount, totalSupply)
    };
    const onChangeSupply = (e: any) => {
      if (!isNumeric(e.target.value)) return;
      const newAmount = e.target.value;
      setTotalSupply(newAmount);
      tokenSectionChanges( name ,symbol, newAmount)
   };
  return (
    <>
         <InfoCardLabel mt={1}>
                Token Info
         </InfoCardLabel>
         <Box display='flex' sx={{flexDirection: {xs: 'column', md: 'row'}}} justifyContent='center'>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
                <Box mt={2} display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                        <TextField placeholder='Token Name' required={true} onChange={onChangeName}  autoComplete='off' error={isCheckErrors ? name.length === 0 : false}  label="Token Name" value={name} />
                </Box>
                <Box display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                        <TextField placeholder='Token Symbol' required={true} onChange={onChangeSymbol}  autoComplete='off' error={isCheckErrors ? name.length === 0 : false}  label="Token Symbol" value={symbol} />
                </Box>
            </Box>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{border: '0px solid blue'}}>
                <Box mt={2} display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%'
                        }}>
                        <TextField placeholder='Token Supply' required={true} onChange={onChangeSupply}  autoComplete='off' error={isCheckErrors ? totalSupply.length === 0 || isTotalSupplyError : false}  label={`Token Supply ${isTotalSupplyError ? "sholud be greater" : ""}`} value={totalSupply} />
                </Box>
                <Box display='flex' sx={{
                        border: '0px solid grey',
                        padding: '10px',
                        width: '100%',
                        position: 'relative',
                        }}>
                          <Box display='flex'  alignItems='center' sx={{
                          minHeight: '53px',
                          padding: '10px',
                          width: '100%'
                          }}>
                              <ValueTitle>
                                Token Specifications
                              </ValueTitle>
                              <Button sx={{maxHeight: '20px', maxWidth: '20px'}} onClick={() => {setSpecsExpanded(!isSpecsExpanded)}}>
                                <ArrowDropDownCircleIcon  sx={{transform: isSpecsExpanded  ? "rotate(180deg)" : ""}}/>
                              </Button>
                            </Box>
                            <Box sx={{position: 'absolute', top: 60, display: isSpecsExpanded ? '' : 'none', background: 'rgba(42,49,57, 1.0)'}}>
                              {isSpecsExpanded &&
                                <>
                                  <Box mt={1} display='flex' justifyContent='center' alignItems='center' flexDirection='column' textAlign='center'>
                                    <ValueTitle>No mint functions</ValueTitle>
                                    <ValueTitle>Ownership renounced</ValueTitle>
                                    <ValueTitle>No blacklist or whitelist functions</ValueTitle>
                                    <ValueTitle>No buy tax, No sell tax, No transfer tax</ValueTitle>
                                  </Box> 
                                </>
                              }
                            </Box>
                </Box>
            </Box>
          </Box>
         

    </>
  );
};

export default TokenSection;

