import { Config, ConnectorNames } from 'types/wallet';

import { wallets } from 'config/constants/assets';

const connectors: Config[] = [
  {
    title: 'MetaMask',
    icon: wallets.metamask,
    connectorId: ConnectorNames.Metamask,
    bgColor: 'rgba(248, 157, 53, 0.2)',
  },
  // {
  //   title: 'WalletConnect',
  //   icon: wallets.walletConnect,
  //   connectorId: ConnectorNames.WalletConnect,
  //   bgColor: 'rgba(67, 144, 249, 0.2)',
  // },
  {
    title: 'WalletConnect',
    icon: wallets.walletConnect,
    connectorId: ConnectorNames.WalletConnectV2,
    bgColor: 'rgba(67, 144, 249, 0.2)',
  },
  // {
  //   title: 'CoinBase',
  //   icon: wallets.coinbase,
  //   connectorId: ConnectorNames.Coinbase,
  //   bgColor: 'rgba(50, 97, 215, 0.2)',
  // },
  // {
  //   title: 'Other',
  //   icon: wallets.other,
  //   connectorId: ConnectorNames.Coinbase,
  //   bgColor: 'rgba(188, 188, 188, 0.2)',
  // },
];

export default connectors;
